import {
  cUnitPrice,
  IFrontFrame,
  IOpenings,
  IProduct,
  IUnitPrice,
  KitchenType
} from '../../common/interface/product-types'

export class Product implements IProduct {
  /**
   * The database id, uuid
   */
  id: string

  /**
   * The database version.
   */
  version: number

  /**
   * Category as in BD1, BL2H2 etc.
   */
  cat: string

  /**
   * PC = Prodboard code. Basically the unique identifier in
   * original master sheet.
   */
  pc: string

  /**
   * Price fields, lots of them.
   */
  adBuInHoPr: IUnitPrice

  /**
   * Adjustable shelves.
   */
  adShPr: IUnitPrice
  baPaPr: IUnitPrice
  coDeEnPaPr: IUnitPrice
  coSpPr: IUnitPrice
  cuBoCoPr: IUnitPrice
  cuBoPr: IUnitPrice
  cuDiPr: IUnitPrice
  enPaPr: IUnitPrice
  exPrDeSt: IUnitPrice
  exPrHiSt: IUnitPrice
  exShPr: IUnitPrice
  shIdPr: IUnitPrice
  fittings = {
    hiPr: 0,
    nuHi: 0,
    nuKn: 0,
    hiPo: ''
  }
  // fittings: IFitting
  glDoPr: IUnitPrice
  hiDrPr: IUnitPrice
  knBlPr: IUnitPrice
  liPr: IUnitPrice
  noDoPr: IUnitPrice
  paInPr: IUnitPrice
  pr: IUnitPrice
  waBlPr: IUnitPrice


  frontFrame: IFrontFrame
  openings: IOpenings

  deEnLaSw = ''
  /**
   * Number of doors
   */
  nuDo = 0

  /**
   * Number of drawers
   */
  nuDr = 0

  /**
   * Standard number of shelves
   */
  nuShSt = 0


  /**
   * Dimensions
   */

  /**
   * Widths
   */
  wiMa = 0
  wiMi = 0

  /**
   * Depth
   */
  deMa = 0
  deMi = 0
  deSt = 0

  /**
   * Heights
   */
  heMi = 0
  heSt = 0
  hiMa = 0


  /**
   * Socle height Default
   */
  shDe = 0

  /**
   * Stoppers and Catchers Setting
   */
  public scSe: 'default' | 'none'

  /**
   * Recess Cabinet Type, base, sink, sink-out etc.
   */
  public rct: string

  /**
   * Product comment in English
   */
  public co: string

  /**
   * Product comment in Swedish
   */
  public coSe: string

  /**
   * Higher than standard?
   */
  public hiSt: number

  // Dishwasher cabinet
  idc = false

  // Oven cabinet
  ioc = false

  // Type of kitchen
  kt: KitchenType = '20tal'

  // Base Corner Cabinet? true/false
  public ibcc = false

  /**
   * Drawer HeightS - The height of drawers (per "row")
   */
  public dhs = []

  constructor(product: IProduct = {} as any) {
    if (product.pc) {
      this.newFromProduct(product)
    } else {
      this.newProduct()
    }
  }

  public newProduct(): IProduct {
    Object.keys(cUnitPrice).forEach((key: string) => {
      this[key] = {price: 0, labor: 0}
      if (cUnitPrice[key].length > 1) {
        this[key].labor = 0
      }
    })

    this.frontFrame = {
      faBoFr: 0,
      faToFr: 0,
      faLeFr: 0,
      faRiFr: 0,
      faMiFr: 0,
      faMiFrThHo: 0,
      faMiFrFiHo: 0
    }

    this.openings = {
      heHoStHe: 0,
      one: {min: 0, max: 0},
      two: {min: 0, max: 0},
      three: {min: 0, max: 0},
      fourFive: {min: 0, max: 0},
    }
    return this
  }

  public newFromProduct(source: IProduct): IProduct {
    Object.assign(this, JSON.parse(JSON.stringify(source)))
    this.version = undefined
    this.id = undefined
    return this
  }
}
