import {FactoryExtra, paintPrices} from './factory-extra'

export class PlateRack extends FactoryExtra {

  public override price = 4200
  public override labor = 140

  public override plateRackDimension = 'standard'
  public override height = 1200
  public override width = 800

  constructor(input: Partial<PlateRack> = {}) {
    super(input)
    Object.assign(this, input)
  }

  override get totalFactoryPrice(): number {
    if (this.setLabor !== null) {
      return this.setLabor
    }
    if (this.plateRackDimension === 'standard') {
      const price = this.labor * this.quantity
      const reduction = price * (paintPrices.get(this.paintProcess).fReduction / 100)
      return price - reduction
    }
    return null
  }

  override get totalCustomerPrice(): number {
    if (this.setPrice !== null) {
      return this.setPrice
    }
    const price = this.price * this.quantity
    if (this.plateRackDimension === 'standard') {
      const reduction = price * (paintPrices.get(this.paintProcess).cReduction / 100)
      return price - reduction
    }
    return null
  }
}
