import {Injectable} from '@angular/core'
import {ProductCategory} from '../services/product-static.service'
import {IProject} from '../services/project-types'
import {SettingsItemService} from '../services/settings-item.service'
import {ProdboardCabinet} from './cabinet/prodboard-cabinet'
import {IMillFileItem, IMillRoom} from './mill-file/mill-file-types'

@Injectable({
  providedIn: 'root'
})
export class ProdboardCabinetFactory {

  public createCabinet(
    settingsItemService: SettingsItemService,
    product: ProductCategory,
    input: IMillFileItem,
    room: IMillRoom,
    project: IProject): ProdboardCabinet {
    return new ProdboardCabinet(
      settingsItemService,
      product,
      // We make sure that we never ever modify the original file values
      JSON.parse(JSON.stringify(input)),
      JSON.parse(JSON.stringify(room)),
      project
    )
  }
}
