import {Component, OnDestroy, OnInit} from '@angular/core'
import {switchMap, takeUntil} from 'rxjs/operators'
import {AutoSaveService} from '../../services/auto-save.service'
import {Subscription, timer, Subject} from 'rxjs'
import {AUTO_SAVE_TIMEOUT, KILL_PROJECT_TIMEOUT} from '../interface/helpers'
import {Router} from '@angular/router'
import {MatDialog} from '@angular/material/dialog'

@Component({
  selector: 'kdl-auto-save',
  template: ''
})
export class AutoSaveComponent implements OnInit, OnDestroy {

  public changed = false

  public saving = false

  public timer = 0

  public autoSaveTimeout = Math.floor(AUTO_SAVE_TIMEOUT / 1000)

  public killTimer$ = new Subject()

  private autoSave$ = new Subscription()


  constructor(
    protected autoSave: AutoSaveService,
    protected router: Router,
    private dialog: MatDialog
  ) {
  }

  public ngOnInit(): void {
    this.autoSave$ = this.autoSave.changed$.pipe(
      switchMap((changed: boolean) => {
        this.killTimer$.next(null)
        this.startKiller()
        this.changed = changed
        return this.autoSave.saving$
      }),
      switchMap((saving: boolean) => {
        this.saving = saving
        return this.autoSave.timer$
      })
    ).subscribe({
      next: (t: number) => {
        this.timer = t
      }
    })
  }

  public ngOnDestroy(): void {
    this.autoSave$.unsubscribe()
    this.killTimer$.next(null)
  }

  private startKiller(): void {
    timer(KILL_PROJECT_TIMEOUT, KILL_PROJECT_TIMEOUT)
      .pipe(
        takeUntil(this.killTimer$))
      .subscribe({
        next: () => {
          this.dialog.closeAll()
          this.router.navigate(['/']).then(() => this.killTimer$.next(null))
        }
      })
  }
}
