import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog'
import {Observable, switchMap} from 'rxjs'
import {filter} from 'rxjs/operators'
import {sortTags} from '../../model/tags/pipes/sort-tag.pipe'
import {ITag} from '../../model/tags/types'
import {UserTag} from '../../model/tags/user-tag'
import {IProjectBase} from '../../services/project-types'
import {TagService} from '../../services/tag.service'

@Component({
  selector: 'kdl-tag-dialog',
  templateUrl: './tag-dialog.component.html',
  styleUrls: ['./tag-dialog.component.scss']
})
export class TagDialogComponent {

  public availableUsers: UserTag[] = []

  public currentUsers: UserTag[] = []

  public currentTags: ITag[] = []

  public availableTags: ITag[] = []

  constructor(
    private dialog: MatDialog,
    public tagService: TagService,
    @Inject(MAT_DIALOG_DATA) public data: {
      project: IProjectBase
    }
  ) {
    this.availableUsers = this.tagService.userTags
    this.currentUsers = data.project.tags.filter((t: ITag): t is UserTag => t.type === 'u')
    this.currentTags = data.project.tags.filter((t: ITag): t is UserTag => !t.hidden && t.type !== 'u')
    this.setAvailable()
  }

  public remove = (tag: Partial<ITag>): void => {
    this.currentUsers = this.currentUsers.filter(t => t.id !== tag.id)
    this.setAvailable()
    this.addOrRemove(this.tagService.unTagProject(tag, this.data.project))
  }

  public add = (user: Partial<ITag>): void => {
    const tag = new UserTag(user.id)
    this.currentUsers.push(tag)
    this.addOrRemove(this.tagService.tagProject(tag, this.data.project))
  }

  public addTag(tag: ITag): void {
    tag.onTagCreate(this.data.project, this.dialog)
      .pipe(filter(Boolean)
      )
      .subscribe({
        next: (t: ITag) => {
          this.currentTags.push(t)
          this.addOrRemove(this.tagService.tagProject(t, this.data.project))
          t.project.tags.push(t)
        }
      })
  }

  public removeTag(tag: ITag): void {
    tag.onTagRemove(this.data.project)
      .subscribe(
        {
          next: () => {
            this.currentTags = this.currentTags.filter(t => t.id !== tag.id)
            this.addOrRemove(this.tagService.unTagProject(tag, this.data.project))
          }
        }
      )
  }

  public editTag(tag: ITag): void {
    tag.configure(this.dialog)
      .pipe(
        filter(Boolean),
        switchMap((t: ITag) =>
          this.tagService.updateTagProject(t, this.data.project)
        ))
      .subscribe()
  }

  public addOrRemove(obs: Observable<Partial<ITag>[]>): void {
    this.setAvailable()
    obs.subscribe({
      next: (tags: ITag[]) => {
        const t = TagService.createTags(tags, this.data.project)
        this.data.project.tags.length = 0
        this.data.project.tags.push(...sortTags(t))
      }
    })
  }

  private setAvailable(): void {
    const existingUsers = this.currentUsers.map(t => t.id)
    this.availableUsers = this.tagService.userTags
      .filter(u => existingUsers.indexOf(u.id) === -1)

    this.availableTags = this.tagService.getAllTags().filter((available: ITag) => {
      /**
       * If we already have such a tag, and it is _not_ multi
       */
      return !this.currentTags
        .find((current: ITag) => available.type === current.type && !available.multi)
    })
  }
}
