import {Pipe, PipeTransform} from '@angular/core'
import {DomSanitizer} from '@angular/platform-browser'

export const linkify = (text: string): string => {
  const urlRegex = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig
  return text.replace(urlRegex, (url) => `<a target="_blank" href="${url}">${url}</a>`)
}

@Pipe({
  name: 'linkConv'
})
export class LinkConv implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(url: string[]) {
    return url
      .filter((u: string) => !!u)
      .map((u: string) => this.sanitizer.bypassSecurityTrustHtml(linkify(u)))
  }
}
