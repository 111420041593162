import {Injectable} from '@angular/core'
import {MatSnackBar} from '@angular/material/snack-bar'
import {EMPTY, Observable, of, switchMap} from 'rxjs'
import {catchError, map, tap} from 'rxjs/operators'
import {
  Category,
  IProduct,
  ProductListItem
} from '../common/interface/product-types'
import {ProblemService} from './problem.service'
import {ProductService} from './product.service'

/**
 * Let us invent a super ProductCategory with the wanted category
 * data inserted. Overlapping properties are hopefully the same.
 */
export interface ProductCategory extends IProduct, Category {
  loaded: boolean
}

@Injectable({
  providedIn: 'root'
})
export class ProductStaticService {

  private productMap = new Map<string, any>()
  private allVersionsOfCurrentProductsMap: Map<string, IProduct[]> = new Map()

  constructor(
    private snackbar: MatSnackBar,
    private productService: ProductService,
    private problemService: ProblemService
  ) {
  }

  /**
   * This should not be called until we have all products loaded.
   * That is why I check on the "loaded" subject before continuing
   */
  public getProductByProdBoardId(id: string, version: string): Observable<IProduct> {

    return this.productService.getProductsAndCategories().pipe(
      switchMap(() => this.productService.getProdboardProduct(id, version)),
      // The product should always exist, but it could "disappear" in the future...
      // Add the "product" to the stored "shallow" product, and return it.
      // We have now updated it in the database.
      map((p: IProduct) => {
        const px = this.productService.productsList$.value.find(pc => pc.pc === p.pc)
        this.productMap.set(id, {...p, ...px})
        return this.productMap.get(id)
      }),
      catchError(() => {
        this.problemService.problems$.next({
          description: `Vi hittade inte ${id} i produktdatabasen`,
          handled: false
        })
        return of({pc: 'missing'} as ProductCategory)
      })
    )
  }

  public getPriceLockVersion(id: string, priceLockTime: number | null): Observable<IProduct> {
    let px: ProductListItem
    return this.productService.getProductsAndCategories().pipe(
      switchMap(() => {
        px = this.productService.productsList$.value
          .find(p => p.pc.toUpperCase() === id.toUpperCase())
        if (!px) {
          this.snackbar.open(
            `Cabinet with code "${id}" does not exist in Mill's Product database. Add it or remove that cabinet from kitchen`,
            'Close',
            {
              horizontalPosition: 'center',
              verticalPosition: 'top'
            })
          return EMPTY
        }
        return this.allVersionsOfCurrentProductsMap.has(id)
          ? of(this.allVersionsOfCurrentProductsMap.get(id))
          : this.productService.getAllVersionsOfProduct(px.id).pipe(
            tap((products: IProduct[]) => {
              this.allVersionsOfCurrentProductsMap.set(id, products)
            })
          )
      }),
      map((products: IProduct[]) => {
        let version = '$LATEST'
        if (priceLockTime) {
          version = products.filter((product: IProduct) => product.timeStamp <= priceLockTime)
            .sort((a: IProduct, b: IProduct) => b.timeStamp - a.timeStamp)[0].version + ''
        }
        return version
      }),
      switchMap((version: string) => {
        return this.getProductByProdBoardId(px.pc, version)
      })
    )
  }
}
