import {Injectable} from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {BehaviorSubject, forkJoin, Observable, of} from 'rxjs'
import {environment} from '../../environments/environment'
import {catchError, map, tap} from 'rxjs/operators'
import {IItemLock} from '@kdl/kulladal-product-backend'


@Injectable({
  providedIn: 'root'
})
export class LockService {

  public locks$: Observable<IItemLock[]>

  private pLocks$ = new BehaviorSubject<IItemLock[]>([])

  private locksMap = new Map<string, IItemLock>()

  private myLocks = new Set<string>()

  constructor(private http: HttpClient) {
    this.locks$ = this.pLocks$.asObservable()
  }

  public lock(id: string): Observable<void> {
    const url = `${environment.productUrl}/locks`
    // Do not lock if it is already locked
    if (this.isLocked(id)) {
      return of(null)
    }
    return this.http.put<void>(url, {id}).pipe(
      tap(() => {
        this.myLocks.add(id)
      })
    )
  }

  public unlock(id: string): Observable<any> {
    const url = `${environment.productUrl}/locks/${id}`
    return this.http.delete(url).pipe(
      tap(() => {
        this.myLocks.delete(id)
      })
    )
  }

  /**
   * This has to be fast, we cannot fetch from the internet all the time.
   * @param id
   */
  public isLocked(id: string): boolean {
    return this.locksMap.has(id) && !this.myLocks.has(id)
  }

  public unlockMe(): Observable<void> {
    // Note that forkJoin does not return on empty arrays
    const locks: Observable<void>[] = [of(undefined)]
    this.myLocks.forEach((id: string) => {
      locks.push(this.unlock(id))
    })
    return forkJoin(locks).pipe(
      map(() => undefined)
    )
  }

  /**
   * Loads locks, returns the locks and completes.
   */
  public locks(): Observable<IItemLock[]> {
    const url = `${environment.productUrl}/locks`
    return this.http.get<IItemLock[]>(url).pipe(
      tap((locks: IItemLock[]) => {
        this.locksMap.clear()
        locks.forEach((lock: IItemLock) => {
          this.locksMap.set(lock.id, lock)
        })
        this.pLocks$.next([...this.locksMap.values()])
      }),
      catchError(() => {
        return []
      })
    )
  }
}
