import {TTranslationItem} from '../../application/i18n.provider'
import {CabinetLighting} from './cabinet-lighting'

export const LightProductNos = [
  '973501', // LED-profil
  '973575', // Diffusor
  '972743', // LED-spot
  '973521', // LED-strip 1m 19,2W/m
  '973522', // LED-strip 2m 19,2W/m
  '973523', // LED-strip 1m 9,6W/m
  '973524', // LED-strip 2m 9,6W/m
  '992615', // Extension cord
  '992613', // Terminal block (only if there are > 6 LED-spots)
  '992675', // Driver 12W
  '992681', // Driver 30W
  '992677', // Driver 50W
  '992689-11', // Driver 150W
  '971185', // Dimmable-driver 25W
  '971186', // Dimmable-driver 50W
  '971188-11', // Dimmable-driver 100W
  '974263', // Dotti sensor
  '973900-41', // Control box
  '973906' // Sensor
] as const
export type TLightProductNo = typeof LightProductNos[number]

export type TLightsType =
  'extra'
  | 'strip'
  | 'spot'
  | 'extensionCord'
  | 'driver'
  | 'sensor'
  | 'plinth'

export type TLightsPosition = 'spotBelow' | 'ledBelow' | 'ledInside'

/**
 * All lights inherit from this one. Has product number
 * and description and customer text. More to come?
 *
 * - URL to product?
 * https://www.beslagdesign.se/sv/produkter/belysning
 */
export interface ILightProduct extends TTranslationItem {

  type: TLightsType

  /**
   * Something along the lines of 974263
   */
  productNo: TLightProductNo
}

/**
 * The LED extra is the profile and diffusor they
 * have only length
 */
export interface LedExtra extends ILightProduct {
  /**
   * They come in different lengths no more no less
   * (but there will be colors etc.), measured in meters.
   */
  length: number
}

/**
 * Spots, slightly slimmer than strips
 */
export interface LedSpot extends ILightProduct {

  /**
   * Power, as in W like 3
   */
  power: number

  /**
   * Is this a dimable product, if so that controls the
   * power unit. It is or is not.
   */
  dimable: boolean

  /**
   * Light temperature, K, normally 3000
   */
  temperature: number
}

/**
 * The king of kings!
 */
export interface LedStrip extends ILightProduct {
  /**
   * Length in meters.
   */
  length: number

  /**
   * Voltage, usually 24
   */
  voltage: number

  /**
   * Power, as in W/m, 9.6 or 19.2 is the usual values
   */
  power: number

  /**
   * Is this a dimable product, if so that controls the
   * power unit. It is or is not.
   */
  dimable: boolean

  /**
   * Light temperature, K, normally 3000
   */
  temperature: number
}

export interface LightExtensionCord extends ILightProduct {
  /**
   * The only thing we know
   */
  length: number
}

export interface LightDriver extends ILightProduct {
  /**
   * Designed for voltage
   */
  voltage: number

  /**
   * Dimmable
   */
  dimmable: boolean

  /**
   * Power, max drive power total
   */
  power: number
}

export interface LightSensor extends ILightProduct {
  /**
   * Really nothing that differs/matters as of now but I want it to be its own
   * interface.
   */
  voltage: number
}

export interface LightPlinth extends ILightProduct {
  /**
   * Actually just a made-up property
   */
  ports: number
}

/**
 * LED-profil
 */
export const P973501: LedExtra = {
  type: 'extra',
  en: 'LED-profile, alu (973501)',
  sv: 'LED-profil. 2 meter, aluminum',
  length: 2,
  productNo: '973501'
}

/**
 * Diffusor (Bländskydd)
 */
export const P973575: LedExtra = {
  type: 'extra',
  en: 'Diffusor, opal (973575)',
  sv: 'Bländskydd, 2 meter',
  length: 2,
  productNo: '973575'
}

/**
 * LED-spot
 */
export const P972743: LedSpot = {
  type: 'spot',
  en: 'LED-spot Stella Flat 24 (972743)',
  sv: 'LED-spot, 3000K, 3W (dimbar)',
  dimable: false,
  power: 3,
  productNo: '972743',
  temperature: 3000
}

/**
 * Led-strip 1m 19,2W/m
 */
export const P973521: LedStrip = {
  type: 'strip',
  en: 'LED-strip, 1000mm, 19,2W/m (973521)',
  sv: 'LED-strip, 1 meter, 24V/19,2W/m, 3000K (dimbar)',
  dimable: true,
  length: 1,
  power: 19.2,
  productNo: '973521',
  temperature: 3000,
  voltage: 24
}

/**
 * Led-strip 2m 19,2W/m
 */
export const P973522: LedStrip = {
  type: 'strip',
  en: 'LED-strip, 2000mm, 19,2W/m (973522)',
  sv: 'LED-strip, 2 meter, 24V/19,2W/m, 3000K',
  dimable: false,
  length: 2,
  power: 19.2,
  productNo: '973522',
  temperature: 3000,
  voltage: 24
}

/**
 * Led-strip 1m 9W - Inside only
 */
export const P973523: LedStrip = {
  type: 'strip',
  en: 'LED-strip, 1000mm, 9,6W/m (973523)',
  sv: 'LED-strip, 1 meter, 24V/9,6W/m, 3000K (dimbar)',
  dimable: true,
  length: 1,
  power: 9.6,
  productNo: '973523',
  temperature: 3000,
  voltage: 24
}

/**
 * Led-strip 2m 9W - Inside only
 */
export const P973524: LedStrip = {
  type: 'strip',
  en: 'LED-strip, 2000mm, 9,6W/m (973524)',
  sv: 'LED-strip, 2 meter, 24V/9,6W/m, 3000K (dimbar)',
  dimable: true,
  length: 2,
  power: 9.6,
  productNo: '973524',
  temperature: 3000,
  voltage: 24
}
/**
 * Extension cord of 2000mm
 */
export const P992615: LightExtensionCord = {
  type: 'extensionCord',
  en: 'Extension cable, 2000mm (992615)',
  sv: 'Förlängningskabel, 2 meter',
  length: 2,
  productNo: '992615'
}
/**
 * Low-power (12W) driver for when we have doors.
 */
export const P992675: LightDriver = {
  type: 'driver',
  en: 'Driver UltraThin, 24V/12W (992675)',
  sv: 'Drivdon UltraThin, 24V/12W',
  dimmable: false,
  power: 12,
  productNo: '992675',
  voltage: 24
}
/**
 * Medium power (30W) driver for when we have doors.
 */
export const P992681: LightDriver = {
  type: 'driver',
  en: 'Driver UltraThin, 24V/30W (992681)',
  sv: 'Drivdon UltraThin, 24V/30W',
  dimmable: false,
  power: 30,
  productNo: '992681',
  voltage: 24
}
/**
 * Medium power (50W) driver for when we have doors.
 */
export const P992677: LightDriver = {
  type: 'driver',
  en: 'Driver UltraThin, 24V/50W (992677)',
  sv: 'Drivdon UltraThin, 24V/50W',
  dimmable: false,
  power: 50,
  productNo: '992677',
  voltage: 24
}
/**
 * The high-power driver for cabinets with doors.
 */
export const P992689_11: LightDriver = {
  type: 'driver',
  en: 'Driver SLIM, 24V/150W (992689-11)',
  sv: 'Drivdon SLIM, 24V/150W',
  dimmable: false,
  power: 150,
  productNo: '992689-11',
  voltage: 24
}
/**
 * Low-power (25W) driver for no door or vitrinlucka.
 */
export const P971185: LightDriver = {
  type: 'driver',
  en: 'Driver Triac, 24V/25W (971185)',
  sv: 'Drivdon Triac, 24V/25W (dimbar)',
  dimmable: true,
  power: 25,
  productNo: '971185',
  voltage: 24
}
/**
 * Medium power (50W) driver for no door or vitrinlucka.
 */
export const P971186: LightDriver = {
  type: 'driver',
  en: 'Driver Triac, 24V/50W (971186)',
  sv: 'Drivdon Triac, 24V/50W (dimbar)',
  dimmable: true,
  power: 50,
  productNo: '971186',
  voltage: 24
}
/**
 * High-power (100W) driver for no door or vitrinlucka.
 */
export const P971188_11: LightDriver = {
  type: 'driver',
  en: 'Driver Triac, 24V/100W (971188-11)',
  sv: 'Drivdon Triac, 24V/100W (dimbar)',
  dimmable: true,
  power: 100,
  productNo: '971188-11',
  voltage: 24
}
/**
 * Dotti door sensor
 */
export const P974263: LightSensor = {
  type: 'sensor',
  en: 'Door sensor Dotti (974263)',
  sv: 'Lucksensor "Dotti"',
  productNo: '974263',
  voltage: 24
}
/**
 * Control box for sensors
 */
export const P973900_41: LightSensor = {
  type: 'sensor',
  en: 'Control box (973900-41)',
  sv: 'Control box A 24V, till IR-sensorer',
  productNo: '973900-41',
  voltage: 24
}
/**
 * Normal door sensor
 */
export const P973906: LightSensor = {
  type: 'sensor',
  en: 'IR door sensor (973906)',
  sv: 'Lucksensor, IR sensor B, till "Control Box" - en per lucka',
  productNo: '973906',
  voltage: 24
}
/**
 * Extra needed when combining many spots (> 6)
 */
export const P992613: LightPlinth = {
  type: 'plinth',
  en: 'Terminal block - Micro24 - extension (992613)',
  sv: 'Kopplingsplint Micro24 - förlängning',
  productNo: '992613',
  ports: 8
}

export const DIMMABLE_DRIVERS: LightDriver[] = [P971185, P971186, P971188_11]
export const NON_DIMMABLE_DRIVERS: LightDriver[] = [P992675, P992681, P992677, P992689_11]

/**
 * General map to get light product by its product No.
 */
export const LightProductsMap = {
  '973501': P973501,
  '973575': P973575,
  '972743': P972743,
  '973521': P973521,
  '973522': P973522,
  '973523': P973523,
  '973524': P973524,
  '992615': P992615,
  '992613': P992613,
  '992675': P992675,
  '992681': P992681,
  '992677': P992677,
  '992689-11': P992689_11,
  '971185': P971185,
  '971186': P971186,
  '971188-11': P971188_11,
  '974263': P974263,
  '973900-41': P973900_41,
  '973906': P973906
}

export interface ILightSharing {
  /**
   * Cabinet's lighting from which is getting its shared component (driver,
   * LED-strip, etc.)
   */
  parent?: CabinetLighting
  /**
   * Cabinets' lighting that are getting shared component from "me" (driver,
   * LED-strip, etc.)
   */
  sons?: CabinetLighting[]
  /**
   * Flag to know if this sharing has been calculated already
   */
  reduced: boolean
}

/**
 * Represents the result of the door factor calculation.
 */
export interface IDoorFactor {
  /**
   * The calculated door factor.
   */
  doorFactor: number

  /**
   * The height of the door.
   */
  doorHeight: number
}
