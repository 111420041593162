import {Injectable} from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {Observable} from 'rxjs'
import {environment} from '../../environments/environment'

@Injectable({
  providedIn: 'any'
})
export class SettingsService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  public getItems<R>(resource: string): Observable<R[]> {
    const url = `${environment.productUrl}/${resource}`
    return this.httpClient.get<R[]>(url)
  }

  public getItem<T>(resource: string, id: string, version: number | string): Observable<T> {
    const url = `${environment.productUrl}/${resource}/${id}/${version}`
    return this.httpClient.get<T>(url)
  }

  /**
   * Deletes any item on any resource, deletes all versions
   *
   * @param resource - Like 'projects' or 'appliances', no slashes (/)
   * @param id - The UUID v4 Id to remove.
   */
  public deleteItem(resource: string, id: string): Observable<void> {
    const url = `${environment.productUrl}/${resource}/${id}`
    return this.httpClient.delete<void>(url)
  }

  /**
   * Saves any item on any resource, be careful!
   */
  public saveItem<T>(resource: string, item: any): Observable<T> {
    const url = `${environment.productUrl}/${resource}/${item.id}`
    return this.httpClient.put<T>(url, item)
  }

  /**
   * Create any item on any resource, be careful!
   */
  public createItem<T>(resource: string, item: any): Observable<T> {
    const url = `${environment.productUrl}/${resource}`
    return this.httpClient.put<T>(url, item)
  }

  public loadFile(url: string): Observable<any> {
    return this.httpClient.get(url).pipe()
  }
}
