import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {BehaviorSubject, forkJoin, from, Observable, of, ReplaySubject} from 'rxjs'
import {first, map, switchMap, tap, toArray} from 'rxjs/operators'
import {environment} from 'src/environments/environment'
import {Category, IProduct, ProductListItem} from '../common/interface/product-types'
import {WaitDialogComponent} from '../dialogs/wait-dialog/wait-dialog.component'
import {Product} from '../model/product/product'

export interface SheetsValidationResult {
  currentNumberOfCategories: number
  currentNumberOfProducts: number
  totalNumberOfRows: number
  filteredEmptyRows: number
  numberOfCategories: number
  numberOfProducts: number

  /**
   * This is the ID of the saved rows to be installed
   */
  id: string

  /**
   * All the rows to import. Happy peoples can use this
   * in the future to review results. I still hope that
   * Adam will give up on the Sheets, but who knows...
   */
  rows?: any[]
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  /**
   * A public product that anyone can subscribe to.
   */
  public products$: ReplaySubject<IProduct[]> = new ReplaySubject<IProduct[]>(1)

  /**
   * A public category that anyone can subscribe to.
   */
  public categories$: ReplaySubject<Category[]> = new ReplaySubject<Category[]>(1)

  /**
   * A public list of products that anyone can subscribe to.
   * The product list items are complete with category data
   */
  public productsList$: BehaviorSubject<ProductListItem[]> = new BehaviorSubject<ProductListItem[]>([])

  /**
   * A list of products to compare with
   */
  public compareProducts$: BehaviorSubject<IProduct[]> = new BehaviorSubject<IProduct[]>([])

  /**
   * We hold an "active" product here to work with. This is updated on "get" and "save"
   */
  public currentProduct$: ReplaySubject<IProduct> = new ReplaySubject<IProduct>(1)

  /**
   * This is a simple semaphore to avoid reloading products. Pretty naïve and
   * should probably be refactored. It should only be used by the
   * getProductsAndCategories function
   */
  private productsLoaded = false

  constructor(
    private httpClient: HttpClient,
    private dialog: MatDialog
  ) {
  }

  /**
   * Initialize our products and categories list. We let someone else call
   * this when they are ready.
   *
   * This will be called wildly when components need the product list, so
   * if we have the lists populated we simply ignore the call. It is only
   * the products' management that would care about updates.
   */
  public getProductsAndCategories(): Observable<any> {

    if (this.productsLoaded) {
      return of('foo')
    }
    const products$ = this.getProducts()
    const category$ = this.getCategories()

    // 1. Get all products and all categories
    return forkJoin([products$, category$]).pipe(
      switchMap((resArray: [IProduct[], Category[]]) => {
        // 2. We now have a list of all products and all categories, these
        // have been published on the $products and $categories already.
        this.productsLoaded = true
        return from(resArray[0])
      }),
      //
      // 4. This assumes that all categories exist in the this.categories$
      // the this.categories$ has been populated by the 'this.getCategories() above.
      // The below call will create an array of "ProductListItems"
      // Each of which needs to be pushed onto the products.
      switchMap((product: IProduct) => this.getProductListItem(product)),
      //
      // 5. This will collect all the productListItems as one array
      // It will complete when the 'from([]) above is through its list
      toArray(),
      // 6. Publish the items on our product list.
      tap((productListItems: ProductListItem[]) => this.updateProductList(productListItems))
    )
  }


  /**
   * Select a product to compare with. If you select
   * the same product twice it will exist twice. You can also
   * remove products from list as well as clear the list
   *
   * @param prodboardId
   */
  public addCompareProduct(prodboardId: string): Observable<IProduct> {
    let product: IProduct
    return this.getProdboardProduct(prodboardId, '$LATEST').pipe(
      switchMap((p: IProduct) => {
        product = p
        return this.compareProducts$
      }),
      first(),
      map((products: IProduct[]) => {
        products.push(product)
        this.compareProducts$.next(products)
        return product
      })
    )
  }

  public removeCompareProduct(prodboardId: string): Observable<void> {
    return this.compareProducts$.pipe(
      first(),
      map((products: IProduct[]) => {
        const filtered = products.filter((product: IProduct) => product.pc !== prodboardId)
        this.compareProducts$.next(filtered)
      })
    )
  }

  public resetCompareProduct(): void {
    this.compareProducts$.next([])
  }

  /**
   * Outbound HTTP functions below
   */

  /**
   * Load one product from the server using the prodboard id.
   */
  public getProdboardProduct(id: string, version: string): Observable<IProduct> {
    const px = this.productsList$.value.find(p => p.pc === id)

    const url = `${environment.productUrl}/products/${px.id}/${version}`
    return this.httpClient.get<IProduct>(url)
  }


  /**
   * Save a new or existing product to the server
   */
  public saveProduct(product: IProduct): Observable<IProduct> {
    const url = `${environment.productUrl}/products/${product.id}`
    return this.httpClient.put<IProduct>(url, product).pipe(
      tap((p: IProduct) => this.currentProduct$.next(p))
    )
  }

  public getAllVersionsOfProduct(id: string): Observable<IProduct[]> {
    const url = `${environment.productUrl}/products/${id}`
    return this.httpClient.get<IProduct[]>(url)
  }

  /**
   *  Overly complex but.
   *
   *  A product is created by using a category and a "code". To create
   *  a new product we look up the category (cat). Then if we have
   *  a template
   *
   * @param category - String matching the 'cat' property of a category
   * @param prodboardCode - The new prodboard code, can be anything
   * @param template - If we want to create from an existing e.g. BCP_12 from BCP_22
   */
  public createProduct(category: string, prodboardCode: string, template: string | undefined = undefined): Observable<IProduct> {
    const url = `${environment.productUrl}/products`
    let newProduct = new Product()
    const dialogRef = this.dialog
      .open(WaitDialogComponent, {disableClose: true})
    return this.createProductTemplate(category, prodboardCode, template).pipe(
      switchMap((product: Product) =>
        this.httpClient.put<Product>(url, product)
      ),
      switchMap((p: Product) => {
        newProduct = p
        this.currentProduct$.next(p)
        // Make sure we have all products,
        return this.getProducts()
      }),
      switchMap(() => this.addProductListItem(newProduct)),
      map(() => {
        dialogRef.close()
        return newProduct
      })
    )
  }

  public deleteProduct(id: string): Observable<void> {
    const dialogRef = this.dialog.open(WaitDialogComponent, {disableClose: true})
    const url = `${environment.productUrl}/products/${id}`
    return this.httpClient.delete<void>(url).pipe(
      switchMap(() => this.getProducts()),
      switchMap(() => this.productsList$),
      first(),
      map((productList: ProductListItem[]) => {
        productList = productList.filter((p: ProductListItem) => p.id !== id)
        dialogRef.close()
        return this.updateProductList(productList)
      })
    )
  }

  public createCategory(category: Category): Observable<Category> {
    const url = `${environment.productUrl}/categories`
    let cat: Category
    return this.httpClient.put<Category>(url, category).pipe(
      switchMap((newCat: Category) => {
        cat = newCat
        return this.getCategories()
      }),
      map(() => cat
      )
    )
  }

  public saveCategory(category: Category): Observable<Category> {
    const url = `${environment.productUrl}/categories/${category.id}`
    return this.httpClient.put<Category>(url, category)
  }

  public getCategory(categoryName: string): Observable<Category> {
    const url = `${environment.productUrl}/category/${categoryName}`
    return this.httpClient.get<Category>(url)
  }

  public validateSheets(id: string): Observable<SheetsValidationResult> {
    const url = `${environment.productUrl}/sheets/${id}`
    return this.httpClient.get<SheetsValidationResult>(url)
  }

  public installSheets(id: string): Observable<SheetsValidationResult> {
    const url = `${environment.productUrl}/sheets/${id}`
    let result: SheetsValidationResult
    // Put requires a payload
    return this.httpClient.put<SheetsValidationResult>(url, {}).pipe(
      switchMap((value: SheetsValidationResult) => {
        result = value
        this.productsLoaded = false
        return this.getProductsAndCategories()
      }),
      map(() => result)
    )
  }

  /**
   * Gets all products and emits them o the products Subject
   */
  private getProducts(): Observable<IProduct[]> {
    const url = `${environment.productUrl}/products`
    return this.httpClient.get<IProduct[]>(url).pipe(
      tap((products: IProduct[]) => {
        this.products$.next(products)
      })
    )
  }

  /**
   * Fetches all categories, publishes a sorted list.
   *
   * @private
   */
  private getCategories(): Observable<Category[]> {
    const url = `${environment.productUrl}/categories`
    return this.httpClient.get<Category[]>(url).pipe(
      tap((categories: Category[]) => {

        // We have undefined in daniels database so let us filter those
        categories = categories.filter((cat: Category) => !!cat.cat)
        categories.sort((a: Category, b: Category): number => {
          if (a.cat === b.cat) {
            return 0
          }
          return a.cat < b.cat ? -1 : 1
        })
        this.categories$.next(categories)
      })
    )
  }

  private createProductTemplate(cat: string, prodboardCode: string, template: string | undefined): Observable<IProduct> {
    const product = new Product()
    return this.categories$.pipe(
      first(),
      switchMap((categories: Category[]) => {
        const c = categories.find((category: Category) => category.cat === cat)
        product.cat = c.cat
        if (template) {
          return this.getProdboardProduct(template, '$LATEST')
        } else {
          return of({})
        }
      }),
      map((templateProduct: IProduct) => {
        if (template) {
          product.newFromProduct(templateProduct)
        } else {
          product.newProduct()
        }
        product.pc = prodboardCode
        return product
      })
    )
  }

  /**
   * Get a category based on short name (cat)
   */
  private getCategoryForProduct(cat: string): Observable<Category> {
    return this.categories$.pipe(
      first(),
      map((categories: Category[]) => categories.find((c) => c.cat === cat))
    )
  }

  /**
   * Convert a product to a product list item
   */
  private getProductListItem(product: IProduct): Observable<ProductListItem> {
    return this.getCategoryForProduct(product.cat).pipe(
      map((category: Category) => ({
        id: product.id,
        version: product.version,
        cat: product.cat,
        pc: product.pc,
        deSwLaSw: category.deSwLaSw,
        tiLaSw: category.tiLaSw,
        deEnLaSw: category.deEnLaSw
      } as ProductListItem))
    )
  }

  private addProductListItem(product: IProduct): Observable<ProductListItem> {
    let productList: ProductListItem[]
    return this.productsList$.pipe(
      first(),
      switchMap((pl: ProductListItem[]) => {
        productList = pl
        return this.getProductListItem(product)
      }),
      map((productListItem: ProductListItem) => {
        productList.push(productListItem)
        this.updateProductList(productList)
        return productListItem
      })
    )
  }

  /**
   * Take a list sort it and emit it on the $subject.
   *
   * @param productList
   * @private
   */
  private updateProductList(productList: ProductListItem[]): void {
    productList.sort((a: ProductListItem, b: ProductListItem) => a.pc.localeCompare(b.pc))
    this.productsList$.next(productList)
  }
}
