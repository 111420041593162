import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {CuttingBoardMill} from './mill-file/mill-file-types'

export class CuttingBoard extends CabinetOption {
  constructor(option: CuttingBoardMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)
    this.priority = 10

    this.viewOptions = [
      {
        type: ViewOptionType.binary,
        title: 'Utdragbar skärbräda',
        name: 'cuttingBoard',
        values: ['Ja', 'Nej'],
        selection: 'Nej',
        disabled: false
      }
    ]
    this.description = 'A cabinet can have a built-in cutting board'
    this.title = 'optCuttingBoard'
    this.setValuesFromProdboardData(option)
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'CuttingBoard'
  }

  public getCustomCustomerListing(useCase: TSettingOptionUseCase, lc: TLanguageCode): string[] {
    // 'Ja' is the only value for CuttingBoard
    return [this.settingOption.getI18n('Ja', useCase, lc)]
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.setYesNoActive(data)
    this.active = this.viewOptions[0].selection === 'Ja'
    this.setPrice()
  }

  private setValuesFromProdboardData(option: CuttingBoardMill): void {
    this.active = option.value === 'Ja'
    this.viewOptions[0].selection = option.value
  }

  private setPrice(): void {
    const count = this.viewOptions[0].selection === 'Ja' && this.active === true ? 1 : 0
    this.resetPrices()
    this.price = this.product.cuBoPr.price * count
    this.labor = this.product.cuBoPr.labor * count

    // We should not add an item if there are no cutting boards
    if (count > 0) {
      this.shouldHavePrice = true
    }
  }
}
