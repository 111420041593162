import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {DrawerDoorMill} from './mill-file/mill-file-types'
import {DRAWER_DOOR_DOOR, DRAWER_DOOR_DRAWER} from './model-types'

/**
 * Class/Model introduced for "Utdragslåda" in Oven cabinet BD1o, BD1o+PC
 */
export class DrawerDoor extends CabinetOption {
  constructor(option: DrawerDoorMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)

    this.priority = 100

    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Låda/Lucka',
        name: 'drawerDoor',
        values: [DRAWER_DOOR_DRAWER, DRAWER_DOOR_DOOR],
        selection: option.value,
        disabled: false
      }
    ]
    // Always active since it is either door or drawer. Drawers
    this.active = true
    this.description = 'MISSING'
    this.title = 'optDrawerDoor'
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'DrawerDoor'
  }

  public update(data: any) {
    super.update(data)
    this.setFromProperties(data)
    this.setPrice()
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    return [this.settingOption.getI18n(this.viewOptions[0].selection, useCase, lc)]
  }

  private setPrice(): void {
    this.resetPrices()
    if (this.viewOptions[0].selection === DRAWER_DOOR_DRAWER) {
      this.price = this.product.hiDrPr.price
      this.labor = this.product.hiDrPr.labor
      this.shouldHavePrice = true
    }
  }
}
