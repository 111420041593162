import {Injectable} from '@angular/core'
import {TLanguageCode} from '../../application/i18n.provider'
import {FRAME_LEFT_POS, FRAME_RIGHT_POS} from './frame-width'
import {IProduct} from '../../common/interface/product-types'
import {ProdboardCabinet} from '../cabinet/prodboard-cabinet'
import {ViewOption} from '../cabinet-option'

@Injectable({providedIn: 'root'})
export class FrameWidthHelper {

  /**
   * Return a very special string to be displayed in the customer spec. But ONLY
   * if it is a dishwasher cabinet!
   * @param viewOptions
   * @param product
   * @param lc Language Code
   * @param cabinet
   */
  public getString(viewOptions: ViewOption[], product: IProduct, lc: TLanguageCode, cabinet: ProdboardCabinet): string {

    if (product.idc !== true) {
      return ''
    }
    const leftWidth = +viewOptions[FRAME_LEFT_POS].selection
    const rightWidth = +viewOptions[FRAME_RIGHT_POS].selection
    const baseWidth = cabinet.dimensions.x

    const actualWidth = baseWidth - 6 - leftWidth - rightWidth

    if (leftWidth > 0 && rightWidth > 0) {
      if (lc === 'sv') {
        return `Ramarna/stödbrädorna är ${leftWidth} mm tjock på vänster sida, ${rightWidth} mm på höger sida. ` +
          `Total bredd för diskmaskinen är därför ${baseWidth}` +
          ` mm (luckan är ${actualWidth} + 3 mm glipa på vardera sida + ` +
          `${leftWidth + rightWidth} mm stödbrädor)`
      }
      return `${leftWidth} + 3 + ${actualWidth} + 3 + ${rightWidth} = ${baseWidth}`
    }

    if (leftWidth > 0) {
      if (lc === 'sv') {
        return `Ramen/stödbrädan är ${leftWidth} mm tjock. Total bredd för diskmaskinen är därför ` +
          `${baseWidth} mm (luckan är ${actualWidth} + 3 mm glipa på vardera sida + ${leftWidth} mm stödbräda)`
      }
      return `${leftWidth} + 3 + ${actualWidth} + 3 = ${baseWidth}`
    }

    if (rightWidth > 0) {
      if (lc === 'sv') {
        return `Ramen/stödbrädan är ${rightWidth} mm tjock. Total bredd för diskmaskinen är ` +
          `därför ${baseWidth} mm (luckan är ${actualWidth} + 3 mm glipa på vardera sida + ${rightWidth} mm stödbräda)`
      }
      return `3 + ${actualWidth} + 3 + ${rightWidth} = ${baseWidth}`
    }

    // Both must be 0 or less.
    if (lc === 'sv') {
      return `Total bredd för diskmaskinen är ${baseWidth} mm (luckan är ${actualWidth}` +
        ` mm + 3 mm glipa på vardera sida = ${baseWidth} mm)`
    }
    return `3 + ${actualWidth} + 3 = ${baseWidth}`
  }
}
