import {FactoryExtra, paintPrices, TFactoryExtraUnit} from './factory-extra'

export const FactoryCorniceStyles = ['regular', 'funkis', 'non-standard'] as const
export type TFactoryCorniceStyle = typeof FactoryCorniceStyles[number]

export const CORNICE_STANDARD_HEIGHT = 45

export class Cornice extends FactoryExtra {

  public corniceStyle: TFactoryCorniceStyle = 'regular'

  public override price = 100
  public override labor = 4
  public override unit: TFactoryExtraUnit = 'm'

  public height: number = CORNICE_STANDARD_HEIGHT

  public fFactor = 0

  public cFactor = 0

  constructor(input: Partial<Cornice> = {}) {
    super(input)
    /**
     * Seriously do not know why this is needed but it appears that I have to. :(
     */
    Object.assign(this, input)
  }

  override get totalFactoryPrice(): number {
    this.adjustPrice()
    if (this.setLabor !== null) {
      return this.setLabor
    }
    const price = this.labor * this.quantity
    const reduction = price * (this.fFactor / 100)
    return price - reduction
  }

  override get totalCustomerPrice(): number {
    this.adjustPrice()
    if (this.setPrice !== null) {
      return this.setPrice
    }
    const price = this.price * this.quantity
    const reduction = price * (this.cFactor / 100)
    return price - reduction
  }

  public adjustPrice(): void {
    this.price = 100
    if (this.corniceStyle === 'non-standard') {
      this.price = 200
      this.labor = 8
    }
    this.fFactor = paintPrices.get(this.paintProcess).fReduction
    this.cFactor = paintPrices.get(this.paintProcess).cReduction
  }
}
