import {
  CUSTOMER_PRICE_FACTOR,
  doorPrices,
  FactoryExtra,
  paintPrices,
  TFactoryExtraUnit,
  TFactoryFrontStyle
} from './factory-extra'

export class DrawerFront extends FactoryExtra {


  public override price = null
  public override labor = null
  public override material = 0
  public override unit: TFactoryExtraUnit = 'pcs'
  public override drawerFrontType: TFactoryFrontStyle = 'Släta lådfronter'
  public override drawerBoxDepth = 505
  public override cutoutHandle: boolean = false
  public override drawerRunners = true
  public override waxedInside = true

  public startCostLabor: number

  constructor(input: Partial<DrawerFront> = {}) {
    super(input)
    Object.assign(this, input)
  }

  override get totalFactoryPrice(): number {
    this.adjustPrice()
    if (this.setLabor !== null) {
      return this.setLabor
    }
    return this.labor * this.quantity + this.startCostLabor
  }

  override get totalCustomerPrice(): number {
    this.adjustPrice()
    if (this.setPrice !== null) {
      return this.setPrice
    }
    const startCostPrice = this.startCostLabor * CUSTOMER_PRICE_FACTOR
    return (this.price * this.quantity) + startCostPrice
  }

  override get totalHeight(): number {
    return this.drawerBoxHeight !== null ? this.drawerBoxHeight : this.height
  }

  override get totalVolume(): number {
    return (this.totalHeight / 1000) * (this.width / 1000) * (this.drawerBoxDepth / 1000)
  }

  public adjustPrice(): void {

    const frontSize: number = (this.height * this.width) / 1000 / 1000
    this.material = frontSize * paintPrices.get(this.paintProcess).material
    const frontLabor: number = frontSize * doorPrices.get(this.doorStyle).labor

    const drawerBoxLabor = this.totalVolume * 220
    const runnerLabor = this.drawerRunners ? 22 : 0
    const cutoutLabor = this.cutoutHandle ? 5 : 0
    const waxedInsideLabor = this.waxedInside ? 5 : 0

    this.startCostLabor = this.quantity * 61

    this.labor = frontLabor + drawerBoxLabor + runnerLabor + cutoutLabor + waxedInsideLabor

    const frontPrice = frontLabor * CUSTOMER_PRICE_FACTOR
    const drawerBoxPrice = drawerBoxLabor * CUSTOMER_PRICE_FACTOR
    const runnerPrice = runnerLabor * CUSTOMER_PRICE_FACTOR
    const cutoutPrice = cutoutLabor * CUSTOMER_PRICE_FACTOR
    const waxedInsidePrice = waxedInsideLabor * CUSTOMER_PRICE_FACTOR

    this.price = frontPrice + drawerBoxPrice + runnerPrice + cutoutPrice + waxedInsidePrice
  }
}
