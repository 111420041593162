import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {HandleDoorMill} from './mill-file/mill-file-types'

export class HandleDoor extends CabinetOption {
  constructor(option: HandleDoorMill, product: IProduct, cabinetId: number, optionCount: number, quantity: number) {
    super(product, cabinetId, optionCount)

    this.quantity = quantity
    this.priority = 100

    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Vred',
        name: 'handleDoor',
        values: ['Ja', 'Nej'],
        selection: 'Nej',
        disabled: false
      }
    ]
    this.active = true
    this.groupName = `optHandleDoorGroup`
    this.description = 'Special kind of knob style, which looks like ' +
      'this: https://www.gyllenhaks.se/index.php?main_page=product_info&products_id=1548'
    this.title = optionCount ? `${optionCount}. optHandleDoor` : `optHandleDoor`

    this.setValuesFromProdboardData(option)
  }

  get optionSelectName(): TOptionSelectName {
    return 'HandleDoor'
  }

  public isVred(): boolean {
    return this.viewOptions[0].selection === 'Ja'
  }

  public update(data: any) {
    super.update(data)
    this.setFromProperties(data)
    this.active = data.handleDoor === 'Ja'
    this.resetPrices()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getCustomCustomerListing(useCase: TSettingOptionUseCase, lc: TLanguageCode): string[] {
    // Nothing to show in any case
    return []
  }

  /**
   * Each class implements its own logic for setting the price and.
   * possibly other options. All options should be available in the
   */
  private setValuesFromProdboardData(option: HandleDoorMill): void {
    this.active = option.value === 'Ja'
    this.viewOptions[0].selection = option.value
  }
}
