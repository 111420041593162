import {TLanguageCode} from '../application/i18n.provider'
import {Comment, CommentHomeEntity, Comments} from '../comments/model/comment'
import {
  cRecessMap,
  IDefaultFrameAndRecessItem,
  IProduct,
  TRecessSkirtingType
} from '../common/interface/product-types'
import {getDefaultSettingOption} from '../services/override.defaults'
import {Problem} from '../services/problem.service'
import {TProjectFileOptionName} from '../services/project-file-types'
import {
  SettingsOption,
  TSettingOptionUseCase
} from '../services/settings-item.service'
import {ProdboardCabinet} from './cabinet/prodboard-cabinet'
import {FactoryData} from './factory/factory-data'
import {FrameWidth} from './frame-width/frame-width'
import {STANDARD_SKIRTING} from './model-types'
import {Skirting} from './skirting'

/**
 * This is used to define the type of view option. Basically the
 * same that are available in any form. The "enum" thing is just
 * to avoid typos, nothing more nothing less.
 */
export enum ViewOptionType {
  binary,
  text,
  select,
}


export interface ViewOption {
  /**
   * This is a name, like "drawer", it will be used as a "property"
   * on an object so please select names carefully.
   */
  name: string

  /**
   *  This is what we display on the form item. It should be human-readable
   *  in Swedish. We have to think about translations later.
   */
  title: string

  /**
   * Values is always an array. If it is a simple input it is just one.
   * It could be [ 'Ja', 'Nej' ] for a radio. It should be in Swedish until
   * we have decided on translation magix
   */
  values: string[]

  /**
   * See the type, currently we support binary (on/off), text and select.
   * I guess next level is checkbox. Are there more?
   */
  type: ViewOptionType

  /**
   * This is the value that is currently selected. Depending
   * on complexity the class will handle this in various ways.
   */
  selection: string

  /**
   * If this option should be read only.
   */
  disabled: boolean

  /**
   * Options may stuff other things into the data property to
   * be able to put various stuff into it for easier calculations.
   */
  data?: any
}

export type TOptionSelectName = Capitalize<TProjectFileOptionName>

export abstract class CabinetOption implements Comments {

  public commentHome: CommentHomeEntity = {type: 'OPTION', id: ''}

  /**
   * new entities for displaying the item translated
   */
  public description: string
  public title: string
  /**
   * Price is price to end customer.
   * It should be filled-in individually in every CabinetOption.
   * It should not be used for display. Instead, use {@link ProjectPricing} and
   * all its utils {@link ProjectPricingUtils}.
   */
  public price = 0

  /**
   * This is what we pay when ordering it.
   * It should be filled-in individually in every CabinetOption.
   * It should not be used for display. Instead, use {@link ProjectPricing} and
   * all its utils {@link ProjectPricingUtils}.
   */
  public labor = 0

  /**
   * A few options have extra material cost.
   * It should be filled-in individually in every CabinetOption.
   * It should not be used for display. Instead, use {@link ProjectPricing} and
   * all its utils {@link ProjectPricingUtils}.
   */
  public material = 0

  /**
   * Name is a unique short combo like PaintProcess_1
   * it should not ever be used for display purposes
   */
  public name = 'You forgot to set this!'
  /**
   * Use to display the main header of the combined settings
   * */

  public groupName = 'Standard'
  /**
   * This is used to sort options only.
   *
   * If not active it will be given the lowest priority
   *
   */
  public active = false

  /**
   * Default priority is low. Depending on stuff an option
   * can give itself higher or lower priority. This is only used
   * to control the ordering of options.
   */
  public priority = 100

  /**
   * General count of this type settings
   */
  public quantity = 1

  /**
   * This is an array of options to use in forms to display/change
   */
  public viewOptions: ViewOption[] = [{} as any]

  public comments: Comment[] = []

  /**
   * We do not want to import the problem service so, we
   * populate this list with problems and let someone else
   * iterate it.
   */
  public problems: Problem[] = []

  /**
   * This is for the warning system to be able to throw
   * a warning if an option have a price of 0 when it shouldn't
   */
  public shouldHavePrice = false

  /**
   * An attempt to give uuid to all options?s
   */
  public id: string

  /**
   * Setting Option related to this CabinetOption. It's assigned on creation
   * @protected
   */
  protected settingOption: SettingsOption

  protected constructor(
    protected product: IProduct, // This is the product from the product database.
    public cabinetIndex: number,
    public optionCount: number = 0
  ) {

    const defaultProduct: any = {
      pr: {},
      enPaPr: {},
      baPaPr: {},
      cuDiPr: {price: 0, labor: 0, material: 0},
      knBlPr: {price: 0, labor: 0, material: 0},
      waBlPr: {price: 0, labor: 0, material: 0},
      glDoPr: {}, // Do not set default on this, we need it to know if we _can_ have glass door
      noDoPr: {price: 0, labor: 0},
      exShPr: {price: 0, labor: 0},
      hiDrPr: {price: 0, labor: 0},
      liPr: {price: 0, labor: 0, material: 0},
      cuBoCoPr: {}, // Carpenter Joy
      cuBoPr: {}, // Cutting board (utdragbar skärbräda)
      adShPr: {},
      shIdPr: {price: 0, labor: 0, material: 0}, // Shelves inside door
      coDeEnPaPr: {price: 0, labor: 0}, // Console decorations
      // Two prices for Extractor Hood, "Carcass Side" "Painted Side"
      csPr: {price: 0, labor: 0},
      psPr: {price: 0, labor: 0},
      // Set number of doors and drawers to zero if not set
      nuDo: 0,
      nuDr: 0
    }
    // Let us make sure there are always prices present
    this.product = {...defaultProduct, ...this.product}
    this.setNameAndOptions()
  }

  /**
   * Method to be implemented by every CabinetOption to correctly get their
   * option name, like "PaintProcess" or "BackPanel".
   * It is needed to do it this way because we need the name in CabinetOption's
   * constructor. Hence, it cannot be declared as normal property in child
   * classes, because it will be recognised as "undefined" in super's
   * constructor.
   */
  abstract get optionSelectName(): TOptionSelectName

  /**
   * Method to be implemented by every CabinetOption that will provide the
   * proper i18n text when needed, depending on the selected values, etc.
   * Even some of them will return "nothing", an empty array/empty strings.
   * @protected
   */
  public abstract getCustomCustomerListing(
    useCase: TSettingOptionUseCase, lc: TLanguageCode): string[]

  /**
   * This is a half-miserable sorting function to sort options based
   * on priority and active.
   *
   * It sorts the ACTIVE options and the non-active then returns the
   * concatenated result.
   */
  public static sortOptions(options: CabinetOption[]): CabinetOption[] {
    const active = options.filter((o) => o.active)
    const inActive = options.filter((o) => !o.active)
    active.sort(CabinetOption.sort)
    inActive.sort(CabinetOption.sort)
    return active.concat(inActive)
  }

  private static sort(a: CabinetOption, b: CabinetOption): number {
    if (a.priority > b.priority) {
      return 1
    } else {
      return a.priority === b.priority ? 0 : -1
    }
  }

  /**
   * Set the related SettingOption. It will be used for any translation
   * text that's needed.
   * We are linking the SettingOption this way because we cannot inject a
   * service here. Ideally we could use SettingItemService.
   * @param settingOption
   */
  public setSettingOption(settingOption: SettingsOption): void {
    // Set the related SettingOption. It will be used for any translation
    // text that's needed.
    this.settingOption = settingOption
  }

  /**
   * Since the "cabinet" is just referenced by "index" we add this
   * method. It is called by the cabinet after the option is instantiated
   * All in one go or one by one.
   *
   * @param id - The holding cabinet uid
   */
  public setCommentHomeId(id: string): void {
    this.commentHome.id = `${id}_${this.name}`
  }

  public getOptionMap(): any {
    const res: any = {}
    this.viewOptions.forEach((opt: ViewOption) => {
      res[opt.name] = opt.selection
    })
    return res
  }

  /**
   * Method used to create a summary for a Project.
   * It tries to get a translation for the Settings Option Value corresponding
   * to the first "viewOption" selection.
   * If it doesn't find it, it will use the
   */
  public getTextForProjectSummary(
    uc: TSettingOptionUseCase,
    lc: TLanguageCode = 'sv'
  ): string {
    return this.settingOption.getI18n(this.viewOptions[0].selection, uc, lc)
  }

  /**
   * This is used by the matrix view and import when showing the
   * complete "config" as a one-liner.
   *
   */
  public createOptionsSummary(): string {
    return this.viewOptions.map((option) =>
      option.selection
    ).join(' ')
  }

  /**
   * This is used by the matrix view and import when showing the
   * complete "config" as a one-liner.
   *
   */
  public createOptionsSummaryLong(): string {
    return this.viewOptions.map((option) =>
      `${option.title}: ${option.selection}`
    ).join(' ♢ ')
  }

  /**
   * All cabinet options must be able to get updated data
   * from the outside. This is on the "base" class so that
   * we can work generally.
   *
   * Resets problems and sets comments, nothing else
   */
  public update(options: any): void {
    // Reset the problem list
    this.problems.length = 0
    if (options.comments) {
      this.comments = options.comments
    }
  }

  /**
   * All options should be able to return the
   * actual production values, these come in various
   * shapes and forms.
   */
  public getFactoryData(): FactoryData {
    // This should be implemented for CAD later.
    return {}
  }

  /**
   * We use this to recalculate values in the cabinet options model if needed
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setOptionValues(cabinet: ProdboardCabinet): void {
    /**
     * Empty for a reason
     */
  }

  /**
   * A per option value map with "core" values. Introduced for
   * the cad export. Basically to avoid variable texts.
   */
  public valueMap(): Record<string, string | number | boolean> {
    return {not: 'implemented'}
  }

  /**
   * Resolves the default bottom and top recess and frame widths. Public
   * for testing purposes only
   *
   * @param product - Should have the rct value set
   * @param skirting - Should be outside or standard.
   * @param frame - The frame is needed to check the specials
   */
  public getDefaultRecess(product: IProduct, skirting: Skirting, frame: FrameWidth): IDefaultFrameAndRecessItem {
    const skirtType: TRecessSkirtingType = skirting.viewOptions[0].selection === STANDARD_SKIRTING ? 'standard' : 'outside'
    const recessType = product.rct
    if (cRecessMap[recessType]) {
      if (['wall'].indexOf(recessType) !== -1) {
        const res: IDefaultFrameAndRecessItem = {bf: 0, br: 0, tf: 0, tr: 0}
        Object.assign(res, cRecessMap[recessType][skirtType])
        const bottomRecess = frame.bottom - 20
        res.br = bottomRecess >= 0 ? bottomRecess : 0
        return res
      }
      return Object.assign({}, cRecessMap[recessType][skirtType])
    } else {
      this.problems.push({
        description: `Produkten ${product.pc} har ingen Recess Cabinet Type`,
        handled: false
      })
      return {bf: 0, br: 0, tf: 0, tr: 0}
    }
  }

  /**
   * Customer listing will automatically select the used language depending
   * on the use-case.
   * For "customer" (c) use cases the preferred language is Swedish.
   * For "factory" (f) use cases the preferred language is English.
   *
   * NOTE: It filters empty strings to not appear
   */
  public getCustomerListing(useCase: TSettingOptionUseCase): string[] {
    // We are going to control the language here for now.
    // If we want to implement full internationalisation, it will need to come
    // as parameter.
    return this.getCustomCustomerListing(useCase, useCase === 'c' ? 'sv' : 'en')
      // Filter empty strings
      .filter(s => s)
  }

  protected setNameAndOptions(): void {
    this.setSettingOption(getDefaultSettingOption(this.optionSelectName))
    // Set CabinetOption name: it's composed buy its category name, e.g.
    // "BackPanel", plus the count value, which is increased with every cabinet
    // option that is in the project with the same category.
    // For some cases "this.option.count" will be undefined. Do not worry.
    // It's just a name to identify the cabinet option, and this "undefined"
    // is useful to identify those CabinetOptions that have been created in
    // ProdboardCabinet > analyzeItems() instead of analyzeOptions().
    this.name = `${this.optionSelectName}_${this.optionCount}`
  }

  /**
   * Set the values (selection) of viewOptions, assuming that the viewOptions come in the
   * order of the properties...
   *
   * @param input - An object with keys matching the properties in the viewOptions
   */
  protected setFromProperties(input: Record<string, number | string>): void {
    this.viewOptions
      .map((o: ViewOption) => o.name)
      .forEach((prop, index) => {
        if (input[prop] != null) {
          this.viewOptions[index].selection = input[prop] + ''
        }
      })
  }

  /**
   * Simple helper to make sure we reset all price info.
   */
  protected resetPrices(): void {
    this.price = 0
    this.material = 0
    this.labor = 0
    this.shouldHavePrice = false
  }

  /**
   * If explicit "active" in the data, then we should
   * force the yes selection and vice versa.
   */
  protected setYesNoActive(data: CabinetOption): void {
    if (data.active === true) {
      this.viewOptions[0].selection = 'Ja'
      this.active = true
    }

    if (data.active === false) {
      this.viewOptions[0].selection = 'Nej'
      this.active = false
    }
  }
}
