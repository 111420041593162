import {ProdboardCabinet} from '../cabinet/prodboard-cabinet'

export class CabinetSettings {
  public index: number

  public code: string

  public title: string

  public titleEn: string

  public height: number

  public width: number

  public depth: number

  public price: number

  public labor: number

  public material: number

  public productCommentEn: string

  public productCommentSv: string

  public hiddenWidth: number

  public visibleWidth: number

  /**
   * The id of the counter top that sits on the cabinet
   */
  public counterTop: string

  /**
   * A list of heights of drawers, maps to dhs in the product
   */
  public drawers: number[]

  constructor(input: any = {}) {
    Object.assign(this, input)
  }

  public fromCabinet(cabinet: ProdboardCabinet) {
    this.index = cabinet.index
    this.code = cabinet.cat
    this.title = cabinet.deSwLaSw
    this.titleEn = cabinet.description
    this.height = cabinet.actualHeight
    this.width = cabinet.dimensions.x
    this.depth = cabinet.dimensions.z
    this.price = cabinet.basePrice
    this.labor = cabinet.baseLabor
    this.material = cabinet.baseMaterial
    this.productCommentEn = cabinet.productComments.en
    this.productCommentSv = cabinet.productComments.sv
    this.drawers = cabinet.drawers
    this.visibleWidth = cabinet.vWidth
    this.hiddenWidth = cabinet.hWidth
    this.counterTop = ''
  }
}
