<div kdlI18n label="Kulladal Summary" matDialogTitle></div>

@if (projectsSummary$ | async; as project) {
  <div class="main">
    <div class="waiting">
      <mat-icon class="material-icons-outlined" color="accent">
        pending_actions
      </mat-icon>

      <span kdlI18n label="The Spec: Waiting for the customer:"></span>
      &nbsp;{{ project.waiting }}
    </div>
    @for (keyValue of customerStateMap.stateNameMap | keyvalue:sorting; track $index) {
      <div class="item">
        <img [ngSrc]="keyValue.value.image" alt="icon"
             height="20"
             width="20">
        {{ keyValue.value.position + 1 }}.
        {{ keyValue.value.name }}:
        {{ project[keyValue.key] }}
      </div>
    }
  </div>
}

<mat-dialog-actions>
  <button color="primary" mat-dialog-close="" mat-flat-button>
    <span kdlI18n label="closeBtn"></span>
  </button>
</mat-dialog-actions>
