<mat-dialog-content class="price-summary-dialog-holder">
  @if (!pricingTables.length) {
    <mat-spinner color="primary"></mat-spinner>
  } @else {
    <mat-tab-group class="price-summary-tabs-holder">
      @for (pricingTable of pricingTables; track pricingTable.title) {
        <mat-tab>
          <ng-template mat-tab-label>
            <span kdlI18n [label]="pricingTable.title"></span>
          </ng-template>

          <div class="price-summary-table">
            <div class="price-summary-section">
              <div class="price-summary-row-header"
                   [ngClass]="'columns-n-' + pricingTable.columns.length">
                @for (col of pricingTable.columns; track col) {
                  <div [class]="'price-summary-cell price-summary-cell-' + col"
                       kdlI18n [label]="'priceTable_column_' + col"></div>
                }
                <div class="price-summary-cell price-summary-cell-expand"></div>
              </div>
            </div>

            @for (section of pricingTable.rows; track $index) {
              <div class="price-summary-section">
                <ng-container
                  [ngTemplateOutlet]="subsectionContainer"
                  [ngTemplateOutletContext]="{row: section}"
                ></ng-container>
              </div>
            }
          </div>

          <ng-template #subsectionContainer let-row="row">
            <kdl-price-summary-row
              [row]="row"
              [visibleColumns]="pricingTable.columns"
            ></kdl-price-summary-row>

            @if (row.subRows?.length) {
              <div class="price-summary-subsection"
                   [@expandAnimation]="!row.expanded ? 'collapsed' : 'expanded'"
                   [class.collapsed]="!row.expanded">
                @for (subRow of row.subRows; track $index) {
                  <ng-container
                    [ngTemplateOutlet]="subsectionContainer"
                    [ngTemplateOutletContext]="{row: subRow}"
                  ></ng-container>
                }
              </div>
            }
          </ng-template>
        </mat-tab>
      }
    </mat-tab-group>
  }
</mat-dialog-content>
