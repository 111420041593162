import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {BrassPlateMill} from './mill-file/mill-file-types'

export class BrassPlate extends CabinetOption {
  constructor(option: BrassPlateMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)

    this.priority = 20
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Mässingsskylt med Kulladalslogo',
        name: 'brassPlate',
        values: ['Vänster', 'Höger', 'Båda'],
        selection: 'Ingen',
        disabled: false
      }
    ]
    this.active = false
    this.description = ''
    this.title = 'optBrassPlate'
    this.setValuesFromProdboardData(option)
  }

  get optionSelectName(): TOptionSelectName {
    return 'BrassPlate'
  }

  public update(data: any) {
    super.update(data)
    this.setFromProperties(data)
  }

  setValuesFromProdboardData(option: BrassPlateMill) {
    this.viewOptions[0].selection = option.value
    this.active = option.value !== 'Ingen'
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    // We don't show it for customer use-case
    if (useCase === 'c') {
      return []
    }

    return [this.settingOption.getI18n(this.viewOptions[0].selection, useCase, lc)]
  }
}
