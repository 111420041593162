import {Observable} from 'rxjs'
import {IProjectBase} from '../../services/project-types'

/**
 * u = User
 * uf = Unfinished task
 * w = Waiting for Customer
 * ph = Phase tag
 * pq = Prodboard Quote request
 * b = Ball, who has the ball for specific conditions/actions
 * pb = Phase Ball, who has the ball within a whole phase
 */
export type TTagTypes = 'u' | 'uf' | 'w' | 'ph' | 'pq' | 'b' | 'pb'

export const PHASE_TAG_ID = '3'

export const PB_QUOTE_REQ_ID = '4'

export const PHASE_BALLS_TAG_ID = '6'

export interface ITag {
  /**
   * This is the id of the TAG not the project
   */
  id: string

  /**
   * We need an additional type to sort and filter these
   */
  type: TTagTypes

  /**
   * The project as a backreference
   */
  project: IProjectBase

  /**
   * All tags have a label. The label is the
   * short version like the initials of the name
   */
  label: string

  /**
   * Name is the long version of the tag
   */
  name: string

  /**
   * Color of tag as #000000
   */
  color: string

  /**
   * Sort order for tag, you _can_ set these to arrange
   * the tags, See TagSortPipe
   */
  sort: number

  /**
   * If you can select this tag (true) or if it is
   * an auto tag (false) that are set by the system
   * weather you want it or not.
   */
  selectable: boolean

  /**
   * If you can have several of this. This implies
   * configurability.
   */
  multi: boolean

  /**
   * Return only the strictly necessary parts, normally just the ID and type
   */
  getRawValue: () => Partial<ITag>

  /**
   * If present it should return an observable
   * with the tag configured
   */
  configure: (...args: any) => Observable<ITag | null>

  /**
   * Called when the tag is created, possibly
   * @param args
   */
  onTagCreate: (...args: any) => Observable<ITag | null>

  /**
   * Called when the tag is removed
   * @param args
   */
  onTagRemove: (...args: any) => Observable<ITag | null>

  /**
   * This is a tag that should not be seen (un most cases)
   */
  hidden: boolean

  /**
   * This is for temporarily hiding the tag in some contexts
   */
  invisible: boolean

  /**
   * An extended description of the tag, always a string
   * but '' if not set. Used for e.g. Balls tag to indicate
   * source condition.
   *
   * NB: You may need to save this explicitly in getRawValue.
   */
  description: string
}