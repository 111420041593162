import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  public transform(value: string): string {
    return value
      .split(' ')
      .map((name: string) => name.slice(0, 1))
      .join('')
      .toUpperCase()
  }

}
