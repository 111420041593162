import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'

export const PLINTH_OPTION_KEY = 'Sockelhöjd'

export class Plinth extends CabinetOption {
  constructor(product: IProduct, cabinetId: number) {
    super(product, cabinetId)

    this.viewOptions = [
      {
        type: ViewOptionType.text,
        title: 'Sockelhöjd',
        name: 'socelHeight',
        values: [],
        selection: '0',
        disabled: false
      }
    ]

    this.description = 'Height of the plinth which the cabinets stands on'
    this.title = 'optPlinth'
    // We never set values on create, b/c this can only get data from project or update
  }

  get optionSelectName(): TOptionSelectName {
    return 'Plinth'
  }

  public update(data: any): void {
    super.update(data)
    this.active = false
    this.priority = 100
    this.viewOptions[0].selection = ''
    if (data.socelHeight || data.socelHeight === 0) {
      this.viewOptions[0].selection = data.socelHeight
      this.active = true
      this.priority = 99 // High prio if set
    }
    this.setPrice()
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    return [
      this.settingOption.getI18n(PLINTH_OPTION_KEY, useCase, lc) +
      `${this.viewOptions[0].selection} mm`]
  }

  private setPrice(): void {
    this.resetPrices()
  }
}
