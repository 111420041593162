import {FactoryExtra, paintPrices, TFactoryExtraUnit, TFactoryExtraWoodTypes} from './factory-extra'
import {DefaultMap} from '../../application/helpers'

const woodCostAddition: DefaultMap<TFactoryExtraWoodTypes, number> = new DefaultMap([
  ['pine', 1],
  ['oak', 1.5],
  ['ash', 1.25]
], ['pine, 1'])

export class Board extends FactoryExtra {

  public override labor: number = 160
  public override price: number = 4000
  public override unit: TFactoryExtraUnit = 'pcs'
  public override woodType: TFactoryExtraWoodTypes = 'pine'

  private area = 0
  private woodAddition = 1

  constructor(input: Partial<Board> = {}) {
    super(input)

    Object.assign(this, input)
  }

  override get totalFactoryPrice(): number {
    this.adjustPrice()
    if (this.setLabor !== null) {
      return this.setLabor
    }
    const price: number = (this.labor * this.area * this.woodAddition) * this.quantity
    const reduction: number = price * (paintPrices.get(this.paintProcess).fReduction / 100)

    return price - reduction
  }

  override get totalCustomerPrice(): number {
    this.adjustPrice()
    if (this.setPrice !== null) {
      return this.setPrice
    }
    const price: number = (this.price * this.area * this.woodAddition) * this.quantity
    const reduction: number = price * (paintPrices.get(this.paintProcess).cReduction / 100)

    return price - reduction
  }

  private adjustPrice(): void {
    this.area = (this.width / 1000) * (this.height / 1000)
    this.woodAddition = woodCostAddition.get(this.woodType)
  }
}
