<div class="deadlines">
  <mat-label>Select deadline</mat-label>

  @if (suppressConfirmAction) {
    <button
      *ngFor="let preset of presets | keyvalue: originalOrder"
      mat-button
      class="button"
      (click)="control.setValue(preset.value)"
    >
      {{ preset.key }}
    </button>
  } @else {
    <mat-radio-group [formControl]="control" ngDefaultControl>
      <mat-radio-button
        *ngFor="let preset of presets | keyvalue: originalOrder"
        [value]="preset.value"
      >
        {{ preset.key }}
      </mat-radio-button>
    </mat-radio-group>
  }

  <mat-form-field [style.margin-top.px]="10" [style.width.px]="150" subscriptSizing="dynamic">
    <mat-label>Due date</mat-label>
    <input [formControl]="control"
           [matDatepicker]="picker"
           matInput>
    <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
