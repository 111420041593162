import {TApplianceTypeName} from '../service/appliance.service'
import {LeafFactory} from './leaf-factory'


/**
 * It is an item, not a Map so we name it item for clarity
 */
export interface IApplianceItem {
  /**
   * A future icon for each selection
   */
  icon: string
  /**
   * Display name, currently English only
   */
  name: string
  /**
   * Key as an identifier for this "level"
   */
  key: TApplianceItemKey
  /**
   * Basically a legacy selector for "applianceType"
   */
  type?: TApplianceTypeName
  /**
   * If we have others following mutually exclusive
   * with controls
   */
  options?: IApplianceItem[]
  /**
   * Form controls for this leaf item.
   */
  controls?: Control[]
}

export const ControlTypes = ['text', 'number', 'radio', 'select', 'file', 'url'] as const
export type TControlType = typeof ControlTypes[number]

export const ControlNames = [
  'artNr',
  // Legacy name for "description
  'description',
  'price',
  'material',
  'labor',
  'width',
  'url',
  'image',
  'depth',
  'height',
  'size',
  'dishwasherValve',
  'supplier',
  'brand',
  // We use name for legacy reasons. Is Model Name.
  'name'
] as const
export type TControlName = typeof ControlNames[number]

export interface IControlSetting {
  type: TControlType
  label: TControlName | 'additionalInfo' | 'modelName'
  suffix?: string
}

const ControlSettingsMap = new Map<TControlName, IControlSetting>([
  ['artNr', {type: 'text', label: 'artNr'}],
  ['description', {type: 'text', label: 'additionalInfo'}],
  ['url', {type: 'text', label: 'url'}],
  ['image', {type: 'file', label: 'image'}],
  ['dishwasherValve', {type: 'radio', label: 'dishwasherValve'}],
  ['supplier', {type: 'select', label: 'supplier'}],
  ['brand', {type: 'text', label: 'brand'}],
  ['name', {type: 'text', label: 'modelName'}],
  ['price', {type: 'number', label: 'price', suffix: 'kr'}],
  ['labor', {type: 'number', label: 'price', suffix: '€'}],
  ['material', {type: 'number', label: 'price', suffix: 'kr'}],
  ['width', {type: 'number', label: 'width', suffix: 'mm'}],
  ['depth', {type: 'number', label: 'depth', suffix: 'mm'}],
  ['height', {type: 'number', label: 'height', suffix: 'mm'}],
  ['size', {type: 'number', label: 'size', suffix: 'mm'}]
])

export class Control {
  public value = null

  /**
   * Actually string, number, radio
   *
   * This is sometimes used for the input type..
   */
  public type: TControlType

  /**
   * What we show if we want to. Not that
   * this default is a kdl18n label that
   * matches our name but that may change.
   *
   * Potentially make this a K18n item...
   */
  public label: string


  /**
   * An optional suffix
   */
  public suffix?: string

  constructor(
    public name: TControlName,
    public required: boolean = true
  ) {
    // This _shall_ break if no match!
    this.type = ControlSettingsMap.get(name).type
    this.label = ControlSettingsMap.get(name).label
    this.suffix = ControlSettingsMap.get(name).suffix
  }
}

export const ApplianceItemKeys = [
  'white_goods',
  'stoves',
  'hobs',
  'extractorHobs',
  'ovens',
  'microwaveCombos',
  'micros',
  'extractors',
  'dishwashers',
  'fridges',
  'fridge',
  'wine_fridge',
  'freezers',
  'fridge_freezers',
  'electric',
  'gas',
  'free_standing',
  'integrated',
  'fixed',
  'pull_out',
  'wall_mounted',
  'free_hanging',
  'width_60',
  'width_70',
  'width_90',
  'width_100',
  'width_120',
  'height_standard',
  'height_xxl',
  'other',
  'width_45',
  'sliding_door',
  'door_on_door',
  // Fittings
  'fittings',
  'handles',
  'knobs',
  'turning_knobs',
  'knob',
  'hooks',
  'knob_hook',
  'brass',
  'chrome',
  'chrome_nickel',
  'copper',
  'stainless',
  'antique',
  'wood',
  'leather',
  'black',
  'white',
  'large',
  'small',
  'overlay_door',
  'inset_door',
  // Sinks & faucet
  'sink_faucet',
  'sink',
  'faucet',
  'basket_strainer',
  'single',
  'double',
  'porcelain',
  'gold',
  'antique_bronze',
  'brushed_nickel',
  'sink_inset',
  'sink_outside',
  // Paint
  'paints',
  'linseed',
  'standard',
  '1', '12', '19', '25', '31',
  '32', '34', '38', '48', '58',
  '67', '69', '86', '87', '97',
  '100', '101', '107', '111', '116',
  '148', '151',
  'accessories_others'

] as const
export type TApplianceItemKey = typeof ApplianceItemKeys[number]


export const applianceTree: IApplianceItem[] = [
  {
    name: 'White Goods',
    key: 'white_goods',
    icon: 'white-goods.png',
    type: 'group',
    options: [
      {
        name: 'Stove',
        key: 'stoves',
        icon: 'stove.png',
        type: 'stove',
        options: [
          {
            name: 'Electric',
            key: 'electric',
            icon: '',
            controls: LeafFactory.getStoveHobControls()
          },
          {
            name: 'Gas',
            key: 'gas',
            icon: '',
            controls: LeafFactory.getStoveHobControls()
          }
        ]
      },
      {
        name: 'Hob',
        key: 'hobs',
        icon: 'hob.png',
        type: 'hob',
        options: [
          {
            name: 'Electric',
            key: 'electric',
            icon: '',
            controls: LeafFactory.getStoveHobControls()
          },
          {
            name: 'Gas',
            key: 'gas',
            icon: '',
            controls: LeafFactory.getStoveHobControls()
          }
        ]
      },
      {
        name: 'Hob with extractor',
        key: 'extractorHobs',
        icon: 'hob-w-extractor.png',
        type: 'hobWExt',
        options: [
          {
            name: 'Electric',
            key: 'electric',
            icon: '',
            controls: LeafFactory.getStoveHobControls()
          },
          {
            name: 'Gas',
            key: 'gas',
            icon: '',
            controls: LeafFactory.getStoveHobControls()
          }
        ]
      },
      {
        name: 'Oven',
        key: 'ovens',
        icon: '',
        type: 'oven',
        options: [
          {
            name: 'Electric',
            key: 'electric',
            icon: '',
            controls: LeafFactory.getOvenControls()
          },
          {
            name: 'Gas',
            key: 'gas',
            icon: '',
            controls: LeafFactory.getOvenControls()
          },
          {
            name: 'Combo microwave',
            key: 'microwaveCombos',
            icon: '',
            controls: LeafFactory.getOvenControls()
          }
        ]
      },
      {
        name: 'Microwave oven',
        key: 'micros',
        icon: 'microwave-oven.png',
        type: 'micro',
        options: [
          {
            name: 'Free standing',
            key: 'free_standing',
            icon: '',
            controls: LeafFactory.getMicroControls()
          },
          {
            name: 'Integrated',
            key: 'integrated',
            icon: '',
            controls: LeafFactory.getMicroControls()
          }
        ]
      },
      {
        name: 'Extractor',
        key: 'extractors',
        icon: 'extractor.png',
        type: 'fan',
        options: [
          {
            name: 'Integrated',
            key: 'integrated',
            icon: '',
            options: [
              {
                name: 'Fixed',
                key: 'fixed',
                icon: '',
                controls: LeafFactory.getExtractorIntControls()
              },
              {
                name: 'Pull-out front',
                key: 'pull_out',
                icon: '',
                controls: LeafFactory.getExtractorIntControls()
              }
            ]
          },
          {
            name: 'Wall mounted',
            key: 'wall_mounted',
            icon: '',
            controls: LeafFactory.getExtractorWallFreeControls()
          },
          {
            name: 'Free hanging',
            key: 'free_hanging',
            icon: '',
            controls: LeafFactory.getExtractorWallFreeControls()
          }
        ]
      },
      {
        name: 'Dishwasher',
        key: 'dishwashers',
        icon: 'dishwasher.png',
        type: 'dishwasher',
        options: [
          {
            name: 'Free standing',
            key: 'free_standing',
            icon: '',
            options: [
              {
                name: 'Width: 60 cm',
                key: 'width_60',
                icon: '',
                options: [
                  {
                    name: 'Standard height (820)',
                    key: 'height_standard',
                    icon: '',
                    controls: LeafFactory.getDishwasherControls()
                  },
                  {
                    name: 'XXL (865)',
                    key: 'height_xxl',
                    icon: '',
                    controls: LeafFactory.getDishwasherControls()
                  },
                  {
                    name: 'Other height',
                    key: 'other',
                    icon: '',
                    controls: LeafFactory.getDishwasherControls()
                  }
                ]
              },
              {
                name: 'Width: 45 cm',
                key: 'width_45',
                icon: '',
                options: [
                  {
                    name: 'Standard height (820)',
                    key: 'height_standard',
                    icon: '',
                    controls: LeafFactory.getDishwasherControls()
                  },
                  {
                    name: 'XXL (865)',
                    key: 'height_xxl',
                    icon: '',
                    controls: LeafFactory.getDishwasherControls()
                  },
                  {
                    name: 'Other height',
                    key: 'other',
                    icon: '',
                    controls: LeafFactory.getDishwasherControls()
                  }
                ]
              }
            ]
          },
          {
            name: 'Integrated',
            key: 'integrated',
            icon: '',
            options: [
              {
                name: 'Sliding door',
                key: 'sliding_door',
                icon: '',
                options: [
                  {
                    name: 'Width: 60 cm',
                    key: 'width_60',
                    icon: '',
                    options: [
                      {
                        name: 'Standard height (820)',
                        key: 'height_standard',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      },
                      {
                        name: 'XXL (865)',
                        key: 'height_xxl',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      },
                      {
                        name: 'Other height',
                        key: 'other',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      }
                    ]
                  },
                  {
                    name: 'Width: 45 cm',
                    key: 'width_45',
                    icon: '',
                    options: [
                      {
                        name: 'Standard height (820)',
                        key: 'height_standard',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      },
                      {
                        name: 'XXL (865)',
                        key: 'height_xxl',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      },
                      {
                        name: 'Other height',
                        key: 'other',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      }
                    ]
                  }
                ]
              },
              {
                name: 'Door-on-door',
                key: 'door_on_door',
                icon: '',
                options: [
                  {
                    name: 'Width: 60 cm',
                    key: 'width_60',
                    icon: '',
                    options: [
                      {
                        name: 'Standard height (820)',
                        key: 'height_standard',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      },
                      {
                        name: 'XXL (865)',
                        icon: '',
                        key: 'height_xxl',
                        controls: LeafFactory.getDishwasherControls()
                      },
                      {
                        name: 'Other height',
                        icon: '',
                        key: 'other',
                        controls: LeafFactory.getDishwasherControls()
                      }
                    ]
                  },
                  {
                    name: 'Width: 45 cm',
                    key: 'width_45',
                    icon: '',
                    options: [
                      {
                        name: 'Standard height (820)',
                        key: 'height_standard',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      },
                      {
                        name: 'XXL (865)',
                        key: 'height_xxl',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      },
                      {
                        name: 'Other height',
                        key: 'other',
                        icon: '',
                        controls: LeafFactory.getDishwasherControls()
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        name: 'Fridge',
        key: 'fridge',
        icon: 'fridge.png',
        type: 'refrigerator',
        options: [
          {
            name: 'Free standing',
            key: 'free_standing',
            icon: '',
            options: [
              {
                name: 'Width: 600 mm',
                key: 'width_60',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls()
              },
              {
                name: 'Width: 700 mm',
                key: 'width_70',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls()
              },
              {
                name: 'Width: 900 mm',
                key: 'width_90',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls()
              },
              {
                name: 'Width: 1000 mm',
                key: 'width_100',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls()
              },
              {
                name: 'Width: 1200 mm',
                key: 'width_120',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls()
              }
            ]
          },
          {
            name: 'Integrated',
            key: 'integrated',
            icon: '',
            options: [
              {
                name: 'Door-on-Door',
                key: 'door_on_door',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls()
              },
              {
                name: 'Sliding doors',
                key: 'sliding_door',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls()
              }
            ]
          }
        ]
      },
      {
        name: 'Wine fridge',
        key: 'wine_fridge',
        icon: 'wine-fridge.png',
        type: 'wineFridge',
        options: [
          {
            name: 'Free standing',
            key: 'free_standing',
            icon: '',
            controls: LeafFactory.getFridgeFreezerControls()
          },
          {
            name: 'Integrated',
            key: 'integrated',
            icon: '',
            options: [
              {
                name: 'Door-on-Door',
                key: 'door_on_door',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls()
              },
              {
                name: 'Sliding doors',
                key: 'sliding_door',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls()
              }
            ]
          }
        ]
      },
      {
        name: 'Freezer',
        key: 'freezers',
        icon: 'freezer.png',
        type: 'freezer',
        options: [
          {
            name: 'Free standing',
            key: 'free_standing',
            icon: '',
            controls: LeafFactory.getFridgeFreezerControls()
          },
          {
            name: 'Integrated',
            key: 'integrated',
            icon: '',
            options: [
              {
                name: 'Door-on-Door',
                key: 'door_on_door',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls()
              },
              {
                name: 'Sliding doors',
                key: 'sliding_door',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls()
              }
            ]
          }
        ]
      },
      {
        name: 'Combo fridge/freezer',
        key: 'fridge_freezers',
        icon: 'combo-fridge-freezer.png',
        type: 'fridgeFreezer',
        options: [
          {
            name: 'Free standing',
            key: 'free_standing',
            icon: '',
            controls: LeafFactory.getFridgeFreezerControls()
          },
          {
            name: 'Integrated',
            key: 'integrated',
            icon: '',
            options: [
              {
                name: 'Door-on-Door',
                key: 'door_on_door',
                icon: '',
                controls: LeafFactory.getFridgeFreezerControls()
              },
              {
                name: 'Sliding doors',
                icon: '',
                key: 'sliding_door',
                controls: LeafFactory.getFridgeFreezerControls()
              }
            ]
          }
        ]
      },
      {
        name: 'Accessories and others',
        key: 'accessories_others',
        icon: '',
        controls: LeafFactory.getOtherAccessoriesControls()
      }
    ]
  },
  {
    name: 'Fittings',
    key: 'fittings',
    icon: 'fittings.png',
    type: 'group',
    options: [
      {
        name: 'Handles',
        key: 'handles',
        icon: 'handle.png',
        type: 'handle',
        options: [
          {
            name: 'Brass',
            key: 'brass',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Chrome / Nickel plated',
            key: 'chrome_nickel',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Copper',
            key: 'copper',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Stainless',
            key: 'stainless',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Antique',
            key: 'antique',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Wood',
            key: 'wood',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Leather',
            key: 'leather',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Black',
            key: 'black',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'White',
            key: 'white',
            icon: '',
            controls: LeafFactory.getFittingControls()
          }
        ]
      },
      {
        name: 'Knobs',
        key: 'knobs',
        icon: 'knobs.png',
        type: 'knobs',
        options: [
          {
            name: 'Brass',
            key: 'brass',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Chrome / Nickel plated',
            key: 'chrome_nickel',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Copper',
            key: 'copper',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Stainless',
            key: 'stainless',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Antique',
            key: 'antique',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Wood',
            key: 'wood',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Leather',
            key: 'leather',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'Black',
            key: 'black',
            icon: '',
            controls: LeafFactory.getFittingControls()
          },
          {
            name: 'White',
            key: 'white',
            icon: '',
            controls: LeafFactory.getFittingControls()
          }
        ]
      },
      {
        name: 'Turning knobs',
        key: 'turning_knobs',
        icon: 'turning-knobs.png',
        type: 'knob',
        options: [
          {
            name: 'Knob',
            key: 'knob',
            icon: '',
            options: [
              {
                name: 'Brass',
                key: 'brass',
                icon: '',
                controls: LeafFactory.getFittingControls()
              },
              {
                name: 'Chrome / Nickel plated',
                key: 'chrome',
                icon: '',
                controls: LeafFactory.getFittingControls()
              }
            ]
          },
          {
            name: 'Hook',
            key: 'hooks',
            icon: '',
            options: [
              {
                name: 'For overlay door (e.g. Högalid)',
                key: 'overlay_door',
                icon: '',
                options: [
                  {
                    name: 'Brass',
                    key: 'brass',
                    icon: '',
                    controls: LeafFactory.getFittingControls()
                  },
                  {
                    name: 'Chrome / Nickel plated',
                    key: 'chrome',
                    icon: '',
                    controls: LeafFactory.getFittingControls()
                  }
                ]
              },
              {
                name: 'For inset door (e.g. Djupadal)',
                key: 'inset_door',
                icon: '',
                options: [
                  {
                    name: 'Brass',
                    key: 'brass',
                    icon: '',
                    controls: LeafFactory.getFittingControls()
                  },
                  {
                    name: 'Chrome / Nickel plated',
                    key: 'chrome',
                    icon: '',
                    controls: LeafFactory.getFittingControls()
                  }
                ]
              }
            ]
          },
          {
            name: 'Knob & hook',
            icon: '',
            key: 'knob_hook',
            options: [
              {
                name: 'For inset door (e.g. Djupadal)',
                key: 'inset_door',
                icon: '',
                options: [
                  {
                    name: 'Small',
                    key: 'small',
                    icon: '',
                    options: [
                      {
                        name: 'Brass',
                        key: 'brass',
                        icon: '',
                        controls: LeafFactory.getFittingControls()
                      },
                      {
                        name: 'Chrome / Nickel plated',
                        key: 'chrome',
                        icon: '',
                        controls: LeafFactory.getFittingControls()
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        name: 'Accessories and others',
        key: 'accessories_others',
        type: 'fittings',
        icon: '',
        controls: LeafFactory.getOtherAccessoriesControls()
      }
    ]
  },
  {
    name: 'Sink & Faucets',
    key: 'sink_faucet',
    icon: 'sinks-and-faucets.png',
    type: 'group',
    options: [
      {
        name: 'Sink',
        key: 'sink',
        icon: 'sink.png',
        type: 'sink',
        options: [
          {
            name: 'Inset',
            key: 'sink_inset',
            icon: '',
            options: [
              {
                name: 'Stainless',
                key: 'stainless',
                icon: '',
                options: [
                  {
                    name: 'Single',
                    key: 'single',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  },
                  {
                    name: 'Double',
                    key: 'double',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  }
                ]
              },
              {
                name: 'Porcelain',
                key: 'porcelain',
                icon: '',
                options: [
                  {
                    name: 'Single',
                    key: 'single',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  },
                  {
                    name: 'Double',
                    key: 'double',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  }
                ]
              },
              {
                name: 'Other',
                key: 'other',
                icon: '',
                options: [
                  {
                    name: 'Single',
                    key: 'single',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  },
                  {
                    name: 'Double',
                    key: 'double',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  }
                ]
              }
            ]
          },
          {
            name: 'Outside',
            key: 'sink_outside',
            icon: '',
            options: [
              {
                name: 'Stainless',
                key: 'stainless',
                icon: '',
                options: [
                  {
                    name: 'Single',
                    key: 'single',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  },
                  {
                    name: 'Double',
                    key: 'double',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  }
                ]
              },
              {
                name: 'Porcelain',
                key: 'porcelain',
                icon: '',
                options: [
                  {
                    name: 'Single',
                    key: 'single',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  },
                  {
                    name: 'Double',
                    key: 'double',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  }
                ]
              },
              {
                name: 'Other',
                key: 'other',
                icon: '',
                options: [
                  {
                    name: 'Single',
                    key: 'single',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  },
                  {
                    name: 'Double',
                    key: 'double',
                    icon: '',
                    controls: LeafFactory.getSinkControls()
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        name: 'Faucet',
        key: 'faucet',
        icon: 'faucet.png',
        type: 'faucet',
        options: [
          {
            name: 'Brass',
            key: 'brass',
            icon: '',
            controls: LeafFactory.getFaucetControls()
          },
          {
            name: 'Chrome / Nickel plated',
            key: 'chrome_nickel',
            icon: '',
            controls: LeafFactory.getFaucetControls()
          },
          {
            name: 'Stainless',
            key: 'stainless',
            icon: '',
            controls: LeafFactory.getFaucetControls()
          },
          {
            name: 'Other',
            key: 'other',
            icon: '',
            controls: LeafFactory.getFaucetControls()
          }
        ]
      },
      {
        name: 'Basket strainer',
        key: 'basket_strainer',
        icon: 'basket-strainer.png',
        type: 'basketStrainer',
        options: [
          {
            name: 'Gold',
            key: 'gold',
            icon: '',
            controls: LeafFactory.getBasketStrainerControls()
          },
          {
            name: 'Antique bronze',
            key: 'antique_bronze',
            icon: '',
            controls: LeafFactory.getBasketStrainerControls()
          },
          {
            name: 'Chrome',
            key: 'chrome',
            icon: '',
            controls: LeafFactory.getBasketStrainerControls()
          },
          {
            name: 'Brushed nickel',
            key: 'brushed_nickel',
            icon: '',
            controls: LeafFactory.getBasketStrainerControls()
          }
        ]
      },
      {
        name: 'Accessories and others',
        key: 'accessories_others',
        icon: '',
        controls: LeafFactory.getOtherAccessoriesControls()
      }
    ]
  },
  {
    name: 'Paint',
    key: 'paints',
    icon: 'paint.png',
    type: 'group',
    options: [
      {
        name: 'Linseed paint',
        key: 'linseed',
        icon: 'linseed-paint.png',
        type: 'linseedPaint',
        options: [
          {
            name: 'Kulladal standard',
            key: 'standard',
            icon: '',
            options: [
              {
                name: '1',
                key: '1',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '12',
                key: '12',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '19',
                key: '19',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '25',
                key: '25',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '31',
                key: '31',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '32',
                key: '32',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '34',
                key: '34',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '38',
                key: '38',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '48',
                key: '48',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '58',
                key: '58',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '67',
                key: '67',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '69',
                key: '69',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '86',
                key: '86',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '87',
                key: '87',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '97',
                key: '97',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '100',
                key: '100',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '101',
                key: '101',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '107',
                key: '107',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '111',
                key: '111',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '116',
                key: '116',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '148',
                key: '148',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: '151',
                key: '151',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              },
              {
                name: 'White',
                key: 'white',
                icon: '',
                controls: LeafFactory.getKdlPaintControls()
              }
            ]
          },
          {
            name: 'Other',
            key: 'other',
            icon: 'other.png',
            controls: LeafFactory.getOtherPaintControls()
          }
        ]
      },
      {
        name: 'Other',
        key: 'other',
        icon: 'other.png',
        type: 'other',
        controls: LeafFactory.getOtherPaintControls()
      }
    ]
  }
]

/**
 * This should be scoped in service but I do not have
 * the time or energy to do just that so I leave it here
 * for now.
 */
export const ApplianceTreeKeysMap = new Map<TApplianceItemKey, string>();
(() => {
  const setItem = (i: IApplianceItem) => {
    ApplianceTreeKeysMap.set(i.key, i.name)
    if (i.options) {
      i.options.forEach(j => setItem(j))
    }
  }
  applianceTree.forEach(root => setItem(root))
})()
