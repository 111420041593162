import {Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs'
import {hasch} from '../common/interface/helpers'
import {IProjectFile} from './project-file-types'

export interface IProdboardComment {
  // The ID is calculated as a number based on the real comment
  id: string

  // If this has been deleted (we still have to remember it)
  deleted: boolean

  // The comment as such.
  comment: string
}

export interface PBFileCompare {

  /**
   * The prodboard id
   */
  id: number

  /**
   * The url
   */
  url: string

  /**
   * The version as parsed from the URL
   */
  version: number

  /**
   * The customer name
   */
  customer: string

  /**
   * Prodboard provides a number property,
   * unclear what it really means
   */
  num: string
}

@Injectable({
  providedIn: 'root'
})
export class ProdboardService {

  /**
   * A tuple of two files, The first should be the NEW file and the
   * second the currently loaded file.
   */
  public static prodboardFiles$: BehaviorSubject<[IProjectFile, IProjectFile]> =
    new BehaviorSubject<[IProjectFile, IProjectFile]>([null, null])

  public static getProdboardComment(comment: string | undefined, oldComment: IProdboardComment | undefined): IProdboardComment {
    const id = hasch(comment)
    let deleted = id === '0'

    if (oldComment) {
      deleted = oldComment.deleted
      if (hasch(oldComment.comment) !== id) {
        deleted = false
      }
    }

    return {
      id,
      comment,
      deleted
    }
  }

  public static convertProdboardFileToDisplay(prodboardFile: IProjectFile, display: PBFileCompare): void {
    let customer = ''
    if (prodboardFile.customer) {
      customer = prodboardFile.customer.name
    }
    let version = -1
    if (prodboardFile.prodboardUrl) {
      version = +prodboardFile.prodboardUrl.split('-')[2]
    }
    Object.assign(display, {
      // TODO - PB V2 does not have ID and Version as in V1.
      //  In V1 they were taken from URL, but that's not possible in V2
      //  anymore. How do we do this now? No idea.
      id: prodboardFile.prodboardId,
      url: prodboardFile.prodboardUrl,
      num: prodboardFile.prodboardNumber,
      customer,
      version
    })
  }
}
