import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {I18nDirective} from './i18n/i18n.directive'
import {I18nPipe} from './i18n/i18n.pipe'


@NgModule({
  declarations: [
    I18nDirective,
    I18nPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    I18nDirective,
    I18nPipe
  ]
})
export class I18nModule {
}
