import {TLanguageCode} from '../application/i18n.provider'
import {CARDINALITY} from '../common/interface/helpers'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {ShelvesMill} from './mill-file/mill-file-types'

export const SHELVES_OPTION_KEY = 'Antal hyllplan'

export class Shelves extends CabinetOption {
  constructor(option: ShelvesMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)
    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Antal hyllplan',
        name: 'shelves',
        values: ['1', '2', '3', '4', '5'],
        selection: '',
        disabled: false
      }
    ]
    this.description = 'MISSING'
    this.title = 'optShelves'

    this.setValuesFromProdboardData(option)
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'Shelves'
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.active = +this.viewOptions[0].selection > 0
    this.setPrice()
  }

  public numberOfShelves(): number {
    return +this.viewOptions[0].selection
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    return [
      this.settingOption.getI18n(SHELVES_OPTION_KEY, useCase, lc)
      + this.viewOptions[0].selection + ' ' + CARDINALITY[lc]
    ]
  }

  private setValuesFromProdboardData(option: ShelvesMill): void {
    this.active = option.value > 0
    this.viewOptions[0].selection = option.value + ''

    if (option.value > option.max) {
      this.problems.push({
        description:
          `Antal hyllor är fel? ${option.value} (0 < > ${option.max}) [${this.cabinetIndex}/${this.name}]`,
        handled: false
      })
    }
  }

  private setPrice(): void {
    this.resetPrices()
    this.shouldHavePrice = this.active
    const count = Number(this.viewOptions[0].selection)
    this.price = this.product.exShPr.price * count
    this.labor = this.product.exShPr.labor * count
  }
}
