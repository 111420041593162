import {warningTexts} from '../service/warning-data'
import {IWarningTextReplacer, TWarningType} from '../service/warning-types'

export const warningTypes = [
  'APPROXIMATE_DELIVERY'
  , 'BRASS_PLATE'
  , 'COLOR'
  , 'COMBINED_UNIT'
  , 'COUNTRY_SELECT'
  , 'DOOR_TYPE'
  , 'FRAME_FILLER'
  , 'HINGE_RECESS'
  , 'HINGES'
  , 'LIGHTING'
  , 'OVEN_DOOR'
  , 'OVER_SIZE'
  , 'PAINT_DW'
  , 'PAINT_PROCESS'
  , 'PAINT_PROCESS_SELECT'
  , 'PAINT_SIDE'
  , 'PRICE'
  , 'RECESS'
  , 'SCRIBINGS_CORNER_CABINET'
  , 'SCRIBINGS_COVER_SIDE'
  , 'SCRIBINGS_COVER_SIDE_WALL'
  , 'SETTINGS_DIFF'
  , 'DRAWER_NARROW'
  , 'SHELVES_NARROW'
  , 'SPICE_RACK_NO_DOOR'
  , 'SOCELS_DIFF'
  , 'CABINET_TO_HIGH'
  , 'OPENING_SHOULD_BE_TALLER'
] as const
// Honestly I do not know _why_ this works, but
// in general it creates a type from the array, 'number'
// basically says use all.
export type TWarningID = typeof warningTypes[number]


export interface IWarning {
  /**
   * Will eventually be used to store these
   * on the backend.
   */
  id: string

  type: TWarningType

  /**
   * What we display in warnings
   */
  name: string

  /**
   * A list of items, potentially empty
   */
  details: IWarningDetail[]

  /**
   * An optional extra hint for the curious
   */
  hint?: string

  /**
   * Some warnings are used on other places?
   */
  waningId: TWarningID
}

export interface IWarningDetail {
  /**
   * The text to display in a list
   */
  text: string

  /**
   * The cabinet index, if any.
   */
  index?: string | number

  /**
   * The unique option name like Drawer_0, if any
   */
  option?: string

  /**
   * The form control to show in the project settings form
   */
  controlToShow?: string
}

export class Warning implements IWarning {
  /**
   * Not used by anyone ever!
   */
  public id: string

  public readonly hint: string | undefined = undefined
  public name: string

  public readonly type: TWarningType
  public readonly waningId: TWarningID

  private pDetails: IWarningDetail[] = []

  constructor(warningId: TWarningID) {
    this.waningId = warningId
    this.type = warningTexts[this.waningId].type
    this.name = warningTexts[this.waningId].text
    this.hint = warningTexts[this.waningId].hint
  }

  get details(): IWarningDetail[] {
    return this.pDetails
  }

  public replace(replacers: IWarningTextReplacer[]): void {
    replacers.forEach((replacer: IWarningTextReplacer) => {
      this.name = this.name.replace(replacer.key, replacer.value)
    })
  }

  /**
   * Adds the default detail to the warning,
   * this is the most common case, you may
   * set the hide show
   */
  public setDetail(controlToShow: string | null = null): IWarningDetail {
    const detail: IWarningDetail = {
      text: warningTexts[this.waningId].detail,
      controlToShow
    }
    this.details.push(detail)
    return detail
  }

  /**
   * Set a detail with cabinet index and option _name_
   * This kind of detail kan be opened.
   *
   * @param index - The cabinet index
   * @param option - The option _name_ as in FrameWidth_0
   */
  public setOptionsDetail(index: number, option: string): IWarningDetail {
    // Sets the name and pushes a detail to the details
    const detail = this.setDetail()
    detail.index = index
    detail.option = option
    return detail
  }

  /**
   * Add a fully customized detail at the end of
   * the list of details
   *
   * @param detail
   */
  public addDetail(detail: IWarningDetail): void {
    this.details.push(detail)
  }

  /**
   * Simple helper to add a list of details, we cannot
   * replace the internal details array
   */
  public addDetails(details: IWarningDetail[]): void {
    details.forEach((detail: IWarningDetail) => this.addDetail(detail))
  }
}
