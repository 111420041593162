import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TPaintProcessValue} from '../services/project-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {PaintProcessMill} from './mill-file/mill-file-types'
import {
  LINSEED_BASE,
  LINSEED_COMPLETE,
  LINSEED_FACTORY_COMPLETE,
  NO_PAINT,
  NO_PAINT_WAXED_INSIDE,
  ORDINARY_FACTORY_COMPLETE,
  ORDINARY_PAINT_BASE,
  ORDINARY_PAINT_COMPLETE
} from './model-types'

/**
 * All the below b/c we have fucked up in project form
 * that uses a number (1..5) for selection while this one
 * uses "strings".
 */
const selections = [
  LINSEED_COMPLETE, // 1
  LINSEED_BASE, // 2
  ORDINARY_PAINT_COMPLETE, // 3
  NO_PAINT_WAXED_INSIDE, // 4
  NO_PAINT, // 5
  ORDINARY_PAINT_BASE, // 6
  LINSEED_FACTORY_COMPLETE, // 7
  ORDINARY_FACTORY_COMPLETE // 8
]

const selectionList = [''].concat(selections)

export class PaintProcess extends CabinetOption {
  /**
   * Fultypar this since I do not have the energy
   */
  private reductionMap = new Map<string, { price: number; labor: number }>(
    [
      [LINSEED_COMPLETE, {price: 0, labor: 0}],
      [LINSEED_BASE, {price: 5, labor: 0}],
      [ORDINARY_PAINT_COMPLETE, {price: 0, labor: 0}],
      [NO_PAINT_WAXED_INSIDE, {price: 8, labor: 8}],
      [ORDINARY_PAINT_BASE, {price: 5, labor: 0}],
      [NO_PAINT, {price: 12, labor: 12}],
      [LINSEED_FACTORY_COMPLETE, {price: 0, labor: -4}],
      [ORDINARY_FACTORY_COMPLETE, {price: 0, labor: -4}]
    ]
  )

  constructor(option: PaintProcessMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)
    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Målningsprocess',
        name: 'paintProcess',
        values: selections,
        selection: '',
        disabled: false
      }
    ]
    this.description = 'Decides if the cabinet should be fully painted, or just primered, etc'
    this.title = 'optPaintProcess'

    this.setValuesFromProdboardData(option)
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'PaintProcess'
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.setPrice()
  }

  public paintProcess(): TPaintProcessValue {
    return selectionList.indexOf(this.viewOptions[0].selection) as TPaintProcessValue
  }

  /**
   * Returns the _name_ of the selection to be used in
   * the override defaults
   */
  public paintProcessSelection(): string {
    return this.viewOptions[0].selection
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    return [this.settingOption.getI18n(this.viewOptions[0].selection, useCase, lc)]
  }

  private setValuesFromProdboardData(option: PaintProcessMill): void {
    this.viewOptions[0].selection = option.value
    this.active = true
  }

  private setPrice(): void {
    this.resetPrices()
    let reductions = this.reductionMap.get(this.viewOptions[0].selection)
    if (!reductions) {
      reductions = {price: 0, labor: 0}
      this.problems.push({description: `No reduction: ${this.viewOptions[0].selection}`, handled: false})
    }
    // The discount is n % of cabinet price, but since this is a deduction we multiply with -1
    this.price = Math.trunc((this.product.pr.price * reductions.price) / 100) * -1
    this.labor = Math.trunc((this.product.pr.labor * reductions.labor) / 100) * -1
    if (reductions.price + reductions.labor !== 0) {
      this.shouldHavePrice = true
    }
  }
}
