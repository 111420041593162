import {Inject, Injectable} from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import {NEVER, Observable, of} from 'rxjs'
import {environment} from '../../../environments/environment'
import {catchError, map, switchMap, tap} from 'rxjs/operators'
import {WINDOW} from '../../application/window.provider'

export interface IFileUpload {

  /**
   * The name of the file
   */
  name: string

  /**
   * The content type, we will possibly use this
   * later.
   */
  contentType: string

  /**
   * Image data is a "Blob"?
   */
  imageData: Blob

  /**
   * The id as received from the server
   */
  id?: string
}

interface PutRequestResult {
  /**
   * A one time link to use to upload an image to S3
   */
  signedUrl: string

  /**
   * The server side generated id <uuidv4>.<ext>
   */
  id: string
}

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor(
    private httpClient: HttpClient,
    @Inject(WINDOW) private injectedWindow: Window
  ) {
  }

  /**
   * Need to rename this in the future and move it to a "file" service.
   */
  public uploadImageData(image: IFileUpload): Observable<IFileUpload> {
    const headers = {}
    headers['Content-Type'] = image.contentType
    headers['Cache-Control'] = 'max-age=4000'
    let id = ''
    const url = `${environment.productUrl}/images`
    return this.httpClient.put<PutRequestResult>(url, {contentType: image.contentType}).pipe(
      switchMap((r: PutRequestResult) => {
        id = (r.id)
        const httpOptions = {
          headers: new HttpHeaders(headers)
        }
        return this.httpClient.put<void>(r.signedUrl, image.imageData, httpOptions)
      }),
      map(() => {
        image.id = id
        return image
      })
    )
  }

  private cache = new Map<string, { signedUrl: string; timeStamp: number }>()

  /**
   * @param id
   */
  public getViewUrl(id: string): Observable<string> {
    const url = `${environment.productUrl}/images/${id}`
    const cache = this.cache.get(id)
    if (cache?.timeStamp > Date.now()) {
      return of(cache.signedUrl)
    }
    return this.httpClient.get(url).pipe(
      catchError(() => {
        // TODO: Remove the image here if 410 answer.
        return NEVER
      }),
      tap((res: any) => this.cache.set(id,
        {signedUrl: res.signedUrl, timeStamp: Date.now() + 60 * 55 * 1000})),
      switchMap((res: any) => of(res.signedUrl))
    )
  }

  public openImage(id: string): void {
    this.getViewUrl(id).subscribe({
      next: (url: string) => {
        this.injectedWindow.open(url, '_blank')
      }
    })
  }

  public deleteImage(id: string): Observable<void> {
    const url = `${environment.productUrl}/images/${id}`
    return this.httpClient.delete<void>(url)
  }
}
