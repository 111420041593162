import {Pipe, PipeTransform} from '@angular/core'

/**
 * Map that is set up from UserService. Once all users are fetched, then
 * they will be placed inside this map.
 */
export const UserEmailToNameMap: Record<string, string> = {}

@Pipe({
  name: 'users'
})
export class UsersPipe implements PipeTransform {

  /**
   * Transforms user-email into user-name using a map in UserService
   * @param email
   */
  public transform(email: string): string {
    return UserEmailToNameMap[email] ?? email
  }
}
