import {PriceTypes, TPriceType, TPriceTypes} from './project-pricing-types'

/**
 * Different type of quantity units that can exist as array.
 */
export const QuantityUnits = ['unit', 'area', 'volume'] as const
/**
 * Different type of quantity units that can exist.
 */
export type TQuantityUnit = typeof QuantityUnits[number]

export type TPriceTableRow = 'section' | 'item' | 'subsection' | 'value'

export type TPriceTableColumn = 'name' | 'quantity' | TPriceType

export class PriceTable {
  title: string
  columns: TPriceTableColumn[]
  rows: PriceTableRow[]

  constructor(
    title: string,
    rows: PriceTableRow[],
    columns: TPriceTableColumn[],
    minimalistic: boolean
  ) {
    this.title = title
    this.rows = rows
    this.columns = columns

    // If minimalistic flag is ON, all sections will be collapsed by default,
    // and all rows with no value will be filtered out.
    // Row with no value -> No sub-rows or no price of visible columns,
    // for example, if visible columns have "customer", then to be a "row with
    // value" it will need to have "customer" price.
    if (minimalistic) {
      const filterRows = (tableRows?: PriceTableRow[]): PriceTableRow[] | undefined => {
        // First, filter next deeper level of table rows, sub-rows
        tableRows?.forEach(row => {
          row.subRows = filterRows(row.subRows)
        })
        // Then, filter this level of table rows
        return tableRows
          ?.filter(row => row.subRows?.length > 0 ||
            PriceTypes.some((type: TPriceType) =>
              this.columns.includes(type) && row[type] !== 0))
      }
      this.rows = filterRows(rows)

      // Collapsed by default
      this.rows.forEach(row => {
        row.expanded = false
      })
    }
  }
}

export class PriceTableRow implements TPriceTypes {
  // Util parameters to decide on styles
  rowType: TPriceTableRow = 'value'
  expanded: boolean = false
  canExpand: boolean = false
  // Display properties (these include TPriceTypes)
  name: string = ''
  amount: number = 0
  unit: TQuantityUnit = 'unit'
  customer: number = 0
  factory: number = 0
  costs: number = 0
  // Sub-rows inside row. If there is any sub-row, all prices (customer,
  // factory and costs) will be auto-calculated with the sub of all sub-rows.
  subRows?: PriceTableRow[]

  constructor(obj: Partial<PriceTableRow>) {
    Object.assign(this, obj)

    // Set "canExpand", which are type of rows but "value".
    this.canExpand = this.rowType !== 'value'

    // "section" rows are expanded by default
    this.expanded = this.rowType === 'section'

    // When creating sub-rows, we make sure we filter those that are valid:
    //  - They are a "value" row.
    //  - They have at least one price value (customer, factory or costs)
    //  - They have some sub-rows
    this.subRows = this.subRows
      ?.filter(r =>
        r.rowType === 'value' ||
        r.customer || r.factory || r.costs ||
        r.subRows?.length > 0)

    // If sub-rows object is not undefined, even if it is an empty list,
    // Prices will be auto-calculated with the sum of all sub-row prices.
    if (this.subRows) {
      this.customer = this.subRows.reduce((acc, row) =>
        acc + row.customer, 0)
      this.factory = this.subRows.reduce((acc, row) =>
        acc + row.factory, 0)
      this.costs = this.subRows.reduce((acc, row) =>
        acc + row.costs, 0)
    }
  }
}
