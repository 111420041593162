import {Component, OnInit} from '@angular/core'
import {ActivatedRoute, ParamMap} from '@angular/router'
import {first, switchMap} from 'rxjs/operators'
import {LockService} from '../../services/lock.service'
import {OpenProjectService} from '../../services/open-project.service'
import {IProject} from '../../services/project-types'

@Component({
  selector: 'kdl-project-loader',
  template: ''
})
export class ProjectLoaderComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private openProjectService: OpenProjectService,
    private lockService: LockService
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        const id = params.get('id')
        return this.openProjectService.selectProjectById(id)
      }),
      first() // This cancels the whole chain so additional path changes will go unnoticed.
    ).subscribe({
      next: (project: IProject) => {
        this.lockService.lock(project.id).subscribe()
      }
    })
  }
}
