import {Board} from './board'

export class VGroove extends Board {

  public override labor: number = 224
  public override price: number = 5600

  constructor(input: Partial<VGroove> = {}) {
    super(input)
    Object.assign(this, input)
  }
}
