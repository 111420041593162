import {VGroove} from './v-groove'
import {FactoryExtra} from './factory-extra'

export class Other extends FactoryExtra {

  constructor(input: Partial<VGroove> = {}) {
    super(input)
    Object.assign(this, input)
  }
}
