import {BaseTag} from './base-tag'

/**
 * The missing state is also known as "hollow" project
 * When a state has moved on to next state w/o having completed
 * all the previous actions
 */
export class MissingStateTag extends BaseTag {
  constructor() {
    super()
    this.id = '1'
    /**
     * Unfinished task it is
     */
    this.type = 'uf'

    /**
     * UF is short for "unfinished task
     */
    this.label = 'UF'

    /**
     * Should be regular warning red.
     */
    this.color = '#ff5511'

    /**
     * We _should_ put this in translations
     */
    this.name = 'Unfinished task'

    /**
     * Set by the system and not something you should do
     * yourself.
     */
    this.selectable = false

    /**
     * Pretty high, but not that high.
     */
    this.sort = 5
  }
}
