import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {CarpenterJoyMill} from './mill-file/mill-file-types'

export class CarpenterJoy extends CabinetOption {
  constructor(option: CarpenterJoyMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)
    this.priority = 10

    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Vänster hörn',
        name: 'left',
        values: ['Ja', 'Nej'],
        selection: '',
        disabled: false
      },
      {
        type: ViewOptionType.select,
        title: 'Höger hörn',
        name: 'right',
        values: ['Ja', 'Nej'],
        selection: '',
        disabled: false
      }
    ]

    this.description = 'A special kind of "decoration" in the facade frame of some wall cabinets'
    this.title = 'optCarpenterJoy'
    this.setValuesFromProdboardData(option)
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'CarpenterJoy'
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.setPrice()
  }

  /**
   * Quite special case since there are two Setting Option Values with the same
   * key 'Ja'. This should not happen, but I'm a bit afraid to change it (Darío)
   */
  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    // Both, left and right, are active
    if (this.viewOptions[0].selection === 'Ja' &&
      this.viewOptions[1].selection === 'Ja') {
      return [this.settingOption.getI18n('Båda', useCase, lc)]
    }

    // Just left is active, which is equivalent to first option value
    if (this.viewOptions[0].selection === 'Ja') {
      return [this.settingOption
        .getI18nFromValue(this.settingOption.values[0], useCase, lc)]
    }

    // Just right is active, which is equivalent to second option value
    return [this.settingOption
      .getI18nFromValue(this.settingOption.values[1], useCase, lc)]
  }

  private setValuesFromProdboardData(option: CarpenterJoyMill): void {
    this.viewOptions[0].selection = 'Nej'
    this.viewOptions[1].selection = 'Nej'
    if (option.active) {
      this.viewOptions[0].selection = option.left
      this.viewOptions[1].selection = option.right
    }
  }

  /**
   * Called upon creation and after update
   */
  private setPrice(): void {
    this.active = false
    this.resetPrices()
    this.viewOptions.forEach((vo) => {
      if (vo.selection === 'Ja') {
        this.active = true
        this.price += this.product.cuBoCoPr.price
        this.labor += this.product.cuBoCoPr.labor
        this.shouldHavePrice = true
      }
    })
  }
}
