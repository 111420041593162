import {Pipe, PipeTransform} from '@angular/core'
import {TLanguageCode} from '../../application/i18n.provider'
import {TOptionSelectName} from '../../model/cabinet-option'
import {
  SettingsItemService,
  TSettingOptionUseCase
} from '../../services/settings-item.service'

@Pipe({
  name: 'settingOption'
})
/**
 * Pipe that will transform Setting Options into i18n texts.
 * They need a few parameters in order to work.
 * However, for internal use, like selectors, or things like that inside Mill,
 * you can skip a few parameters. It will transform the option into "internal"
 * use-case using "defaultLanguage", that's usually English.
 */
export class SettingOptionsPipe implements PipeTransform {
  constructor(private settingsItemService: SettingsItemService) {
  }

  transform(
    valueKey: string,
    optionName: TOptionSelectName,
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string {
    return this.settingsItemService.getSettingOption(optionName)
      .getI18n(valueKey, useCase, lc)
  }
}
