<mat-form-field [style.width.px]="width">
  <mat-label kdlI18n label="{{label}}"></mat-label>
  <input [formControl]="control"
         [matAutocomplete]="auto"
         [placeholder]="label | i18n | async"
         aria-label="Number"
         matInput
         type="text">
  <mat-hint>{{ hint }}</mat-hint>
</mat-form-field>
<mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption="true" class="no-max-height">
  @for (option of filteredOptions | async | sort; track $index) {
    <mat-option [value]="option">{{ option }}</mat-option>
  }
</mat-autocomplete>
