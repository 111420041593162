import {InitialsPipe} from '../../common/initials-pipe/initials.pipe'
import {UsersPipe} from '../../common/users-pipe/users.pipe'
import {BaseTag} from './base-tag'

export class UserTag extends BaseTag {
  /**
   * ID is expected to be the email
   */
  constructor(id: string) {
    super()
    this.id = id
    this.name = new UsersPipe().transform(id)
    this.label = new InitialsPipe().transform(this.name)
    this.color = '#000000'
    this.type = 'u'
    this.multi = true

    // Put users first (or last)
    this.sort = 0
  }
}
