import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOption,
  ViewOptionType
} from './cabinet-option'
import {SpiceRackMill} from './mill-file/mill-file-types'
import {SELECTION_NONE} from './model-types'

export class SpiceRack extends CabinetOption {
  constructor(option: SpiceRackMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)

    this.priority = 20
    this.viewOptions = []

    this.description = 'A kind of shelf that is put on the inside of the door'
    this.title = 'optSpiceRack'

    this.setValuesFromProdboardData(option)
    this.active = this.viewOptions.some(vo => vo.selection !== SELECTION_NONE)
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'SpiceRack'
  }

  public update(data: any) {
    super.update(data)
    this.setFromProperties(data)
    this.active = this.viewOptions.some(vo => vo.selection !== SELECTION_NONE)
    this.setPrice()
  }

  public hasSpiceJarRack(): boolean {
    return this.active
  }

  public getCustomCustomerListing(useCase: TSettingOptionUseCase, lc: TLanguageCode): string[] {
    return this.viewOptions
      .filter(vo => vo.selection !== SELECTION_NONE)
      .map((viewOption: ViewOption) => {
        const option =
          this.settingOption.getI18n(viewOption.selection, useCase, lc)

        // TODO: Fix this - Daniel 2023-09-17
        // Get door option value that for use-case "c" (customer) has the same
        // value for its Swedish translation as viewOption's title.
        const doorValue = this.settingOption.values
          .find(v => v.ucs.some(uc =>
            uc.type === 'c' && uc.i18n.sv === viewOption.title))
        // Then, get the correct translation for that value
        const door = this.settingOption
          .getI18nFromValue(doorValue, useCase, lc)

        return `${door}: ${option}`
      })
  }

  private setValuesFromProdboardData(option: SpiceRackMill): void {
    option.doors.forEach((door, index: number) => {
      const title: string = this.settingOption.getI18n(door.pos, 'c', 'sv')
      const viewOption: ViewOption = {
        type: ViewOptionType.select,
        title: title,
        name: `door${index}`,
        values: ['Ingen', 'Kryddhylla', 'Burkhylla', 'Combo'],
        selection: door.type,
        disabled: false
      }
      this.viewOptions.push(viewOption)
    })
  }

  private setPrice(): void {
    this.resetPrices()
    const numRacks = this.viewOptions
      .filter((vo: ViewOption) => vo.selection !== SELECTION_NONE)
      .reduce((acc: number) => acc + 1, 0)
    this.price = this.product.shIdPr.price * numRacks
    this.labor = this.product.shIdPr.labor * numRacks

    if (numRacks > 0) {
      this.shouldHavePrice = true
    }
  }
}
