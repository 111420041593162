/**
 * This enum is for communicating the internal Cognito state.
 */
export enum EAuthState {
  start, // Login started on not started, wait for username/password ish
  code, // Verification code needed
  newPassword,// Need to set a new password, for new users
  resetPassword, // When password is reset, user must log in.
  pendingUser,
  authenticated,// User is authenticated and ready to go.
  passwordUpdated, // Successful password update done
  error// Something is wrong
}

export const ADMIN_ROLE_NAME = 'admin'
export const CREW_ROLE_NAME = 'crew'
export const AGENT_ROLE_NAME = 'agent'
