import {Pipe, PipeTransform} from '@angular/core'

import {ITag} from '../types'
import {BallsTag} from '../balls-tag'


export const sortTags = <T extends ITag>(tags: Partial<T>[]) => {
  return tags.sort((a: T, b: T) => {
    if (a.sort === b.sort) {
      if (a.type === 'b' && b.type === 'b') {
        return new Date((a as unknown as BallsTag).dueDate).getTime() - new Date((b as unknown as BallsTag).dueDate).getTime()
      }
      return a.name.localeCompare(b.name)
    }
    return a.sort - b.sort
  })
}

@Pipe({
  name: 'sortTag'
})
export class SortTagPipe implements PipeTransform {

  transform(tags: Partial<ITag>[]): Partial<ITag>[] {
    return sortTags(tags)
  }

}
