import {TProjectItemType} from '../../project-viewer/model/project-node'

/**
 * This should be set when the source item is not created
 * before the image is created
 */
export const TEMP_SOURCE_ID = 'uffe_must_die'

export interface IProjectImage {
  /**
   * The ID used to fetch image from S3
   */
  id?: string

  /**
   * File name that it had originally
   */
  name: string

  /**
   * Display name that can be used in UI or Customer/Factory presentation
   */
  displayName: string

  /**
   * The image has an original scope? COMMENT, PROJECT, CABINET etc.
   */
  scope: TProjectItemType

  /**
   * View url to view the image. This is rendered on the fly
   */
  viewUrl: string

  /**
   * Title, a larger text to display _above_ the image
   */
  title?: string

  /**
   * An optional text to display over/under the image.
   */
  caption?: string

  customerTitle?: string

  factoryTitle?: string

  /**
   * This may or may not be set to some entity
   * The ID must match the id specifically to an
   * item, we do not really care. It can be set to the
   * empty string: ''
   */
  sourceId: string
}
