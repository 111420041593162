import {DefaultMap} from '../application/helpers'
import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {LightingMill} from './mill-file/mill-file-types'

export const LIGHT_NO_LIGHT = 'Ingen belysning'
export const LIGHT_SPOT_UNDERNEATH = 'Spotlights undertill'
export const LIGHT_LED_UNDERNEATH = 'LED-strips undertill'
export const LIGHT_SPOT_UNDERNEATH_STRIP_INSIDE = 'Spotlights undertill, och LED-strips inuti skåpet'
export const LIGHT_LED_UNDERNEATH_LED_INSIDE = 'LED-strips undertill och inuti skåpet'
export const LIGHT_LED_INSIDE = 'LED-strips inuti skåpet'

export class Lighting extends CabinetOption {
  /**
   * True if Spotlights underneath
   */
  public spots = false

  /**
   * True if led strips underneath
   */
  public ledUnderneath = false

  /**
   * True if led inside
   */
  public ledInside = false
  /**
   * Since we want to drink wine and not write test we create this
   *
   * Heads Up! If you change these the WarningService may break!
   */
  private values = new DefaultMap<string, number, number>(
    [
      [LIGHT_NO_LIGHT, 0],
      [LIGHT_SPOT_UNDERNEATH, 1],
      [LIGHT_LED_UNDERNEATH, 1.7],
      [LIGHT_SPOT_UNDERNEATH_STRIP_INSIDE, 3.5],
      [LIGHT_LED_UNDERNEATH_LED_INSIDE, 4.2],
      [LIGHT_LED_INSIDE, 2.5]
    ], 0
  )

  constructor(option: LightingMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)
    this.priority = 100 // Top if active
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Belysningstyp',
        name: 'lightingType',
        values: [...this.values.keys()],
        selection: LIGHT_NO_LIGHT,
        disabled: false
      }
    ]
    this.description = 'Spotlights or LED-strips can be added to the cabinets'
    this.title = 'optLightning'

    this.setValuesFromProdboardData(option)
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'Lighting'
  }

  /**
   * lightingType
   * @param data
   */
  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.setPrice()
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    return [this.settingOption.getI18n(this.viewOptions[0].selection, useCase, lc)]
  }

  private setValuesFromProdboardData(option: LightingMill): void {
    this.active = option.active
    this.priority = option.active ? 10 : 100
    this.viewOptions[0].selection = option.value
  }

  private setPrice(): void {
    const selection = this.viewOptions[0].selection
    this.spots = [LIGHT_SPOT_UNDERNEATH_STRIP_INSIDE, LIGHT_SPOT_UNDERNEATH].indexOf(selection) !== -1
    this.ledUnderneath = [LIGHT_LED_UNDERNEATH, LIGHT_LED_UNDERNEATH_LED_INSIDE].indexOf(selection) !== -1
    this.ledInside = [LIGHT_LED_INSIDE, LIGHT_LED_UNDERNEATH_LED_INSIDE, LIGHT_SPOT_UNDERNEATH_STRIP_INSIDE].indexOf(selection) !== -1
    this.active = this.viewOptions[0].selection !== LIGHT_NO_LIGHT
    this.resetPrices()
    const factorial = this.values.get(this.viewOptions[0].selection)
    this.price = this.product.liPr.price * factorial
    this.labor = this.product.liPr.labor * factorial
    this.material = this.product.liPr.material * factorial

    if (factorial > 0) {
      this.shouldHavePrice = true
    }
  }
}
