import {CustomerStateLabel} from '../../customer/customer-types'
import {BallTagOwnerMap, TBallTagOwner} from './balls-tag'
import {BaseTag} from './base-tag'
import {PHASE_BALLS_TAG_ID} from './types'

export class PhaseBallTag extends BaseTag {
  /**
   * PB is for Phase Ball
   */
  public readonly type = 'pb'

  /**
   * State (Phase) in which the tag can be active
   */
  public state: CustomerStateLabel

  /**
   * Reuse owner from BallTag.
   */
  public owner: TBallTagOwner

  /**
   * Timestamp that indicated when the tag was created
   */
  public creationTime: number

  /**
   * Reuse emojis from BallTag.
   */
  private emoji: string = '\u{1F3C0}'

  constructor(tag: Partial<PhaseBallTag>) {
    super()
    /**
     * It always uses the same ID, therefore, only one tag of this can exist.
     */
    this.id = PHASE_BALLS_TAG_ID

    /**
     * We must set the state & owner. They are the key params in this Tag type
     */
    this.state = tag.state
    this.owner = tag.owner

    /**
     * Set by the system and not something you should do
     * yourself.
     */
    this.selectable = false
    this.creationTime = tag.creationTime || Date.now()

    this.color = '#9a00aa'
    this.name = this.label = `${this.emoji} is next!`
    this.setLabels()
  }

  public getRawValue(): Partial<PhaseBallTag> {
    return {
      id: this.id,
      type: this.type,
      state: this.state,
      owner: this.owner,
      creationTime: this.creationTime
    }
  }

  private setLabels(): void {
    const emoji = BallTagOwnerMap.get(this.owner).emoji
    this.label =
      `${BallTagOwnerMap.get(this.owner).displayName} ${emoji} is next`
    this.description = `${emoji} is next`

    const now = Date.now()
    const sevenDaysInMillis = 7 * 24 * 60 * 60 * 1000

    // Tag colouring is different when owner is "customer"
    if (this.owner === 'customer') {
      // Warning red, like MissingState tags, for
      // "It is customers turn, and we have nothing in 7+ days"
      if (now > this.creationTime + sevenDaysInMillis) {
        this.color = '#ff5511'
      } else {
        // Low-opacity orange, like Ball tags with less than 3 days, for
        // "It is customers turn, but we are inside the 7 days time span"
        this.color = 'rgba(164,125,65, 0.1)'
      }
    }
  }
}
