import {DefaultMap} from '../../application/helpers'
import {
  CAD_CHECKED_BY_CUSTOMER,
  CAD_DRAWINGS_FIRST_VERSION,
  CAD_PROOFREADING_FINISHED,
  CAD_UPDATED_AFTER_CUSTOMER,
  CAD_UPDATED_AFTER_PROOFREADING,
  CONFIRMED_MESSAGE_SENT,
  CREATE_SLACK_CHANNEL_ID,
  CSC_ADD_NUMBER_TO_PROJECT,
  CSC_AGACCO_ECONOMY_SHEET,
  CSC_ANYTHING_ELSE_TO_ORDER,
  CSC_CAD_PROOFREAD_KULLADAL,
  CSC_CAD_PROOFREAD_KULLADAL_UPDATED,
  CSC_CAD_SENT_TO_CUSTOMER,
  CSC_CARPENTRY_PICKUP,
  CSC_CUSTOMER_DELIVERY_DATE,
  CSC_CUSTOMER_INFO_EMAIL,
  CSC_CUSTOMER_STENY,
  CSC_DRIVE_CREATE_FOLDERS,
  CSC_EXPORT_ORDERSHEET,
  CSC_FITTING_INSTRUCTION_PDF,
  CSC_INSERT_CARPENTRY_DELIVERY_SCHEDULE,
  CSC_INSERT_DELIVERY_SCHEDULE,
  CSC_INVOICE_2_OF_3,
  CSC_KGH_EMAIL_TO_CUSTOMER,
  CSC_LIGHTS_PACKED,
  CSC_NUMBERS_IN_NYA_EKONOMIN,
  CSC_ORDER_SPECIAL_PAINT,
  CSC_PAINT_EMAIL_TO_CUSTOMER,
  CSC_PROOFREADER_SENT,
  CSC_SEND_INVOICE_1,
  CSC_SENT_QUOTE_TO_CUSTOMER,
  CSC_TRANSPORTER_PICKUP,
  CSC_UPLOAD_PRODBOARD_FILE,
  HAS_CUSTOMER_RESPOND,
  KITCHEN_DELIVERED,
  PAINTING_FINISHED,
  REMIND_THE_CUSTOMER,
  REMIND_THE_CUSTOMER_AGAIN,
  SEND_QUOTE_TO_CUSTOMER,
  SEND_WHAT_NEXT_TO_CUSTOMER,
  WAITING_FOR_CUSTOMER_QUOTE_ACCEPT
} from '../customer-state-map'
import {ICustomerStateCondition} from '../customer-types'

type CrossStateInfo = {
  // The ID of the other state
  id: string

  // Deadline to be set in days
  deadLineDays: number
}

/**
 * Helper class to update states this is where the cross-state
 * definition resides and works statically on a passed in set of states
 */
export class CrossState {

  private static CrossStateMap = new DefaultMap<string, CrossStateInfo[]>(
    [
      // When we have prodboard file we should set send quote to 5 days
      [CSC_UPLOAD_PRODBOARD_FILE, [{id: SEND_QUOTE_TO_CUSTOMER, deadLineDays: 5}]],
      // A:  Customer contact 1 to reminder
      [SEND_QUOTE_TO_CUSTOMER, [{id: REMIND_THE_CUSTOMER, deadLineDays: 14}]],
      // A: Customer contact reminder to Customer contact reminder again.
      [REMIND_THE_CUSTOMER, [{id: REMIND_THE_CUSTOMER_AGAIN, deadLineDays: 14}]],
      [REMIND_THE_CUSTOMER_AGAIN, [{id: HAS_CUSTOMER_RESPOND, deadLineDays: 14}]],
      // A: Customer contact reminder to the customer wants to continue.
      // [REMIND_THE_CUSTOMER_AGAIN, [{id: CUSTOMER_WANTS_TO_CONTINUE, deadLineDays: 14}]],
      // C: Quote sent to customer (c-17, c-18)
      [CSC_SENT_QUOTE_TO_CUSTOMER, [{id: WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, deadLineDays: 13}]],
      // Cad Slack channel to Cad drawings 1, fist CAD condition
      [CREATE_SLACK_CHANNEL_ID, [{id: CAD_DRAWINGS_FIRST_VERSION, deadLineDays: 7}]],
      // First version of cad shall trigger action on me
      [CAD_DRAWINGS_FIRST_VERSION, [
        {id: CSC_CAD_PROOFREAD_KULLADAL, deadLineDays: 7},
        {id: CSC_PROOFREADER_SENT, deadLineDays: 7}
      ]],
      [CSC_CAD_PROOFREAD_KULLADAL, [{id: CSC_CAD_PROOFREAD_KULLADAL_UPDATED, deadLineDays: 3}]],
      // Cad Proof reading, second Cad condition
      [CAD_PROOFREADING_FINISHED, [{id: CAD_UPDATED_AFTER_PROOFREADING, deadLineDays: 7}]],
      // Third condition for cad
      [CAD_CHECKED_BY_CUSTOMER, [{id: CAD_UPDATED_AFTER_CUSTOMER, deadLineDays: 7}]],
      // Check for proofreading, not e the relation to the above task
      [CSC_PROOFREADER_SENT, [{id: CAD_PROOFREADING_FINISHED, deadLineDays: 7}]],
      // From confirmed to delivered kitchen
      // This date should actually be estimated delivery date.
      [CONFIRMED_MESSAGE_SENT, [
        {id: KITCHEN_DELIVERED, deadLineDays: 90},
        {id: CSC_KGH_EMAIL_TO_CUSTOMER, deadLineDays: 45},
      ]],
      // When the painter should have finished his/hers job after delivery
      [KITCHEN_DELIVERED, [{id: PAINTING_FINISHED, deadLineDays: 120}]],
      // A series of actions based on quote accepted to Me deadlines
      [WAITING_FOR_CUSTOMER_QUOTE_ACCEPT, [
        // A number of immediate tasks in step D
        {id: CSC_EXPORT_ORDERSHEET, deadLineDays: 3},
        {id: CSC_ADD_NUMBER_TO_PROJECT, deadLineDays: 3},
        {id: CREATE_SLACK_CHANNEL_ID, deadLineDays: 3},
        {id: CSC_DRIVE_CREATE_FOLDERS, deadLineDays: 3},
        {id: CSC_INSERT_DELIVERY_SCHEDULE, deadLineDays: 3},
        {id: CSC_INSERT_CARPENTRY_DELIVERY_SCHEDULE, deadLineDays: 3},
        {id: CSC_NUMBERS_IN_NYA_EKONOMIN, deadLineDays: 3},
        {id: CSC_SEND_INVOICE_1, deadLineDays: 3},
        {id: CSC_AGACCO_ECONOMY_SHEET, deadLineDays: 3},
        {id: SEND_WHAT_NEXT_TO_CUSTOMER, deadLineDays: 3},
        // Medium tasks in step D still
        {id: CSC_ANYTHING_ELSE_TO_ORDER, deadLineDays: 14},
        {id: CSC_ORDER_SPECIAL_PAINT, deadLineDays: 14},
        // Long-running tasks in step F
        {id: CSC_CUSTOMER_STENY, deadLineDays: 30},
        {id: CSC_LIGHTS_PACKED, deadLineDays: 30},
        {id: CSC_CUSTOMER_DELIVERY_DATE, deadLineDays: 30},
        {id: CSC_CUSTOMER_INFO_EMAIL, deadLineDays: 30},
        {id: CSC_CARPENTRY_PICKUP, deadLineDays: 30},
        {id: CSC_TRANSPORTER_PICKUP, deadLineDays: 30},
        {id: CSC_PAINT_EMAIL_TO_CUSTOMER, deadLineDays: 30},
        {id: CSC_FITTING_INSTRUCTION_PDF, deadLineDays: 30},
        {id: CSC_INVOICE_2_OF_3, deadLineDays: 60}]
      ],
      [CSC_CAD_SENT_TO_CUSTOMER, [{id: CAD_CHECKED_BY_CUSTOMER, deadLineDays: 7}]]
      //
    ],
    []
  )

  /**
   * Updates the deadline on another condition when the first
   * is checked.
   * Condition A should set a deadline on Condition B
   *
   * @param condition - This checkbox that may update another
   * @param conditions - All checkboxes
   */
  public static checkCrossState(condition: ICustomerStateCondition, conditions: Map<string, ICustomerStateCondition>): void {
    const dependantConditions = this.CrossStateMap.get(condition.id)
    dependantConditions.forEach((dependantCondition: CrossStateInfo) => {
      // If the dependent is among the existing conditions
      if (conditions.has(dependantCondition.id)) {
        const conditionToUpdate = conditions.get(dependantCondition.id)
        if (condition.completed) {
          conditionToUpdate.deadline = Date.now() + dependantCondition.deadLineDays * 60 * 60 * 24 * 1000
        } else {
          conditionToUpdate.deadline = undefined
        }
      }
    })
  }
}
