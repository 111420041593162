<div matDialogTitle>Configure "Action"</div>
<div class="content">
  <form [formGroup]="form">
    <div>
      <mat-label>Owner</mat-label>
      <mat-radio-group
          [style.width.px]="200"
          formControlName="owner" ngDefaultControl>
        <mat-radio-button *ngFor="let owner of owners | keyvalue: originalOrder"
                          [disabled]="!owner.value.selectable"
                          [value]="owner.key">{{owner.value.emoji}} {{owner.value.displayName}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <kdl-snooze
        (selection)="dueDate = $event"
        [dueDate]="data.dueDate"></kdl-snooze>
  </form>
</div>
<mat-dialog-actions>
  <button (click)="save()"
          [disabled]="form.invalid && form.controls.owner.value !== 'me'"
          color="primary"
          mat-raised-button>
    <span kdlI18n label="saveBtn"></span>
  </button>
  <button [mat-dialog-close]="null" color="accent" mat-raised-button>
    <span kdlI18n label="cancelBtn"></span>
  </button>
  <div class="filler"></div>
  <button (click)="deleteMe()" *ngIf="existing" color="warn" mat-flat-button>
    <span kdlI18n label="deleteBtn"></span>
  </button>
</mat-dialog-actions>