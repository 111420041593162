import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(values: string[]): string[] {
    return values.sort((a: string, b: string) =>
      a.localeCompare(b, undefined, {numeric: true, sensitivity: 'base'}))
  }

}
