import {TLanguageCode, TTranslationItem} from '../../application/i18n.provider'
import {TSettingOptionUseCase} from '../../services/settings-item.service'
import {ProdboardCabinet} from '../cabinet/prodboard-cabinet'
import {CabinetOption, TOptionSelectName} from '../cabinet-option'
import {Lighting} from '../lighting'
import {StoppersAndCatchers, TStoppersSelection} from './stoppers-and-catchers'
import {HandleDoor} from '../handle-door'

import {DOOR_NO_DOOR_SELECTED} from '../model-types'
import {DoorType} from '../door-type'
import {Hinges} from '../hinges'
import {Door} from '../door'
import {CenterPost} from '../center-post'

interface CountMap {
  [key: string]: number[]
}

export interface SummaryProperties {
  description: string
  cabinetNumbers: number[]
}

export class ProjectOption {
  /**
   * Tells if any cabinet have lightning
   */
  public lightning = false

  /**
   * Needed configuration to request i18n texts for CabinetOptions
   */
  private readonly useCase: TSettingOptionUseCase
  private readonly lc: TLanguageCode

  private skirtCount: CountMap = {}

  private doorCount: CountMap = {}

  private coverSideCount: CountMap = {}

  private drawerFrontCount: CountMap = {}

  private hingeCount: CountMap = {}

  private paintCount: CountMap = {}

  private shelvesCount: CountMap = {}

  private centerPostCount: CountMap = {}

  private stoppersAndCatchersCount: CountMap = {}

  private scribingsCount: CountMap = {}

  private stoppersAndCatchers: Record<TStoppersSelection, TTranslationItem> = {
    a: {sv: 'Magnet i luckan, metallstopp i stommen', en: 'Our magnets and stoppers'},
    b: {sv: 'Metallvinkel som stopp, inga magneter', en: 'No magnets - just the stoppers'},
    c: {sv: 'Inga magneter eller luckstopp', en: 'No magnets no stoppers'}
  }

  private countMap: Map<TOptionSelectName, CountMap>

  constructor(
    cabinets: ProdboardCabinet[],
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode,
  ) {
    // Set needed configuration for i18n CabinetOptions texts
    this.lc = lc
    this.useCase = useCase

    // Initialise count map
    this.countMap = new Map<TOptionSelectName, CountMap>([
      ['Skirting', this.skirtCount],
      ['DoorType', this.doorCount],
      ['CoverSide', this.coverSideCount],
      ['DrawerFront', this.drawerFrontCount],
      ['Hinges', this.hingeCount],
      ['PaintProcess', this.paintCount],
      ['Shelves', this.shelvesCount],
      ['CenterPost', this.centerPostCount],
      ['Scribings', this.scribingsCount]
    ])

    cabinets
      .map((cabinet: ProdboardCabinet) => {
        cabinet.options
          // Only take active options that we have a map for
          .filter(option => this.countMap.has(option.optionSelectName) && option.active)
          // Skip Doors that have no door.
          .filter(o => !(o.optionSelectName === 'DoorType' && o.viewOptions[0].selection === DOOR_NO_DOOR_SELECTED))
          .forEach((option: CabinetOption) => {
            this.createSummary(this.countMap.get(option.optionSelectName), option, cabinet.index)
          })
        /**
         * This is special since we only need to know IF any lightning is active
         */
        this.lightning = this.lightning || !!cabinet.getActiveOption<Lighting>('Lighting')
        return cabinet
      })
      .filter((cabinet: ProdboardCabinet) => cabinet.hasStoppers)
      .forEach((cabinet: ProdboardCabinet) => {
        this.setStoppersAndCatchers(cabinet)
      })
  }

  public getSkirting(): SummaryProperties[] {
    return this.getFromSummary(this.skirtCount)
  }

  public getDoors(): SummaryProperties[] {
    return this.getFromSummary(this.doorCount)
  }

  public getCoverSides(): SummaryProperties[] {
    return this.getFromSummary(this.coverSideCount)
  }

  public getDrawerFront(): SummaryProperties[] {
    return this.getFromSummary(this.drawerFrontCount)
  }

  public getHinges(): SummaryProperties[] {
    return this.getFromSummary(this.hingeCount)
  }

  public getPaints(): SummaryProperties[] {
    return this.getFromSummary(this.paintCount)
  }

  public getShelves(): SummaryProperties[] {
    return this.getFromSummary(this.shelvesCount)
  }

  public getCenterPost(): SummaryProperties[] {
    return this.getFromSummary(this.centerPostCount)
  }

  public getScribings(): SummaryProperties[] {
    return this.getFromSummary(this.scribingsCount)
  }

  public getCatchersAndStoppers(): SummaryProperties[] {
    return this.getFromSummary(this.stoppersAndCatchersCount)
  }

  private createSummary(prop: { [key: string]: number[] }, option: CabinetOption, cabinetNumber: number): void {
    const value = option.getTextForProjectSummary(this.useCase, this.lc)
    prop[value] = prop[value] || []
    prop[value].push(cabinetNumber)
  }

  private getFromSummary(prop: { [key: string]: number[] }): SummaryProperties[] {
    const res: any = []
    const keys = Object.keys(prop)
    keys
      .filter((s: string) => s)
      .forEach((key: string) => {
        const cabinetNumbers: number[] = prop[key]
        res.push(
          {
            description: key,
            cabinetNumbers
          }
        )
      })
    return res
  }

  private setStoppersAndCatchers(cabinet: ProdboardCabinet): void {
    // Door type is active when there are any doors or drawers
    const doorType = cabinet.getActiveOption<DoorType>('DoorType')
    const door = cabinet.getActiveOption<Door>('Door')
    // If hinges are inactive, then no point
    const hinges = cabinet.getActiveOption<Hinges>('Hinges')
    const handles: HandleDoor = cabinet.getActiveOption<HandleDoor>('HandleDoor')
    const centerPost: CenterPost = cabinet.getActiveOption<CenterPost>('CenterPost')

    const type = StoppersAndCatchers.select(doorType, door, hinges, handles, centerPost)
    if (type) {
      const key = this.stoppersAndCatchers[type][this.lc]
      this.stoppersAndCatchersCount[key] = this.stoppersAndCatchersCount[key] || []
      this.stoppersAndCatchersCount[key].push(cabinet.index)
    }
  }
}
