import {animate, state, style, transition, trigger} from '@angular/animations'
import {AsyncPipe, DecimalPipe, NgClass} from '@angular/common'
import {Component, input} from '@angular/core'
import {MatIcon} from '@angular/material/icon'
import {I18nModule} from '../../../../i18n/i18n.module'
import {
  PriceTableRow,
  TPriceTableColumn
} from '../../../../services/project-price-table-types'

@Component({
  selector: 'kdl-price-summary-row',
  standalone: true,
  imports: [
    DecimalPipe,
    MatIcon,
    AsyncPipe,
    I18nModule,
    NgClass
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
  templateUrl: './price-row.component.html',
  styleUrl: '../price-summary-dialog.component.scss'
})
export class PriceRowComponent {

  public row = input.required<PriceTableRow>()
  public visibleColumns = input.required<TPriceTableColumn[]>()
}
