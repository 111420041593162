import {TTranslationItem} from '../../application/i18n.provider'
import {TPaintProcessValue} from '../../services/project-types'
import {Pipe, PipeTransform} from '@angular/core'

export interface IFactoryPaintProcess extends TTranslationItem {
  ui: string
}
export const TranslateFactoryExtraPaintMap = new Map<TPaintProcessValue, IFactoryPaintProcess>([
  [1, {
  en: 'Linseed paint FINISHED in carpentry',
    sv: 'Linoljefärg, färdigmålad i snickeriet',
    ui: 'Linseed paint FINISHED in carpentry'}],
  [2, {
  en: 'Standard linseed paint (last coat will be done on location by our painter)',
    sv: 'Linoljefärg, komplett (två strykningar i snickeriet, en strykning efter montering)',
    ui: 'Standard linseed paint (Our painter does the last coat on location)'}],
  [3, {
  en: 'Standard linseed paint (last coat will be done on location by the customer)',
    sv: 'Linoljefärg, grundmålning i snickeriet, kunden slutmålar själv',
  ui: 'Primer, Linseed paint (The customer does the last coat on location)'}],
  [4, {en: 'Unpainted/untreated', sv: 'Helt omålat/obehandlat', ui: 'Unpainted/untreated'}],
  [5, {
  en: 'Regular paint - NOT LINSEED - FINISHED in carpentry',
    sv: 'Vanlig färg (ej linoljefärg), färdigmålad i snickeriet',
    ui: 'Regular paint - NOT LINSEED - FINISHED in carpentry'}],
  [6, {
  en: 'Regular paint - NOT LINSEED (last coat will be done on location by our painter)',
    sv: 'Vanlig färg (ej linoljefärg). Grundmålning i snickeriet, kunden slutmålar själv',
    ui: 'Primer, regular paint - NOT LINSEED (The customer does the last coat on location)'}],
])

@Pipe({
  name: 'factoryExtraPaintProcess'
})

export class FactoryExtraPaintProcessPipe implements PipeTransform {

  transform(value: TPaintProcessValue, lc: 'sv' | 'en' | 'ui'): unknown {
    return TranslateFactoryExtraPaintMap.get(value)[lc]
  }

}
