import {IProduct} from '../common/interface/product-types'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {HiddenVisibleWidthMill} from './mill-file/mill-file-types'

export class HiddenVisibleWidth extends CabinetOption {
  constructor(option: HiddenVisibleWidthMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)

    this.priority = 100
    this.viewOptions = [
      {
        type: ViewOptionType.text,
        title: 'Synlig bredd',
        name: 'visibleWidth',
        values: [],
        selection: '',
        disabled: false
      },
      {
        type: ViewOptionType.text,
        title: 'Dold bredd',
        name: 'hiddenWidth',
        values: [],
        selection: '',
        disabled: false
      }
    ]
    this.active = true

    this.description = 'Special case for corner cabinets - one part of the cabinet is "hidden", the other part is "visible"'
    this.title = 'optHiddenVisibleWidth'

    this.setValuesFromProdboardData(option)
  }

  get optionSelectName(): TOptionSelectName {
    return 'HiddenVisibleWidth'
  }

  get visibleWidth(): number {
    return +this.viewOptions[0].selection
  }

  get hiddenWidth(): number {
    return +this.viewOptions[1].selection
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
  }

  public getCustomCustomerListing(): string[] {
    return []
  }

  private setValuesFromProdboardData(option: HiddenVisibleWidthMill): void {
    this.viewOptions[0].selection = option.visible + ''
    this.viewOptions[1].selection = (option.hidden - 50) + ''
  }
}
