import {Pipe, PipeTransform} from '@angular/core'
import {Observable} from 'rxjs'
import {ImagesService} from '../../images/services/images.service'

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  constructor(private imageService: ImagesService) {
  }

  /**
   * TODO: Implement cache here!?
   * @param id
   */
  transform(id: string): Observable<string> {
    return this.imageService.getViewUrl(id)
  }
}
