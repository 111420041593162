import {Observable, of} from 'rxjs'
import {ITag, TTagTypes} from './types'

export class BaseTag implements ITag {
  color: string
  id: string
  label: string
  name: string
  type: TTagTypes
  invisible = false
  description = ''
  /**
   * Scary default ...
   */
  project = {tags: []} as any

  /**
   * Default is that you can select them.
   */
  selectable = true

  /**
   * Default sort is low. If you do not set anything
   * you will end up last-ish
   */
  sort: number = 100

  /**
   * Default you can only have one.
   */
  multi: boolean = false

  /**
   * Most tags are not hidden.
   */
  hidden = false
  /**
   * The bare minimum for tags that are binary
   */
  getRawValue(): Partial<ITag> {
    return {type: this.type, id: this.id}
  }

  configure(): Observable<ITag | null> {
    return of(null)
  }

  onTagCreate(): Observable<ITag | null> {
    return of(null)
  }

  onTagRemove(): Observable<ITag | null> {
    return of(null)
  }
}
