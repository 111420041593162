import {Component, OnDestroy, OnInit} from '@angular/core'
import {Subscription} from 'rxjs'
import {filter, tap} from 'rxjs/operators'
import {OpenProjectService} from '../../services/open-project.service'
import {IProject} from '../../services/project-types'
import {
  CUSTOMER_INTERNAL_HOME_PATH,
  FACTORY_INTERNAL_HOME_PATH
} from '../interface/routing'

@Component({
  selector: 'kdl-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit, OnDestroy {
  public path = ''

  public customerPath = '/' + CUSTOMER_INTERNAL_HOME_PATH

  public factoryPath = '/' + FACTORY_INTERNAL_HOME_PATH

  public sub$ = new Subscription()

  constructor(
    private openProjectService: OpenProjectService
  ) {
  }

  ngOnInit(): void {
    this.sub$ = this.openProjectService.project$.pipe(
      tap(() => this.path = ''),
      filter(Boolean)
    ).subscribe({
        next: (project: IProject) => {
          this.path = `/${project.id}/print`
        }
      }
    )
  }

  public ngOnDestroy(): void {
    this.sub$.unsubscribe()
  }
}
