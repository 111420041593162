import {BaseTag} from './base-tag'
import {ITag, PB_QUOTE_REQ_ID} from './types'
import {Observable, of} from 'rxjs'

export class ProdboardQuoteTag extends BaseTag {
  constructor() {
    super()
    this.id = PB_QUOTE_REQ_ID
    /**
     * Waiting for customer
     */
    this.type = 'pq'

    /**
     * WfC is short for Waiting for Customer, we did not
     * want the WC abbreviation.
     */
    this.label = 'PQ'

    /**
     * A really red one
     */
    this.color = '#8d2b23'

    /**
     * We _should_ put this in translations
     */
    this.name = 'Prodboard Quote Request'

    this.sort = 0 // Highest of all
  }

  public onTagCreate(...args: any): Observable<ITag | null> {
    args[0].isOrder = true
    return of(new ProdboardQuoteTag())
  }

  public onTagRemove(...args: any): Observable<ITag | null> {
    args[0].isOrder = false
    return of(null)
  }
}

