import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {IKulladalUser} from '@kdl/kulladal-product-backend'
import {Observable, ReplaySubject, switchMap} from 'rxjs'
import {map} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {UserEmailToNameMap} from '../common/users-pipe/users.pipe'
import {IProject} from './project-types'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public users$: Observable<IKulladalUser[]>

  private pUsers$ = new ReplaySubject<IKulladalUser[]>(1)

  constructor(private httpClient: HttpClient) {
    this.users$ = this.pUsers$.asObservable()
  }

  private sortUsers(users: IKulladalUser[]): IKulladalUser[] {
    users.forEach((user: IKulladalUser) => {
      // Setup map in Users pipe to be used later in the app
      UserEmailToNameMap[user.email] = user.name
    })
    return users.sort((a: IKulladalUser, b: IKulladalUser) => a.name.localeCompare(b.name))
  }

  public getUsers(): Observable<IKulladalUser[]> {
    const url = `${environment.productUrl}/users`
    return this.httpClient.get<IKulladalUser[]>(url).pipe(
      map((users: IKulladalUser[]) => {
        this.sortUsers(users)
        this.pUsers$.next(users)
        return users
      })
    )
  }

  public createUser(user: IKulladalUser): Observable<IKulladalUser> {
    const url = `${environment.productUrl}/users`
    return this.httpClient.put<IKulladalUser>(url, user)
  }

  public saveUser(user: IKulladalUser): Observable<IKulladalUser> {
    const url = `${environment.productUrl}/users/${user.email}`
    return this.httpClient.put<IKulladalUser>(url, user)
  }

  public deleteUser(email: string): Observable<void> {
    const url = `${environment.productUrl}/users/${email}`
    return this.httpClient.delete<void>(url)
  }

  public getUserSelf(): Observable<IKulladalUser> {
    let u: IKulladalUser
    const url = `${environment.productUrl}/users/self`
    return this.httpClient.get<IKulladalUser>(url).pipe(
      switchMap((user: IKulladalUser) => {
        u = user
        return this.getUsers()
      }),
      map(() => {
          return u
        }
      ))
  }

  public getUser(email: string): Observable<IKulladalUser> {
    const url = `${environment.productUrl}/users/${email}`
    return this.httpClient.get<IKulladalUser>(url)
  }

  public getUserSelfProjects(): Observable<IProject[]> {
    const url = `${environment.productUrl}/projects/self`
    return this.httpClient.get<IProject[]>(url)
  }
}
