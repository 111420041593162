import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {PaintSideMill} from './mill-file/mill-file-types'

export const VALUE_NONE = 'Ingen'

export class PaintSide extends CabinetOption {
  constructor(option: PaintSideMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)

    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Målad sida',
        name: 'paintedSide',
        values: [
          'Vänster',
          'Höger',
          'Båda',
          'Ingen'
        ],
        selection: 'Ingen',
        disabled: false
      }
    ]
    this.description = 'A technical thing, about painting certain cabinet sides that would otherwise be visible'
    this.title = 'optPaintSide'

    this.setValuesFromProdboardData(option)
    this.setActive()
  }

  get optionSelectName(): TOptionSelectName {
    return 'PaintSide'
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.setActive()
  }

  private setValuesFromProdboardData(option: PaintSideMill): void {
    this.viewOptions[0].selection = option.value
  }

  private setActive(): void {
    this.active = this.viewOptions[0].selection !== VALUE_NONE
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    // Nothing to be shown to use-case "customer"
    if (useCase === 'c') {
      return []
    }

    return [this.settingOption.getI18n(this.viewOptions[0].selection, useCase, lc)]
  }
}
