import {TTranslationItem} from '../../application/i18n.provider'

/**
 * To avoid putting åäö and shit in the map, convert anything
 * into something better. This is a crude, not crypto safe thing.
 *
 * It is also weak from a collision perspective but should do well here.
 *
 * @param input - any string you want a hash for.
 */
export const hasch = (input: string | undefined): string => {
  //const encoder = new TextEncoder();
  //const data = encoder.encode(input);
  //const hasha = await crypto.subtle.digest('SHA-256', data)

  if (!input) {
    return '0'
  }
  let hash = 0
  let i = 0
  const len = input.length
  while (i < len) {
    // eslint-disable-next-line no-bitwise
    hash = ((hash << 5) - hash + input.charCodeAt(i++)) << 0
  }
  return Math.abs(hash) + ''
}

/**
 * The amount of time we wait before saving
 * set the time in ms.
 */
export const AUTO_SAVE_TIMEOUT = 4 * 1000

/**
 * The amount of time we wait before sending back to
 * main view (/). Starting with 10 minutes.
 */
export const KILL_PROJECT_TIMEOUT = 60 * 10 * 1000

export const CARDINALITY: TTranslationItem = {
  sv: 'st',
  en: 'pcs'
}
