import {Cornice} from './cornice'
import {paintPrices} from './factory-extra'

export const FactorySocleStyles = ['regular', 'outside', 'non-standard']
export type TFactorySocleStyle = typeof FactorySocleStyles[number]

export class SocleBoard extends Cornice {

  public socleStyle: TFactorySocleStyle = 'regular'

  public override price = 125
  public override labor = 5
  public override height = 95

  constructor(input: Partial<SocleBoard> = {}) {
    super(input)
    Object.assign(this, input)
  }

  public override adjustPrice(): void {
    if (this.socleStyle === 'outside') {
      this.price = 150
      this.labor = 6
    }
    if (this.socleStyle === 'non-standard') {
      this.price = 200
      this.labor = 8
    }
    this.fFactor = paintPrices.get(this.paintProcess).fReduction
    this.cFactor = paintPrices.get(this.paintProcess).cReduction
  }
}
