<div [class]="'price-summary-row price-summary-row-' + row().rowType"
     [ngClass]="'columns-n-' + visibleColumns().length"
     [class.expandable]="row().canExpand && row().subRows?.length"
     (keydown.enter)="row().canExpand &&
        (row().expanded = !row().expanded)"
     (click)="row().canExpand &&
        (row().expanded = !row().expanded)"
>
  <!-- Name -->
  @if (visibleColumns().includes('name')) {
    <div class="price-summary-cell price-summary-cell-name">
      {{ row().name | i18n | async }}
    </div>
  }

  <!-- Quantity + Units -->
  @if (visibleColumns().includes('quantity')) {
    <div class="price-summary-cell">
      @if (row().amount) {
        <span>{{ row().amount | number: '1.0-2': 'fr' }} </span>
        <span kdlI18n
              [label]="'projectPricing_quantityUnit_' + row().unit">pcs</span>
      }
    </div>
  }

  <!-- Customer price -->
  @if (visibleColumns().includes('customer')) {
    <div class="price-summary-cell">
      @if (row().customer !== null) {
        {{ row().customer | number: '1.0-0': 'fr' }} SEK
      }
    </div>
  }

  <!-- Factory price -->
  @if (visibleColumns().includes('factory')) {
    <div class="price-summary-cell">
      @if (row().factory !== null) {
        {{ row().factory | number: '1.2-2': 'fr' }} €
      }
    </div>
  }

  <!-- Costs price -->
  @if (visibleColumns().includes('costs')) {
    <div class="price-summary-cell">
      @if (row().costs !== null) {
        {{ row().costs | number: '1.0-0': 'fr' }} SEK
      }
    </div>
  }

  <!-- Expandable icon -->
  <div class="price-summary-cell price-summary-cell-expand">
    @if (row().canExpand && row().subRows?.length) {
      @if (row().expanded) {
        <mat-icon class="material-icons-outlined"
                  fontIcon="keyboard_arrow_up"></mat-icon>
      } @else {
        <mat-icon class="material-icons-outlined"
                  fontIcon="keyboard_arrow_down"></mat-icon>
      }
    }
  </div>
</div>
