import {FactoryExtra, IFactoryExtra} from './factory-extra'
import {Cornice} from './cornice'
import {Door} from './door'
import {DrawerFrontNoDrawerBox} from './drawer-front-no-drawer-box'
import {DrawerFront} from './drawer-front'
import {DrawerBox} from './drawer-box'
import {SocleBoard} from './socle-board'
import {PlateRack} from './plate-rack'
import {VGroove} from './v-groove'
import {Other} from './other'
import {Board} from './board'

export class FactoryExtraFactory {

  public static getExtra(input: Partial<IFactoryExtra> = {}): FactoryExtra {
    if (input.extrasType === 'cornice') {
      return new Cornice(input as Cornice)
    }
    if (input.extrasType === 'door') {
      return new Door(input as Door)
    }
    if (input.extrasType === 'drawerFrontNoDrawerBox') {
      return new DrawerFrontNoDrawerBox(input as DrawerFrontNoDrawerBox)
    }
    if (input.extrasType === 'drawerFront') {
      return new DrawerFront(input as DrawerFront)
    }
    if (input.extrasType === 'drawerBox') {
      return new DrawerBox(input as DrawerBox)
    }
    if (input.extrasType === 'socle') {
      return new SocleBoard(input as SocleBoard)
    }
    if (input.extrasType === 'plateRack') {
      return  new PlateRack(input as PlateRack)
    }
    if (input.extrasType === 'vGroove') {
      return new VGroove(input as VGroove)
    }
    if(input.extrasType === 'other') {
      return new Other(input as Other)
    }
    if (input.extrasType === 'board') {
      return new Board(input as Board)
    }
    return new FactoryExtra({})
  }
}
