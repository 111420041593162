import {Component, Input} from '@angular/core'
import {LockService} from '../../services/lock.service'

@Component({
  selector: 'kdl-lock',
  templateUrl: './lock.component.html'
})
export class LockComponent {
  @Input() id = ''

  constructor(public lockService: LockService) {
  }
}
