import {CustomerStateMap} from '../../customer/customer-state-map'
import {BaseTag} from './base-tag'
import {PHASE_TAG_ID} from './types'
import {CustomerStateLabel} from '../../customer/customer-types'

/**
 * The phase tag is special, it represents the phase (1 - 9, or A - I)
 *
 * It is configurable in that it can change "state" but only from
 * the machine, not by the user
 */
export class PhaseTag extends BaseTag {

  /**
   * This is the important part of this tag.
   * This will be updated now and then but there
   * can only be one.
   */
  public state: CustomerStateLabel = CustomerStateMap.FIRST_STATE_LABEL

  constructor(data: Partial<PhaseTag> = {state: CustomerStateMap.FIRST_STATE_LABEL}) {
    super()
    this.id = PHASE_TAG_ID // 3

    /**
     * We must set the state
     */
    this.state = data.state
    /**
     * Ph is for Phase
     */
    this.type = 'ph'

    /**
     * The label
     */
    this.label = 'P' + this.state

    /**
     * We need something grey here
     */
    this.color = '#424242'

    /**
     * We _should_ put this in translations
     */
    this.name = 'Current Phase'

    /**
     * Set by the system and not something you should do
     * yourself.
     */
    this.selectable = false

    /**
     * This tag is not seen anywhere atm.
     */
    this.hidden = true
  }

  public getRawValue(): Partial<PhaseTag> {
    return {
      ...super.getRawValue(),
      state: this.state
    }
  }
}
