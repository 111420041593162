import {Pipe, PipeTransform} from '@angular/core'
import {TWhiteGoodsTypeKey} from '../interface/product-types'

const wgTypeMap = new Map<TWhiteGoodsTypeKey, string>([
  ['ff', 'Freezer/Fridge'],
  ['wf', 'Wine fridge'],
  ['o', 'Oven'],
  ['m', 'Microwave oven'],
  ['o+m', 'Oven + Microwave oven'],
  ['dw', 'Dishwasher'],
  ['ex', 'Extractor'],
  ['td', 'Tumble dryer'],
  ['wm', 'Washing machine']
])

@Pipe({
  name: 'whiteGoodsType'
})
export class WhiteGoodsTypePipe implements PipeTransform {

  transform(value: TWhiteGoodsTypeKey): string {
    return wgTypeMap.get(value)
  }

}
