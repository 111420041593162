import {AfterViewInit, Directive, ElementRef} from '@angular/core'

@Directive({
  selector: '[kdlForm]'
})
export class FormDirective implements AfterViewInit {
  public el: ElementRef
  public id: string

  constructor(el: ElementRef) {
    this.el = el
  }

  public ngAfterViewInit() {
    this.id = this.el.nativeElement.id
  }
}
