import {formatDate} from '@angular/common'
import {MatDialog} from '@angular/material/dialog'
import {Observable} from 'rxjs'
import {filter, map} from 'rxjs/operators'
import {DefaultMap} from '../../application/helpers'
import {BaseTag} from './base-tag'
import {
  BallsTagDialogComponent
} from './dialogs/balls/balls-tag-dialog.component'
import {ITag} from './types'

export const BallTagOwners = ['me', 'cad', 'customer', 'carpentry', 'painter', 'colleague', 'proofreader'] as const
export type TBallTagOwner = typeof BallTagOwners[number]

export type TBallTagOwnerData = {
  /**
   * What we show in lists and such.
   */
  displayName: string

  /**
   * The Emoji of choice
   */
  emoji: string

  /**
   * Selectable, if this can be set by
   * users.
   */
  selectable: boolean
}

export const BallTagOwnerMap = new DefaultMap<TBallTagOwner, TBallTagOwnerData, TBallTagOwnerData>([
  ['me', {displayName: 'Me', emoji: '\u{1F3C0}', selectable: true}],
  ['customer', {displayName: 'Customer', emoji: '\u{1F464}', selectable: true}],
  ['cad', {displayName: 'CAD', emoji: '\u{1F4D0}', selectable: false}],
  ['proofreader', {displayName: 'Proofreader', emoji: '\u{1F440}', selectable: false}],
  ['carpentry', {displayName: 'Carpentry', emoji: '\u{1F528}', selectable: false}],
  ['painter', {displayName: 'Painter', emoji: '\u{1F3A8}', selectable: false}]
], {displayName: '', emoji: '\u{1F3C0}', selectable: false})

export class BallsTag extends BaseTag {
  /**
   * We can have many balls!
   */
  public readonly id = self.crypto.randomUUID()

  /**
   * B is for Ball
   */
  public readonly type = 'b'

  /**
   * The current next date, happens to be ISO 8601 date
   */
  public dueDate: string | undefined

  /**
   * Is this me or am I waiting for someone?
   */
  public owner: TBallTagOwner

  /**
   * If this is an autogenerated tag or not. Auto tags
   * are also automatically removed based on the checklist.
   */
  public auto: string | undefined

  /**
   * With basket ball.
   * https://apps.timwhitlock.info/emoji/tables/unicode
   */
  private emoji = '\u{1F3C0}'

  constructor(tag: Partial<BallsTag>) {
    super()
    this.id = tag.id || this.id
    this.dueDate = tag.dueDate
    this.owner = tag.owner
    this.project = tag.project
    this.auto = tag.auto
    this.description = tag.description

    /**
     * Something like orange, like a basket ball?
     */
    this.color = 'rgb(164,125,65)'
    //
    this.name = this.label = `${this.emoji} Action`
    this.setLabels()

    /**
     * Default sort is highest
     */
    this.sort = 0

    /**
     * We can have several of these!
     */
    this.multi = true
  }

  public getRawValue(): Partial<BallsTag> {
    return {
      id: this.id,
      type: this.type,
      dueDate: this.dueDate,
      owner: this.owner,
      auto: this.auto,
      description: this.description
    }
  }

  /**
   * We do the same on create as on configure
   * First arg will be the Project this tag is associated to.
   * Second arg is a MatDialog to be used in configure.
   */
  public onTagCreate(...args: any): Observable<ITag | null> {
    this.project = args[0]
    return this.configure(args[1])
  }

  /**
   * Configures a BallsTag by opening its configuration dialog and returning
   * the "afterClosed()" result.
   * @param args First argument must be a MatDialog
   */
  public configure(...args: any): Observable<ITag> {
    const dialog: MatDialog = args[0]
    return dialog.open(BallsTagDialogComponent, {
      disableClose: false,
      data: this
    })
      .afterClosed()
      .pipe(
        filter(Boolean),
        map((res: BallsTag) => {
          this.dueDate = res.dueDate
          this.owner = res.owner
          this.setLabels()
          return this
        }))
  }

  private setLabels(): void {
    if (this.dueDate) {
      const days = (new Date(this.dueDate).getTime() - Date.now()) / 1000 / 60 / 60 / 24
      let opacity = this.owner === 'me' && days < 3 ? '0.9' : '0.1'
      let color = 'rgba(164,125,65' // #A47D41
      if (days < 0) {
        // A strong red color for passed date.
        color = 'rgb(141,43,35' // 8d2b23
        opacity = '1'
      }
      this.color = `${color},${opacity})`
      const emoji = BallTagOwnerMap.get(this.owner).emoji
      this.label =
        `${BallTagOwnerMap.get(this.owner).displayName} ${emoji}` +
        ` ${formatDate(this.dueDate, 'dd MMM - yy', 'sv').replace('.', '')}`
    }
  }
}
