import {Component, EventEmitter, Input, Output} from '@angular/core'
import {CustomerStateName} from '../../customer/customer-types'

@Component({
  selector: 'kdl-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  @Input({required: true}) state!: CustomerStateName

  @Output() selected: EventEmitter<CustomerStateName> = new EventEmitter<CustomerStateName>()
}
