import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core'
import {I18nService} from '../service/i18n.service'
import {Subscription} from 'rxjs'

@Directive({
  selector: '[kdlI18n]'
})
export class I18nDirective implements OnInit, OnDestroy {

  @Input() label: string

  private $sub = new Subscription()

  constructor(
    private el: ElementRef,
    private service: I18nService
  ) {
  }

  public ngOnInit(): void {
    this.$sub = this.service.getLabel(this.label).subscribe({
      next: (label: string) => {
        this.el.nativeElement.innerText = label
      }
    })
  }

  public ngOnDestroy(): void {
    this.$sub.unsubscribe()
  }
}
