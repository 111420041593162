import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {LegMill} from './mill-file/mill-file-types'

export class Legs extends CabinetOption {
  constructor(option: LegMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)

    this.priority = 6

    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Benval',
        name: 'legs',
        values: ['Inga', 'Vänster', 'Höger', 'Båda'],
        selection: '',
        disabled: false
      }
    ]
    this.active = true
    this.description = 'When the cabinet is in the end of a row, a "leg" is sometimes added'
    this.title = 'optLegs'

    this.setValuesFromProdboardData(option)
    this.active = this.viewOptions[0].selection !== 'Inga'
  }

  get optionSelectName(): TOptionSelectName {
    return 'Legs'
  }

  public valueMap(): Record<string, string | number | boolean> {
    return {
      legs: ['', 'left', 'right', 'both'][this.viewOptions[0].values.indexOf(this.viewOptions[0].selection)]
    }
  }

  public update(data: any) {
    super.update(data)
    this.resetPrices()
    this.setFromProperties(data)
    this.active = this.viewOptions[0].selection !== 'Inga'
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    return [this.settingOption.getI18n(this.viewOptions[0].selection, useCase, lc)]
  }

  private setValuesFromProdboardData(option: LegMill): void {
    this.viewOptions[0].selection = option.value
  }
}
