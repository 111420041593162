import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {CabinetOption, TOptionSelectName, ViewOptionType} from './cabinet-option'
import {FactoryData} from './factory/factory-data'
import {DoorMill} from './mill-file/mill-file-types'
import {
  DOOR_NO_DOOR_SELECTED,
  DOOR_OPTION_FALSE,
  DOOR_OPTION_TRUE,
  DOOR_STANDARD_DOOR,
  DOOR_VITRINE,
  DOOR_VITRINE_VARIANT_A,
  DOOR_VITRINE_VARIANT_B,
  DOOR_VITRINE_VARIANT_C, DOOR_VITRINE_VARIANT_D,
  DOOR_VITRINE_VARIANT_E, prodboardToLocalDoorVitrineMap, vitrineDoorTypesFactors
} from './model-types'
import {blownGlassPrice} from "../factory-extras/model/factory-extra"

export class Door extends CabinetOption {
  /**
   * Set this to notify factory if no door/glass door and NOT
   * painted inside
   */
  public paintedInsideNote = ''
  public isV2 = false

  constructor(option: DoorMill, product: IProduct, cabinetId: number, optionCount: number, quantity: number) {
    super(product, cabinetId, optionCount)
    this.quantity = quantity
    this.priority = 10
    this.isV2 = !!option.isV2
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Lucktyp',
        name: 'doorType',
        values: [DOOR_VITRINE, DOOR_STANDARD_DOOR, DOOR_NO_DOOR_SELECTED],
        selection: DOOR_STANDARD_DOOR,
        disabled: false
      },
      {
        type: ViewOptionType.binary,
        title: 'Målad insida',
        name: 'paintedInside',
        values: [DOOR_OPTION_TRUE, DOOR_OPTION_FALSE], // Ja or Nej
        selection: DOOR_OPTION_FALSE,
        disabled: false
      },
      {
        type: ViewOptionType.select,
        title: 'Vitrine variant',
        name: 'vitrineVariant',
        values: [DOOR_VITRINE_VARIANT_A, DOOR_VITRINE_VARIANT_B, DOOR_VITRINE_VARIANT_C,
          DOOR_VITRINE_VARIANT_D, DOOR_VITRINE_VARIANT_E],
        selection: DOOR_VITRINE_VARIANT_A,
        disabled: false
      },
      {
        type: ViewOptionType.binary,
        title: 'Blow glass',
        name: 'blowGlass',
        values: [DOOR_OPTION_TRUE, DOOR_OPTION_FALSE], // Ja or Nej
        selection: option.isBlownGlass === true ? DOOR_OPTION_TRUE : DOOR_OPTION_FALSE,
        disabled: false
      }
    ]
    this.active = true // Door is always active b/c if you remove the door you still have a door.

    this.groupName = `optDoorGroup`
    this.name = optionCount ? `${optionCount}. optDoor` : `optDoor`
    this.description = 'Different door settings: Glass door, or "no door", etc'
    this.title = option.title ? `${option.title}` : this.name
    this.setValuesFromProdboardData(option)
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'Door'
  }

  get hasPaintedInside(): boolean {
    return this.viewOptions[1].selection === DOOR_OPTION_TRUE
  }

  public getFactoryData(): FactoryData {
    return {
      paintedInside: this.hasPaintedInside
    }
  }

  /**
   * This is called from the outside to update the values of
   * this item based on data stored in the project. The data
   * will match the data we have on the form.
   */
  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.setPrice()
  }

  public typeOfDoor(): string {
    return this.viewOptions[0].selection
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    const answer = [this.settingOption.getI18n(this.viewOptions[0].selection, useCase, lc)]
    // Painted insides
    if (this.viewOptions[1].selection === DOOR_OPTION_TRUE) {
      answer.push(
        this.settingOption.getI18n(this.viewOptions[1].selection, useCase, lc))
    }
    return answer
  }

  /**
   * This function converts the complex structure from Prodboard to
   * out own complex structure that we use in presentation.
   */
  private setValuesFromProdboardData(option: DoorMill): void {
    this.viewOptions[0].selection = option.value
    if (!this.isV2) {
      this.viewOptions.splice(2, 2)
    } else if (option.value === DOOR_VITRINE) {
      this.viewOptions[2].selection = prodboardToLocalDoorVitrineMap.get(option.variant)
    }

    this.viewOptions[1].selection = option.value === DOOR_NO_DOOR_SELECTED
      ? option.paintedInside
      : DOOR_OPTION_FALSE
    /**
     * If no price for Glass Door, then we cannot have glass doors either.
     * Remove it from the selection list
     */
    if (!this.product.glDoPr.price) {
      this.viewOptions[0].values.splice(0, 1)
    }
  }

  private configureOptions(): void {
    this.paintedInsideNote = ''
    this.viewOptions[1].disabled = this.viewOptions[0].selection === DOOR_STANDARD_DOOR
    this.viewOptions[1].selection = this.viewOptions[1].disabled ? DOOR_OPTION_FALSE : this.viewOptions[1].selection

    if (this.viewOptions[1].selection === DOOR_OPTION_FALSE) {
      this.paintedInsideNote = 'Standard waxed insides (NOT painted)'
    }

    if (this.isV2) {
      this.configurationForV2()
    }
  }

  private configurationForV2(): void {
    const isVitrineDoor = this.viewOptions[0].selection === DOOR_VITRINE
    this.viewOptions[2].disabled = !isVitrineDoor
    this.viewOptions[3].disabled = !isVitrineDoor
  }

  /**
   * Note that we calculate prices on OUR model.
   */
  private setPrice(): void {
    this.configureOptions()
    this.resetPrices()

    if (this.typeOfDoor() === DOOR_VITRINE) {
      const coefficientByType = this.isV2 ? vitrineDoorTypesFactors.get(this.viewOptions[2].selection) : 1
      const blownGlass = this.isV2 ? blownGlassPrice.get(this.viewOptions[3].selection).price : 0
      this.price = (this.product.glDoPr.price / this.quantity) * coefficientByType + blownGlass
      this.labor = (this.product.glDoPr.labor / this.quantity) * coefficientByType
      this.shouldHavePrice = true
    }

    if (this.typeOfDoor() === DOOR_NO_DOOR_SELECTED) {
      this.price = this.product.noDoPr.price / this.quantity
      this.labor = this.product.noDoPr.labor / this.quantity
    }

    // Painted inside, this ADDS to the price so we do this last
    if (this.hasPaintedInside) {
      this.price += this.product.paInPr.price
      this.labor += this.product.paInPr.labor
      this.shouldHavePrice = true
    }
  }
}
