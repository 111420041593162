import {Pipe, PipeTransform} from '@angular/core'
import {ITag} from '../types'


@Pipe({
  name: 'hiddenTag'
})
export class HiddenTagPipe implements PipeTransform {

  transform(tags: Partial<ITag>[]): Partial<ITag>[] {
    return tags.filter(t => !t.hidden)
  }

}
