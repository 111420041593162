import {TLanguageCode} from '../application/i18n.provider'
import {CARDINALITY} from '../common/interface/helpers'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOption,
  ViewOptionType
} from './cabinet-option'
import {DrawerInsertMill} from './mill-file/mill-file-types'
import {drawerInsertsPrices} from "../factory-extras/model/factory-extra"

interface IOptionData {
  property: 'cuDiPr' | 'knBlPr' | 'waBlPr' | 'typePr'
  receiptText: string
}

export class DrawerInsert extends CabinetOption {
  constructor(option: DrawerInsertMill, product: IProduct, cabinetId: number, optionCount: number, quantity: number) {
    super(product, cabinetId, optionCount)

    this.quantity = quantity
    this.priority = 10

    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Bestickinsats',
        name: 'cutleryDiv',
        values: ['0', '1', '2', '3', '4'],
        selection: '0',
        disabled: false,
        data: {
          property: 'cuDiPr',
          receiptText: 'Pris för ##NUM bestickinsats(er)'
        } as IOptionData
      },
      {
        type: ViewOptionType.select,
        title: 'Knivblock',
        name: 'knifeBlock',
        values: ['0', '1', '2', '3', '4'],
        selection: '0',
        disabled: false,
        data: {
          property: 'knBlPr',
          receiptText: 'Pris för ##NUM knivblock'
        } as IOptionData
      },
      {
        type: ViewOptionType.select,
        title: 'Kryddinsats',
        name: 'wavy',
        values: ['0', '1', '2', '3', '4'],
        selection: '0',
        disabled: false,
        data: {
          property: 'waBlPr',
          receiptText: 'Pris för ##NUM kryddinsats(er)'
        } as IOptionData
      },
      {
        type: ViewOptionType.select,
        title: 'Typ bestickinsats',
        name: 'type',
        values: [
          'No specific drawer insert selected', '160-D2', '160-D2-W1', '160-D2x2', 'BK', '260-BK-W1', '260-D2x2-D1', '260-D3',
          '260-D3-W1', '260-D3x2', '360-BK-D2', '360-BK-D2-W1', '360-BK-D2x2', '360-D2x2-D2',
          '360-D2x2-D2-W1', '360-D3x2-D1', '360-D4', '360-D4-W1', '360-D4-W2', '360-D4x2',
          '460-BK-D2', '460-BK-D3-W1', '460-BK-D3x2', '460-D3x2-D2', '460-D3x2-D2-W1', '460-D4x2-D1',
          '460-D5', '460-D5-W1', '460-D5-W2', '460-D5x2', '560-BK-D2-D2-W1', '560-BK-D4-W1',
          '560-BK-D4x2', '560-D3x2-D3', '560-D4x2-D2-W1', '560-D5x2-D1', '560-D6', '560-D6-W1',
          '560-D6-W2', '560-D6x2', '660-BK-D4', '660-BK-D5-W1', '660-BK-D5x2', '660-D4x2-D3',
          '660-D5x2-D2-W1', '660-D6x2-D1', '660-D7', '660-D7-W1', '660-D7-W2', '660-D7-x2',
          '760-BK-D2-D3-W1', '760-BK-D3-W1-D3-W1', '760-BK-D6-W1', '760-BK-D6x2', '760-D5x2-D3',
          '760-D6x2-D2-W1', '760-D7x2-D1', '760-D8', '760-D8-W1', '760-D8-W2', '760-D8x2',
          '860-BK-D3-W1-D4-W1', '860-BK-D4-D3-W1', '860-BK-D7-W1', '860-BK-D7x2', '860-D6x2-D3',
          '860-D7x2-D2-W1', '860-D8x2-D1', '860-D9', '860-D9-W1', '860-D9-W2', '860-D9x2'
        ],
        selection: 'No specific drawer insert selected',
        disabled: false,
        data: {
          property: 'typePr',
          receiptText: 'Pris för ##NUM kryddinsats(er)'
        } as IOptionData
      }
    ]

    this.groupName = `optDrawerInsertGroup`
    this.description = 'Dividers for drawers, knife blocks etc'
    this.title = optionCount ? `${optionCount}. optDrawerInsert` : `optDrawerInsert`
    this.setValuesFromProdboardData(option)
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'DrawerInsert'
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    this.setPrice()
  }

  /**
   * For CAD, we just want the legs
   */
  public valueMap(): Record<string, string | number | boolean> {
    return {
      cutlery: Number.parseInt(this.viewOptions[0].selection, 10),
      knife: Number.parseInt(this.viewOptions[1].selection, 10),
      wavy: Number.parseInt(this.viewOptions[2].selection, 10),
      type: String(this.viewOptions[3].selection),
    }
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    // We assume, blindly, that every viewOption has a corresponding
    // Setting Option Value.
    // Careful, do not use "filter" before "map". Otherwise, viewOptions don't
    // match with translation values.
    return this.viewOptions
      .map((option: ViewOption, i: number) => {
        const getI18nText = (index: number) => {
          return this.settingOption.getI18nFromValue(this.settingOption.values[index], useCase, lc)
        }
        if (+option.selection > 0) {
          return `${getI18nText(i)}: ${option.selection} ${CARDINALITY[lc]}`
        }
        if (option.name === 'type') {
          return `${getI18nText(3)} ${this.optionCount}: ${getI18nText(4)} ${option.selection}`
        }
        return ''
      })
      .filter(Boolean)
  }

  /**
   * Filters and updates `viewOptions` to display only fields present in `option`.
   * Each item in `viewOptions` is retained if its `name` exists in `option` and is non-null.
   * For retained items, sets the `selection` property to the string representation of the corresponding value in `option`.
   */
  private setValuesFromProdboardData(option: DrawerInsertMill): void {
    this.viewOptions = this.viewOptions
      .filter(({name}) => option[name as keyof DrawerInsertMill] !== undefined)
      .map(opt => ({
        ...opt,
        selection: String(option[opt.name as keyof DrawerInsertMill])
      }))
  }

  private setPrice(): void {
    this.resetPrices()
    this.active = false

    this.viewOptions
      .filter(vo => +vo.selection > 0 || vo.name === 'type')
      .forEach(vo => {
        if (vo.name === 'type') {
          this.addViewOptionPriceV2(vo)
        } else if (+vo.selection > 0) {
          this.addViewOptionPriceV1(vo)
        }
        this.active = this.viewOptions[0].selection !== 'No specific drawer insert selected'
        this.shouldHavePrice = true
      })
  }

  private addViewOptionPriceV2(vo: ViewOption): void {
    const currentDrawerInsert = drawerInsertsPrices.get(vo.selection)
    this.price = currentDrawerInsert.price
    this.labor = currentDrawerInsert.labor
  }

  private addViewOptionPriceV1(vo: ViewOption): void {
    const count = +vo.selection
    const property = vo.data.property

    this.price += count * this.product[property].price
    this.labor += count * this.product[property].labor
    this.material += count * this.product[property].material

    this.shouldHavePrice = true
  }
}
