import {Injectable, signal, WritableSignal} from '@angular/core'
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout'

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  public smallScreen: WritableSignal<boolean> = signal(false)

  constructor(private breakpointObserver: BreakpointObserver) {

    this.breakpointObserver.observe([
      Breakpoints.XSmall
    ]).subscribe({
      next: (result: BreakpointState) => {
        this.smallScreen.set(result.matches)
      }
    })
  }


}
