import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {HandleDrawerMill} from './mill-file/mill-file-types'


export class HandleDrawer extends CabinetOption {
  constructor(option: HandleDrawerMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)
    this.priority = 100

    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Typ av handtag/knoppar',
        name: 'handleDrawer',
        values: ['Handtag_fornicklad_massing', '1843 silver', 'Handtag_massing', '1843 gold', 'Skalhandtag_massing',
          'ldhandtag_silver'],
        selection: '',
        disabled: false
      }
    ]
    this.active = true

    this.description = 'MISSING'
    this.title = 'optHandleDrawer'

    this.setValuesFromProdboardData(option)
  }

  get optionSelectName(): TOptionSelectName {
    return 'HandleDrawer'
  }

  public update(options: any) {
    super.update(options)
    this.resetPrices()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getCustomCustomerListing(useCase: TSettingOptionUseCase, lc: TLanguageCode): string[] {
    // Nothing to show in any case
    return []
  }

  private setValuesFromProdboardData(option: HandleDrawerMill) {
    this.viewOptions[0].selection = option.value
  }
}
