import {I3DCoordinates} from './mill-file-types'

export type TProdboardV1SelectionItemType =
  'frames/json'
  | 'fillers/json'
  | 'hidden width'
  | 'visible width'
  | 'handle_door'
  | 'cabinet'
  | 'legs/json'

export type TProdboardV1CabinetOptionName =
  'anpassat för fläkt'
  | 'brass tag'
  | 'combined unit'
  | 'drawer/door'
  | 'doors'
  | 'gångjärn'
  | 'handtag, låda'
  | 'hyllor'
  | 'hyllor/lådor'
  | 'hyllor på luckans insida'
  | 'hängning'
  | 'inbyggd belysning'
  | 'krönlist'
  | 'luckinfästning'
  | 'mittstolpe'
  | 'målning'
  | 'paint side because of dw'
  | 'ramens bredd (admin only)'
  | 'sida, front'
  | 'sida, vänster'
  | 'sida, höger'
  | 'släta lådfronter'
  | 'snickarglädje'
  | 'sockel'
  | 'ställbara hyllplan'
  | 'template'
  | 'typ av lucka'
  | 'täcksida, höger'
  | 'täcksida, vänster'
  | 'täcksidor'
  | 'täcksida, baksida'
  | 'utdragslådor'
  | 'utdragbar skärbräda'

export const HiddenDrawerPossibilitiesV1 = [
  'översta delen', 'andra delen', 'tredje delen', 'fjärde delen',
  'femte delen', 'sjätte delen', 'sjunde delen', 'åttonde delen'
]

export const DrawerInsertPossibilitiesV1 = [
  'lådinsats'
]

/**
 * JSON emitted by Prodboard in it's V1. It will be used from now on
 * only to transpile this model to Prodboard V2 file. 17-09-2024 Darío.
 */
export interface IProdboardV1File {
  /**
   * ID of the project inside Prodboard. In V1 it's a number.
   */
  id: number
  /**
   * Number of the project inside Prodboard. Annoying because "number" is a
   * reserved word.
   */
  ['number']: string
  /**
   * Project's URL. It usually is a URL of Ritprogram.
   */
  url: string
  /**
   * Planning of project's room and all items inside it.
   */
  plan: IProdboardV1Plan

  // We don't care too much about these properties
  subdivision?: any
  customer?: any
  assignedTo: {
    name: string
    email: string
    phone: string | null
  } | null
}

/**
 * First level of Prodboard V1 JSON.
 * {plan}.
 *
 * Project's plan, which contains the room and all items inside it.
 */
export interface IProdboardV1Plan {
  items: IProdboardV1ItemHolder[]
  room: IProdboardV1Room
}

export interface IProdboardV1Room {
  dimensions: I3DCoordinates
}

export interface IProdboardPosition {
  center: I3DCoordinates
  direction: I3DCoordinates
}

/**
 * Second level of Prodboard V1 JSON.
 * {plan > items[]}.
 *
 * From this model we basically just care about "uid" and "item", which is
 * the possible cabinet data.
 */
export interface IProdboardV1ItemHolder {
  /**
   * The new (Sep. 2022) ID received from Prodboard.
   */
  uid: string
  /**
   * Item that usually represents a cabinet (for us at least). All items that
   * are not relevant to us will be removed.
   */
  item: IProdboardV1Item
  /**
   * Position of the item inside the room.
   */
  position: IProdboardPosition
  /**
   * Room is typically not here, but if not, we take it from upper level.
   */
  room: IProdboardV1Room
}

/**
 * Third level of Prodboard V1 JSON.
 * {plan > items[] > item}.
 *
 * This is most often a cabinet, but can be a worktop or an auxiliary
 */
export interface IProdboardV1Item {
  /**
   * The index is most important. It must not change between imports.
   */
  index: number
  /**
   * We only care about 'module', but it can be 'auxiliary' or something else.
   */
  type: string
  /**
   * Item's code. Important because this code is linked to Product in our DB.
   * Also, we have a special case with code "Beams". This code is used to
   * create plan's room beams in Mill models.
   */
  code: string
  /**
   * They are called items here, but we will get CabinetOptions from here too.
   */
  items: IProdboardV1SelectionItem[]
  /**
   * Item options, which for us, they will be transformed into CabinetOptions.
   */
  options: IProdboardV1CabinetOption[]
  /**
   * Comments from Prodboard that will be able to be imported to Mill.
   */
  comments?: string
  /**
   * Dimensions of the item.
   */
  dimensions: I3DCoordinates
  /**
   * Position of the item.
   */
  position: IProdboardPosition
  /**
   * Collection of the item. Many items in Prodboard have a higher hierarchy
   * that is collection, like "Lucka Rönneholm". We just care about the name.
   */
  collection: {
    code?: string | null
    name: string
  } | null

  // Parameters we don't care about.
  prototype?: string // Usually the same as "code"
  materials?: any
  panels?: any
  // eslint-disable-next-line @typescript-eslint/naming-convention
  a_shelf?: any
  inline?: any // [],
  orientation?: string //  "right",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  end_panel?: string // "1750", Price of?
  // eslint-disable-next-line @typescript-eslint/naming-convention
  knife_block?: string // "1300", Price of?
  light?: number // 600,
  dr3?: boolean  // true,
  dr6?: boolean  // true,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  cab_type?: string//  "b",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  d_rule?: any //
  // eslint-disable-next-line @typescript-eslint/naming-convention
  h_rule?: any //
  // eslint-disable-next-line @typescript-eslint/naming-convention
  w_type15?: boolean //
  // eslint-disable-next-line @typescript-eslint/naming-convention
  w_type23?: boolean //
}

/**
 * Fourth level of Prodboard V1 JSON.
 * {plan > items[] > item > items[]}.
 *
 * Cabinet options referred to more outside qualities? Anyway, more options.
 * For example, FrameWidth, Legs, etc.
 */
export interface IProdboardV1SelectionItem {
  /**
   * Code is what we are searching for. It is the name, in Prodboard language,
   * of the cabinet option to map
   */
  code: TProdboardV1SelectionItemType
  /**
   * This is either the "code" (like PD1x01) or something not a code
   */
  modificator: string
  /**
   * Value of the cabinet option.
   * It can be an object like:
   *   {
   *    "left": 20,
   *    "right": 20,
   *    "top": 40,
   *    "bottom": 20
   *   }
   * or a string like: "0", or "none".
   */
  value: any
  items?: [] // [{"w": 780}]
  quantity?: number // 780
}

/**
 * Fourth level of Prodboard V1 JSON.
 * {plan > items[] > item > options[]}.
 *
 * Cabinet options referred to more outside qualities, like frames, fillers,
 * legs, etc.
 */
export interface IProdboardV1CabinetOption {
  /**
   * Code of cabinet option inside Prodboard. For some reason, in cabinet
   * options we are using "name" instead of "code" to identify them.
   * (I realised seconds later... Sometimes it is empty "")
   */
  code: string
  /**
   * Name of cabinet option. We only care about a range of names to create
   * specific cabinet options. All other names are considered as "unknown"
   * options, that will end up being evaluated as "are you a hidden drawer?" or
   * "are you a drawer insert?".
   */
  name: TProdboardV1CabinetOptionName
  /**
   * Collection that this cabinet option belongs to. It's basically used for
   * doors. Since all cabinets in Prodboard hang from a collection hierarchy.
   * We only care about collection's name, like "Lucka Rönneholm".
   * Curious datum, it should be the same as {@link IProdboardV1Item}
   * "collection" parameter.
   */
  collection: {
    code?: string | null
    name: string
  } | null
  /**
   * Value of the cabinet option. From here onwards the real Picasso starts.
   */
  value: IProdboardV1CabinetOptionValue

  items: any[]
}

/**
 * Fifth level of Prodboard V1 JSON.
 * {plan > items[] > item > options[] > value}.
 *
 * Value that a cabinet option can have.
 */
export interface IProdboardV1CabinetOptionValue {
  /**
   * Code that identifies this cabinet-option value inside Prodboard
   */
  code: string
  /**
   * Usually, a friendly name in Swedish.
   */
  name: string
  bottom?: string
  left?: string
  right?: string
  top?: string
  options: IProdboardV1CabinetOptionValueOption // Values can have options as well
  door?: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    no_door: {
      option: {
        name: string
      }
    }
  }
  doors_setting?: {
    door_1: {
      pos: string
      type: {
        code: string
      }
    }
    door_2: {
      pos: string
      type: {
        code: string
      }
    }
  }
  handle?: {
    model: {
      choice: {
        code: string
      }
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    dr_model: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      dr_choice: {
        code: string
      }
    }
  }

  // Parameters we don't care about?
  material?: any
  models?: any
}

/**
 * Sixth level of Prodboard V1 JSON.
 * {plan > items[] > item > options[] > value > options[]}.
 *
 * Option that a cabinet-option-value can have. Prodboard people are not very
 * good with naming...
 */
export interface IProdboardV1CabinetOptionValueOption {
  // The property names here are set by prodboard, so we have no
  // choice but to disable the linter.

  /* eslint-disable @typescript-eslint/naming-convention */
  ch_b?: any
  // The cover side options
  /* eslint-disable @typescript-eslint/naming-convention */
  sh_door?: {
    type: {
      code: string
      name: string
    }
  }
  sh_door_l?: {
    type: {
      code: string
      name: string
    }
  }
  sh_door_r?: {
    type: {
      code: string
      name: string
    }
  }
  combi_new?: { left: boolean; right: boolean }
  rec_side_left?: { size: string }
  rec_side_right?: { size: string }
  left_panel?: {
    depth: number
    offset: number
  }
  right_panel?: {
    depth: number
    offset: number
  }
  side_panels?: {
    left_side: { name: string }
    right_side: { name: string }
    back: any
  }
  frames?: {
    fr_left: string
    fr_right: string
    bottom: string
    left: string
    right: string
    top: string
  }
  door?: {
    type: string
  }
  cornice?: {
    mode: boolean
  }
  sh?: {
    qty: string
  }
  shelf?: {
    sh: string
  }
  rails_and_pegs?: {
    ch: string
  }
  h_drawer?: {
    choice_1: boolean
  }
  light?: {
    mode: boolean
    choice: {
      code: string
      name: string
    }
  }
  plinth?: {
    type: string
    left: boolean
    right: boolean
  }
  paint?: {
    ch: number
  }
  open?: {
    type: any
  }
  hinges?: {
    style: string
  }
  /* eslint-enable @typescript-eslint/naming-convention */
}

///////////////////////////////////////////////////////////////////////
// PRODBOARD V1 OPTIONS
///////////////////////////////////////////////////////////////////////

// export const itemNameMapV1: Record<string, (new () => OptionMill)> = {
//   'frames/json': FrameWidthMill,
//   'fillers/json': FillerMill,
//   'legs/json': LegMill,
//   'hidden width': HiddenVisibleWidthMill,
//   'visible width': HiddenVisibleWidthMill,
//   'handle_door': HandleDoorMill
// }
//
// export const optionNameMapV1: Record<string, new (arg: any) => any> = {
//   'anpassat för fläkt': FanAdoptionMill,
//   'brass tag': BrassPlateMill,
//   'combined unit': CombinedUnitMill,
//   'doors': DoorTypeMill,
//   'gångjärn': HingesMill,
//   'handtag, låda': HandleDrawerMill,
//   'hyllor': ShelvesMill,
//   'hyllor/lådor': ShelvesMill,
//   'hyllor på luckans insida': SpiceRackMill,
//   'hängning': HangingMill,
//   'inbyggd belysning': LightingMill,
//   'krönlist': CorniceMill,
//   'mittstolpe': CenterPostMill,
//   'målning': PaintProcessMill,
//   'släta lådfronter': DrawerFrontMill,
//   'snickarglädje': CarpenterJoyMill,
//   'sockel': SkirtingMill,
//   'ställbara hyllplan': ShelvesAdjustableMill,
//   'typ av lucka': DoorMill,
//   'luckinfästning': DoorAttachmentMill,
//   'ramens bredd (admin only)': ScribingsMill,
//   'täcksida, höger': CoverSideMill,
//   'täcksida, vänster': CoverSideMill,
//   'täcksidor': BackPanelMill,
//   'täcksida, baksida': BackPanelMill,
//   'utdragslådor': HiddenDrawerSinkMill,
//   'utdragbar skärbräda': CuttingBoardMill,
//   'drawer/door': DrawerDoorMill,
//   'paint side because of dw': PaintSideMill,
//   'sida, front': FanExtractorPanelMill,
//   'sida, vänster': FanExtractorPanelMill,
//   'sida, höger': FanExtractorPanelMill
// }