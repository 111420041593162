import {TPaintProcessValue} from '../../services/project-types'
import {TFactoryCorniceStyle} from './cornice'
import {TFactoryDoorDirection, TFactoryHingeType} from './door'
import {IUnitPrice} from '../../common/interface/product-types'
import {DefaultMap} from '../../application/helpers'
import {
  DOOR_OPTION_FALSE,
  DOOR_OPTION_TRUE,
  LUCKA_DJUPADAL,
  LUCKA_HOGALID,
  LUCKA_MALMGARDEN,
  LUCKA_MELLANHEDEN,
  LUCKA_RONNEHOLM,
  LUCKA_SORGENFRI,
  LUCKA_SUNNANA
} from '../../model/model-types'
import {TFactorySocleStyle} from './socle-board'

export interface IPaintProcessCosts {
  labor: number
  price: number
  material: number
  cReduction: number
  fReduction: number
}

export const paintPrices = new DefaultMap<TPaintProcessValue | 0, IPaintProcessCosts>([
  [1, {labor: 40, price: 27, material: 115, cReduction: 0, fReduction: 4}], // Standard linseed paint process, finalized in carpentry
  [2, {labor: 30, price: 27, material: 75, cReduction: 0, fReduction: 0}], // Standard linseed paint process
  [3, {labor: 20, price: 27, material: 115, cReduction: 5, fReduction: 0}], // Primered, linseed paint process
  [4, {labor: 0, price: 27, material: 0, cReduction: 12, fReduction: 12}], // Unpainted
  [5, {labor: 30, price: 27, material: 115, cReduction: 0, fReduction: 4}], // Regular paint - NOT LINSEED PAINT - finalized in carpentry
  [6, {labor: 20, price: 27, material: 75, cReduction: 5, fReduction: 0}], // Primer, Regular paint - NOT LINSEED PAINT
  [7, {labor: 0, price: 0, material: 0, cReduction: 0, fReduction: 0}], //  "4. Omålat på utsidorna, vaxat på insidorna" NO_PAINT_WAXED_INSIDE
  [8, {labor: 0, price: 0, material: 0, cReduction: 0, fReduction: 0}] // "8. Vanlig färg, slutmålat i snickeriet" ORDINARY_FACTORY_COMPLETE
], [0, {labor: 0, price: 0, material: 0, cReduction: 0, fReduction: 0}])

export const doorPrices = new DefaultMap<TFactoryDoorStyle, IUnitPrice>([
  [LUCKA_HOGALID, {price: 27, labor: 144}],
  [LUCKA_DJUPADAL, {price: 27, labor: 132}],
  [LUCKA_RONNEHOLM, {price: 27, labor: 115}],
  [LUCKA_SUNNANA, {price: 27, labor: 132}],
  [LUCKA_MALMGARDEN, {price: 27, labor: 144}],
  [LUCKA_SORGENFRI, {price: 27, labor: 115}],
  [LUCKA_MELLANHEDEN, {price: 27, labor: 144}]
], [])

export const blownGlassPrice = new DefaultMap<string, IUnitPrice>([
  [DOOR_OPTION_TRUE, {price: 1750, labor: 0}],
  [DOOR_OPTION_FALSE, {price: 0, labor: 0}]
], {price: 0, labor: 0, material: 0})

// TODO: This will need to be removed after adding the ability to import data from 'MASTER supplements'
export const drawerInsertsPrices = new DefaultMap<string, IUnitPrice, IUnitPrice>([
  ['160-D2', { price: 360, labor: 12, material: 0 }],
  ['160-D2-W1', { price: 380, labor: 13, material: 0 }],
  ['160-D2x2', { price: 392, labor: 13, material: 0 }],
  ['BK', { price: 1790, labor: 17, material: 0 }],
  ['260-BK-W1', { price: 1990, labor: 18, material: 0 }],
  ['260-D2x2-D1', { price: 612, labor: 20, material: 0 }],
  ['260-D3', { price: 580, labor: 19, material: 0 }],
  ['260-D3-W1', { price: 600, labor: 20, material: 0 }],
  ['260-D3x2', { price: 632, labor: 21, material: 0 }],
  ['360-BK-D2', { price: 2210, labor: 25, material: 0 }],
  ['360-BK-D2-W1', { price: 2230, labor: 26, material: 0 }],
  ['360-BK-D2x2', { price: 2282, labor: 28, material: 0 }],
  ['360-D2x2-D2', { price: 872, labor: 29, material: 0 }],
  ['360-D2x2-D2-W1', { price: 892, labor: 30, material: 0 }],
  ['360-D3x2-D1', { price: 872, labor: 29, material: 0 }],
  ['360-D4', { price: 800, labor: 27, material: 0 }],
  ['360-D4-W1', { price: 820, labor: 27, material: 0 }],
  ['360-D4-W2', { price: 840, labor: 28, material: 0 }],
  ['360-D4x2', { price: 872, labor: 29, material: 0 }],
  ['460-BK-D2', { price: 2410, labor: 32, material: 0 }],
  ['460-BK-D3-W1', { price: 2450, labor: 33, material: 0 }],
  ['460-BK-D3x2', { price: 2522, labor: 36, material: 0 }],
  ['460-D3x2-D2', { price: 1112, labor: 37, material: 0 }],
  ['460-D3x2-D2-W1', { price: 1132, labor: 38, material: 0 }],
  ['460-D4x2-D1', { price: 1112, labor: 37, material: 0 }],
  ['460-D5', { price: 1020, labor: 34, material: 0 }],
  ['460-D5-W1', { price: 1040, labor: 35, material: 0 }],
  ['460-D5-W2', { price: 1060, labor: 35, material: 0 }],
  ['460-D5x2', { price: 1112, labor: 37, material: 0 }],
  ['560-BK-D2-D2-W1', { price: 2670, labor: 41, material: 0 }],
  ['560-BK-D4-W1', { price: 2670, labor: 41, material: 0 }],
  ['560-BK-D4x2', { price: 2802, labor: 45, material: 0 }],
  ['560-D3x2-D3', { price: 1352, labor: 45, material: 0 }],
  ['560-D4x2-D2-W1', { price: 1372, labor: 46, material: 0 }],
  ['560-D5x2-D1', { price: 1352, labor: 45, material: 0 }],
  ['560-D6', { price: 1240, labor: 41, material: 0 }],
  ['560-D6-W1', { price: 1260, labor: 42, material: 0 }],
  ['560-D6-W2', { price: 1280, labor: 43, material: 0 }],
  ['560-D6x2', { price: 1352, labor: 45, material: 0 }],
  ['660-BK-D4', { price: 2850, labor: 47, material: 0 }],
  ['660-BK-D5-W1', { price: 2890, labor: 48, material: 0 }],
  ['660-BK-D5x2', { price: 3002, labor: 52, material: 0 }],
  ['660-D4x2-D3', { price: 1592, labor: 53, material: 0 }],
  ['660-D5x2-D2-W1', { price: 1612, labor: 54, material: 0 }],
  ['660-D6x2-D1', { price: 1592, labor: 53, material: 0 }],
  ['660-D7', { price: 1460, labor: 49, material: 0 }],
  ['660-D7-W1', { price: 1480, labor: 49, material: 0 }],
  ['660-D7-W2', { price: 1500, labor: 50, material: 0 }],
  ['660-D7-x2', { price: 1592, labor: 53, material: 0 }],
  ['760-BK-D2-D3-W1', { price: 3090, labor: 55, material: 0 }],
  ['760-BK-D3-W1-D3-W1', { price: 3110, labor: 55, material: 0 }],
  ['760-BK-D6-W1', { price: 3110, labor: 55, material: 0 }],
  ['760-BK-D6x2', { price: 3242, labor: 60, material: 0 }],
  ['760-D5x2-D3', { price: 1832, labor: 61, material: 0 }],
  ['760-D6x2-D2-W1', { price: 1852, labor: 62, material: 0 }],
  ['760-D7x2-D1', { price: 1832, labor: 61, material: 0 }],
  ['760-D8', { price: 1680, labor: 56, material: 0 }],
  ['760-D8-W1', { price: 1700, labor: 57, material: 0 }],
  ['760-D8-W2', { price: 1720, labor: 57, material: 0 }],
  ['760-D8x2', { price: 1832, labor: 61, material: 0 }],
  ['860-BK-D3-W1-D4-W1', { price: 3330, labor: 63, material: 0 }],
  ['860-BK-D4-D3-W1', { price: 3330, labor: 63, material: 0 }],
  ['860-BK-D7-W1', { price: 3330, labor: 63, material: 0 }],
  ['860-BK-D7x2', { price: 3482, labor: 68, material: 0 }],
  ['860-D6x2-D3', { price: 2072, labor: 69, material: 0 }],
  ['860-D7x2-D2-W1', { price: 2092, labor: 70, material: 0 }],
  ['860-D8x2-D1', { price: 2072, labor: 69, material: 0 }],
  ['860-D9', { price: 1900, labor: 63, material: 0 }],
  ['860-D9-W1', { price: 1920, labor: 64, material: 0 }],
  ['860-D9-W2', { price: 1940, labor: 65, material: 0 }],
  ['860-D9x2', { price: 2072, labor: 69, material: 0 }]
], { price: 0, labor: 0, material: 0 })


export const frontTypeCost: DefaultMap<TFactoryFrontStyle, number> = new DefaultMap<TFactoryFrontStyle, number>([
  ['Släta lådfronter', 0],
  ['Standard', 13]
], ['Släta lådfronter', 0])

export const RequiredControls = new Map<TFactoryExtraType, string[]>([
  ['door', ['doorStyle', 'height', 'width', 'paintProcess']],
  ['drawerFront', ['height', 'width', 'drawerBoxDepth', 'paintProcess', 'doorStyle']],
  ['drawerFrontNoDrawerBox', ['height', 'width', 'paintProcess', 'doorStyle']],
  ['drawerBox', ['drawerBoxWidth', 'drawerBoxHeight', 'drawerBoxDepth']],
  ['cornice', ['paintProcess']],
  ['socle', ['paintProcess']],
  ['plateRack', ['paintProcess']],
  ['vGroove', ['paintProcess', 'height', 'width']],
  ['other', ['paintProcess', 'width', 'height', 'depth', 'image']],
  ['board', ['paintProcess', 'height', 'width', 'depth']],
])

export const FactoryExtraTypes = ['cornice', 'drawerFront', 'drawerFrontNoDrawerBox', 'drawerBox', 'door', 'socle', 'plateRack', 'vGroove', 'other', 'board'] as const
export type TFactoryExtraType = typeof FactoryExtraTypes[number]

export const FactoryExtraUnits = ['mm', 'm', 'pcs', 'm2'] as const
export type TFactoryExtraUnit = typeof FactoryExtraUnits[number]

export const FactoryFrontStyle = ['Standard', 'Släta lådfronter'] as const
export type TFactoryFrontStyle = typeof FactoryFrontStyle[number]

export const FactoryDoorStyles = [LUCKA_HOGALID, LUCKA_DJUPADAL, LUCKA_RONNEHOLM, LUCKA_SUNNANA, LUCKA_MALMGARDEN, LUCKA_SORGENFRI, LUCKA_MELLANHEDEN] as const
export type TFactoryDoorStyle = typeof FactoryDoorStyles[number]

export const CUSTOMER_PRICE_FACTOR = 27
export const CUSTOMER_MATERIAL_FACTOR = 2.2

export const FactoryExtraWoodTypes = ['pine', 'ash', 'oak'] as const
export type TFactoryExtraWoodTypes = typeof FactoryExtraWoodTypes[number]

export interface IFactoryExtra {
  /**
   * A unique id for this _INSTANCE_ of extra
   */
  id: string

  /**
   * Future use if we put these in DB, should always be 'FE'
   */
  type: string

  /**
   * In case we need it.
   */
  version: number

  /**
   * "type" is mandatory ... what is default? We reserve
   * the word "type" for the database use and we call this
   * extrasType
   */
  extrasType: TFactoryExtraType

  /**
   * Price is price to customer if any. Should be 0 if none
   * and always in SEK (kr)
   */
  price: number

  /**
   * Labor is price to factory, we use "labor" for legacy reasons.
   * Price in € and always 0 or more
   */
  labor: number

  /**
   * Quantity, always a minimum of 1
   */
  quantity: number

  /**
   * Paint process must always have a value?
   * If not we set it to 0. Most factory
   * extras have it?
   */
  paintProcess: TPaintProcessValue | 0

  /**
   * We measure in meters, sq. m or pcs or what ever.
   * We use it for display only.
   */
  unit: TFactoryExtraUnit

  /**
   * An image of a drawing. Used in socle and cornice
   */
  image: string

}

/**
 * This is a fake super type to be able to
 * pass a generic specialized Extra!! (wow!)
 */
export type TFactoryAll = Partial<FactoryExtra>

export class FactoryExtra implements IFactoryExtra {

  /**
   * Generate an UUID.
   */
  public id = self.crypto.randomUUID()

  /**
   * Must always be a string
   */
  public type = 'FE'

  /**
   * Basically future use
   */
  public version: number = 0

  public height: number = null

  public width: number = null

  public depth: number = null

  /**
   * Let us make cornice the default. I do not
   * want this to be "nullable". Potentially we can
   * have an undefined type or something.
   */
  public extrasType: TFactoryExtraType = 'cornice'

  public paintProcess: TPaintProcessValue = null

  public color: string = ''

  public descriptionForFactory: string = ''

  public descriptionForCustomer: string = ''

  public hingeType: TFactoryHingeType = null

  public doorStyle: TFactoryDoorStyle = null

  public doorDirection: TFactoryDoorDirection = null

  public midPanelCount = 0

  /**
   * Drawer front
   */
  public drawerFrontType: TFactoryFrontStyle = null
  public waxedInside: boolean = null
  public drawerRunners: boolean = null
  public cutoutHandle: boolean = null
  public drawerBoxHeight: number = null
  public drawerBoxDepth: number = null
  public drawerBoxWidth: number = null

  /**
   * Cornice
   */
  public corniceStyle: TFactoryCorniceStyle

  /**
   * Socle
   */
  public socleStyle: TFactorySocleStyle

  public image: string = null
  /**
   * The below has to be set by the subclasses as default
   * values.
   */
  public price: number = 0
  public labor: number = 0
  public material: number = 0
  public quantity: number = 1
  public unit: TFactoryExtraUnit = 'pcs'

  /**
   * Plate rack
   */
  public plateRackDimension = null

  /**
   * Board
   */
  public woodType: TFactoryExtraWoodTypes = null

  /**
   * Override price data finally and forever.
   */
  public setPrice: number | null = null
  public setLabor: number | null = null

  constructor(input: Partial<IFactoryExtra> = {}) {
    Object.assign(this, input)
  }

  get totalFactoryPrice(): number {
    if (this.setLabor !== null) {
      return this.setLabor
    }
    return this.labor * this.quantity
  }

  get totalCustomerPrice(): number {
    if (this.setPrice !== null) {
      return this.setPrice
    }
    return this.price * this.quantity
  }

  get totalMaterialPrice(): number {
    return this.material * this.quantity
  }

  get totalVolume(): number | null {
    return null
  }

  get totalHeight(): number {
    return 0
  }
}
