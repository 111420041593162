import {Control} from './appliance-map'

export class LeafFactory {

  /**
   * For stove, hob & hob with extractor
   */
  public static getStoveHobControls(): Control[] {
    return [
      new Control('artNr', false),
      new Control('brand'),
      new Control('description', false),
      new Control('image'),
      new Control('name'),
      new Control('price'),
      new Control('supplier'),
      new Control('url'),
      new Control('width')
    ]
  }

  /**
   * For oven
   */
  public static getOvenControls(): Control[] {
    return [
      new Control('artNr', false),
      new Control('brand'),
      new Control('description', false),
      new Control('height'),
      new Control('image'),
      new Control('name'),
      new Control('price'),
      new Control('supplier'),
      new Control('url'),
      new Control('width')
    ]
  }

  /**
   * For microwave oven
   */
  public static getMicroControls(): Control[] {
    return [
      new Control('brand'),
      new Control('name'),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('width'),
      new Control('height'),
      new Control('depth'),
      new Control('url'),
      new Control('image')
    ]
  }

  /**
   * For integrated extractor
   */
  public static getExtractorIntControls(): Control[] {
    return [
      new Control('brand'),
      new Control('name'),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('width'),
      new Control('depth'),
      new Control('url'),
      new Control('image')
    ]

  }

  /**
   * For wall and free hanging extractor
   */
  public static getExtractorWallFreeControls(): Control[] {
    return [
      new Control('brand'),
      new Control('name'),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('width'),
      new Control('url'),
      new Control('image')
    ]
  }

  /**
   * For dishwasher
   */
  public static getDishwasherControls(): Control[] {
    return [
      new Control('brand'),
      new Control('name'),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('url'),
      new Control('image')
    ]
  }

  /**
   * For fridges and freezers
   */
  public static getFridgeFreezerControls(): Control[] {
    return [
      new Control('brand'),
      new Control('name'),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('width'),
      new Control('height'),
      new Control('depth'),
      new Control('url'),
      new Control('image')
    ]
  }

  /**
   * For fittings
   */
  public static getFittingControls(): Control[] {
    return [
      new Control('brand'),
      new Control('name'),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('url'),
      new Control('size'),
      new Control('image')
    ]
  }

  /**
   * For sinks
   */
  public static getSinkControls(): Control[] {
    return [
      new Control('brand'),
      new Control('name'),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('width'),
      new Control('height'),
      new Control('depth'),
      new Control('url'),
      new Control('image')
    ]
  }

  /**
   * For faucet
   */
  public static getFaucetControls(): Control[] {
    return [
      new Control('brand'),
      new Control('name'),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('height'),
      new Control('dishwasherValve'),
      new Control('url'),
      new Control('image')
    ]
  }

  /**
   * For basketStrainer
   */
  public static getBasketStrainerControls(): Control[] {
    return [
      new Control('brand'),
      new Control('name'),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('url'),
      new Control('image')
    ]
  }

  /**
   * For KDL paint
   */
  public static getKdlPaintControls(): Control[] {
    return [
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price')
    ]
  }

  /**
   * For other paint
   */
  public static getOtherPaintControls(): Control[] {
    return [
      new Control('brand', false),
      new Control('supplier'),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price'),
      new Control('url', false)
    ]
  }

  public static getOtherAccessoriesControls(): Control[] {
    return [
      new Control('brand', false),
      new Control('name', false),
      new Control('supplier', false),
      new Control('artNr', false),
      new Control('description', false),
      new Control('price', false),
      new Control('width', false),
      new Control('height', false),
      new Control('depth', false),
      new Control('size', false),
      new Control('url', false),
      new Control('image', false)
    ]
  }
}
