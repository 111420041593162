<div class="container">
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <div [matBadgeHidden]="currentUsers.length === 0" [matBadge]="currentUsers.length" matBadgeColor="primary"
             [matBadgeOverlap]="false" matBadgePosition="after" matBadgeSize="small">
          <span kdlI18n label="users"></span>
        </div>
      </ng-template>
      <div class="header" kdlI18n label="currentUsers"></div>
      <mat-chip-listbox>
        <mat-chip-row (removed)="remove(existing)"
                      *ngFor="let existing of currentUsers | sortTag"
                      [style.background-color]="existing.color">
          {{existing.name}}
          <button matChipRemove>
            <mat-icon class="material-icons-outlined">close</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-listbox>
      <div class="header" kdlI18n label="availableUsers"></div>
      <mat-chip-listbox>
        <mat-chip-row (click)="add(available)" *ngFor="let available of availableUsers | sortTag">
          {{available.name}}
        </mat-chip-row>
      </mat-chip-listbox>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <div [matBadgeHidden]="currentTags.length === 0" [matBadge]="currentTags.length" matBadgeColor="accent"
             [matBadgeOverlap]="false" matBadgePosition="after" matBadgeSize="small">
          <span [style.margin-right.px]="5" kdlI18n label="tags"></span>
        </div>

      </ng-template>
      <div class="header" kdlI18n label="currentTags"></div>
      <mat-chip-listbox>
        <mat-chip-row (click)="editTag(existing)"
                      (removed)="removeTag(existing)"
                      *ngFor="let existing of currentTags"
                      [style.background-color]="existing.color">
          {{existing.label}}
          <button *ngIf="existing.selectable" matChipRemove>
            <mat-icon class="material-icons-outlined">close</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-listbox>


      <div class="header" kdlI18n label="availableTags"></div>
      <mat-chip-listbox>
        <mat-chip-row
            (click)="addTag(available)" *ngFor="let available of availableTags">
          {{available.name}}
        </mat-chip-row>
      </mat-chip-listbox>
    </mat-tab>
  </mat-tab-group>

</div>
<mat-dialog-actions>
  <button color="primary" mat-raised-button matDialogClose><span kdlI18n label="closeBtn"></span></button>
</mat-dialog-actions>
