import {Pipe, PipeTransform} from '@angular/core'
import {
  DefaultLanguage,
  TLanguageCode,
  TTranslationItem
} from '../../application/i18n.provider'
import {TMakeMaterial} from '../../counter-top/model/counter-top'

const TranslateMap: Map<TMakeMaterial, TTranslationItem> = new Map([
  ['wood', {en: 'Wood', sv: 'Trä'}],
  ['stone', {en: 'Stone', sv: 'Sten'}],
  ['stainless', {en: 'Stainless', sv: 'Rostfri'}]
])

@Pipe({
  name: 'counterTopMat'
})
/**
 * Pipe used for Counter Top materials.
 */
export class CounterTopMaterialPipe implements PipeTransform {
  transform(value: TMakeMaterial, lc: TLanguageCode = DefaultLanguage): string {
    return TranslateMap.get(value)[lc]
  }
}
