import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {CenterPostMill} from './mill-file/mill-file-types'

export class CenterPost extends CabinetOption {
  constructor(option: CenterPostMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)
    /**
     * Set this "statically" first so that they always have a default
     * value. The after you have analyzed the values, set it properly
     */
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Typ av mittstolpe',
        name: 'centerPostType',
        values: ['Fast mittstolpe mellan luckorna', 'Mittstolpen är fixerad vid en av luckorna', 'Ingen mittstolpe mellan luckorna'],
        selection: '',
        disabled: false
      }
    ]

    this.description = 'Describes if the mid-post is attached to the door or fixed in the carcass'
    this.title = 'optCenterPost'
    this.active = true // This is always active b/c
    this.priority = 7
    this.setValuesFromProdboardData(option)
  }

  get isFakeMidPost(): boolean {
    return this.viewOptions[0].selection === 'Mittstolpen är fixerad vid en av luckorna'
  }

  get optionSelectName(): TOptionSelectName {
    return 'CenterPost'
  }

  public update(data: any) {
    super.update(data)
    this.resetPrices()
    this.setFromProperties(data)
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    return [this.settingOption.getI18n(this.viewOptions[0].selection, useCase, lc)]
  }

  private setValuesFromProdboardData(option: CenterPostMill) {
    this.viewOptions[0].selection = option.value
  }
}
