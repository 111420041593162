import {DefaultMap} from '../application/helpers'
import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOption,
  ViewOptionType
} from './cabinet-option'
import {HiddenDrawerMill} from './mill-file/mill-file-types'
import {DRAWER_HIGH, DRAWER_LOW, SHELF} from './model-types'

const DRAWER_HIGH_PROP = 'largeHiddenDrawers'
const DRAWER_LOW_PROP = 'smallHiddenDrawers'
const SHELF_PROP = 'shelves'


export class HiddenDrawer extends CabinetOption {
  /**
   * This map contains the actual values as numbers so that
   * we do not have to convert back and forth.
   * @private
   */
  private readonly optionCountMap = new DefaultMap<string, number, number>(
    [
      [DRAWER_HIGH, 0],
      [DRAWER_LOW, 0],
      [SHELF, 0]
    ], 0
  )

  /**
   * Get "name" from property, 'shelf' > 'Hyllplan' etc.
   * @private
   */
  private propertyToNameMap = new DefaultMap<string, string>([
    [DRAWER_HIGH_PROP, DRAWER_HIGH],
    [DRAWER_LOW_PROP, DRAWER_LOW],
    [SHELF_PROP, SHELF]
  ], 0)

  constructor(option: HiddenDrawerMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)

    this.priority = 100
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: DRAWER_HIGH,
        name: DRAWER_HIGH_PROP,
        values: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        selection: '0',
        disabled: false
      },
      {
        type: ViewOptionType.select,
        title: DRAWER_LOW,
        name: DRAWER_LOW_PROP,
        values: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        selection: '0',
        disabled: false
      },
      {
        type: ViewOptionType.select,
        title: SHELF,
        name: SHELF_PROP,
        values: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        selection: '0',
        disabled: false
      }
    ]
    this.active = true

    this.description = 'Info about what\'s inside the cabinet: qty of shelves, drawers'
    this.title = 'optHiddenDrawer'

    this.setValuesFromProdboardData(option)
    this.setValuesFromMap()
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'HiddenDrawer'
  }

  public update(data: any) {
    super.update(data)
    for (const prop of this.propertyToNameMap.keys()) {
      // Convert property to a name
      const name = this.propertyToNameMap.get(prop)
      // Set the actual value in the value map.
      if (data.hasOwnProperty(prop)) {
        this.optionCountMap.set(name, +data[prop])
      }
    }
    this.setValuesFromMap()
    this.setPrice()
  }

  public hasHiddenDrawers(): boolean {
    return this.optionCountMap.get(DRAWER_LOW) + this.optionCountMap.get(DRAWER_HIGH) > 0
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    return [...this.optionCountMap.entries()]
      // Remove all that have count 0
      .filter((v) => v[1] > 0)
      // Map to [ 'A', 2 ] => 'A: 2'
      .map((v) =>
        `${this.settingOption.getI18n(v[0], useCase, lc)}${v[1]}`)
  }

  private setValuesFromProdboardData(option: HiddenDrawerMill): void {
    this.optionCountMap.set(DRAWER_HIGH, option.bigDrawers)
    this.optionCountMap.set(DRAWER_LOW, option.smallDrawers)
    this.optionCountMap.set(SHELF, option.shelves)
  }

  private setValuesFromMap(): void {
    this.viewOptions.forEach((vo: ViewOption) => {
      vo.selection = this.optionCountMap.get(vo.title) + ''
    })
    this.active = [...this.optionCountMap.values()].some(c => c > 0)
  }

  private setPrice(): void {
    this.resetPrices()
    this.price =
      this.product.hiDrPr.price * this.optionCountMap.get(DRAWER_HIGH) +
      this.product.hiDrPr.price * this.optionCountMap.get(DRAWER_LOW) +
      this.product.exShPr.price * this.optionCountMap.get(SHELF)
    this.labor =
      this.product.hiDrPr.labor * this.optionCountMap.get(DRAWER_HIGH) +
      this.product.hiDrPr.labor * this.optionCountMap.get(DRAWER_LOW) +
      this.product.exShPr.labor * this.optionCountMap.get(SHELF)

    this.shouldHavePrice = [...this.optionCountMap.values()].some(v => v > 0)
  }
}
