import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {IFanExtractorPanelSideMill} from './mill-file/mill-file-types'
import {
  EXTRACTOR_HOOD_CONCAVE_CONSOLE,
  EXTRACTOR_HOOD_CONVEX_CONSOLE,
  EXTRACTOR_HOOD_MIRROR,
  EXTRACTOR_HOOD_NO_CONSOLE,
  EXTRACTOR_HOOD_PLAIN,
  EXTRACTOR_HOOD_UNPAINTED
} from './model-types'

type TFESide = 'left' | 'right' | 'front'

const titleMap: Record<TFESide, string> = {
  left: 'optFanExtractorLeft',
  right: 'optFanExtractorRight',
  front: 'optFanExtractorFront'
}

export class FanExtractorPanel extends CabinetOption {
  private readonly side: TFESide = 'front'

  constructor(
    option: IFanExtractorPanelSideMill,
    optionCount: number,
    product: IProduct,
    cabinetId: number
  ) {
    super(product, cabinetId, optionCount)
    this.priority = 100
    this.active = true
    /**
     * The view options for this option. Here you must list all things that
     * can be selected/modified for an option.
     *
     * Based on this we will generate "Forms" higher up.
     */
    this.viewOptions = [
      {
        title: 'Panel type',
        type: ViewOptionType.select,
        name: 'panelType',
        values: [EXTRACTOR_HOOD_MIRROR, EXTRACTOR_HOOD_PLAIN, EXTRACTOR_HOOD_UNPAINTED],
        selection: EXTRACTOR_HOOD_UNPAINTED,
        disabled: false
      },
      {
        title: 'Console',
        type: ViewOptionType.select,
        name: 'consoleType',
        values: [EXTRACTOR_HOOD_NO_CONSOLE, EXTRACTOR_HOOD_CONCAVE_CONSOLE, EXTRACTOR_HOOD_CONVEX_CONSOLE],
        selection: EXTRACTOR_HOOD_NO_CONSOLE,
        disabled: false
      }
    ]
    this.description = 'Panel on Extractor Hood'

    this.side = option.side
    this.title = titleMap[this.side]
    this.setValuesFromProdboardData(option)
    this.setPrice()
  }

  get optionSelectName(): TOptionSelectName {
    return 'FanExtractorPanel'
  }

  public update(data: any): void {
    super.update(data)
    this.setFromProperties(data)
    if (this.viewOptions[1]) {
      this.viewOptions[1].disabled = this.viewOptions[0].selection === EXTRACTOR_HOOD_UNPAINTED
      if (this.viewOptions[1].disabled) {
        this.viewOptions[1].selection = EXTRACTOR_HOOD_NO_CONSOLE
      }
    }
    this.setPrice()
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    let res = this.settingOption.getI18n(this.side, useCase, lc) + ' ' +
      this.settingOption.getI18n(this.viewOptions[0].selection, useCase, lc)
    if (this.side !== 'front') {
      res += ` ${this.settingOption.getI18n(this.viewOptions[1].selection, useCase, lc)}`
    }
    return [res]
  }

  /**
   * Each class implements its own logic for setting the price and.
   * possibly other options. All options should be available in the
   */
  private setValuesFromProdboardData(option: IFanExtractorPanelSideMill): void {
    this.viewOptions[0].selection = option.style

    if (option.style === 'No coverside') {
      this.viewOptions[1].disabled = true
      this.viewOptions[1].selection = EXTRACTOR_HOOD_NO_CONSOLE
    }

    // Remove the coverside option for front.
    if (option.side !== 'front') {
      this.viewOptions[1].selection = option.console
    } else {
      this.viewOptions = [this.viewOptions[0]]
    }
  }

  /**
   * Called upon creation and after update
   */
  private setPrice(): void {
    this.resetPrices()

    if (this.viewOptions[0].selection === EXTRACTOR_HOOD_MIRROR) {
      this.price = this.product.csPr.price
      this.labor = this.product.csPr.labor
      this.shouldHavePrice = true
    }

    if (this.viewOptions[0].selection === EXTRACTOR_HOOD_PLAIN) {
      this.price = this.product.psPr.price
      this.labor = this.product.psPr.labor
      this.shouldHavePrice = true
    }

    if (this.viewOptions[1] && this.viewOptions[1].selection !== EXTRACTOR_HOOD_NO_CONSOLE) {
      this.labor += this.product.coDeEnPaPr.labor
      this.price += this.product.coDeEnPaPr.price
      // ADD to the cabinet price as this is for longer sides
      this.shouldHavePrice = true
    }
  }
}
