import {
  CUSTOMER_MATERIAL_FACTOR,
  CUSTOMER_PRICE_FACTOR,
  doorPrices,
  FactoryExtra,
  paintPrices,
  TFactoryExtraUnit
} from './factory-extra'
import {IUnitPrice} from '../../common/interface/product-types'
import {DefaultMap} from '../../application/helpers'
import {CLASSIC_HINGES, MODERN_HINGES} from '../../model/model-types'

export const FactoryHingeTypes = ['none', CLASSIC_HINGES, MODERN_HINGES] as const
export type TFactoryHingeType = typeof FactoryHingeTypes[number]

export const FactoryDoorDirection = ['left', 'right', 'top', 'bottom'] as const
export type TFactoryDoorDirection = typeof FactoryDoorDirection[number]

const hingePrices = new DefaultMap<TFactoryHingeType, IUnitPrice>([
    ['none', {price: 27, labor: 0, material: 0}],
    [CLASSIC_HINGES, {price: 27, labor: 4, material: 50}],
    [MODERN_HINGES, {price: 27, labor: 8, material: 0}]
  ], ['none', {price: 27, labor: 0, material: 0}])

export class Door extends FactoryExtra {

  public override price = null
  public override labor = null
  public override material = 0
  public override unit: TFactoryExtraUnit = 'pcs'
  public override midPanelCount = 0

  public size = 0

  public startCostLabor: number

  constructor(input: Partial<Door> = {}) {
    super(input)
    Object.assign(this, input)
  }

  override get totalFactoryPrice(): number {
    this.adjustPrice()
    if (this.setLabor !== null) {
      return this.setLabor
    }
    return this.labor * this.quantity + this.startCostLabor
  }

  override get totalCustomerPrice(): number {
    this.adjustPrice()
    if (this.setPrice !== null) {
      return this.setPrice
    }
    const startCostPrice = this.startCostLabor * CUSTOMER_PRICE_FACTOR
    return (this.price * this.quantity) + startCostPrice
  }

  override get totalMaterialPrice(): number {
    this.adjustPrice()
    return this.material * this.quantity
  }

  private adjustPrice(): void {
    this.size = (this.height * this.width) / 1000 / 1000

    const hingeMaterial = hingePrices.get(this.hingeType).material
    const paintMaterial = this.size * paintPrices.get(this.paintProcess).material

    this.material = hingeMaterial + paintMaterial

    const doorLabor = this.size * doorPrices.get(this.doorStyle).labor
    const hingeLabor = hingePrices.get(this.hingeType).labor
    const paintLabor = this.size * paintPrices.get(this.paintProcess).labor
    // The first mid panel is free so we remove one
    const midPanelLabor = (this.midPanelCount - 1) * 13
    this.startCostLabor = this.quantity * 11

    this.labor = doorLabor + hingeLabor + paintLabor + midPanelLabor

    const doorPrice = doorLabor * CUSTOMER_PRICE_FACTOR
    const hingePrice = (hingeLabor * CUSTOMER_PRICE_FACTOR) + (hingeMaterial * CUSTOMER_MATERIAL_FACTOR)
    const paintPrice = (paintLabor * CUSTOMER_PRICE_FACTOR) + (paintMaterial * CUSTOMER_MATERIAL_FACTOR)
    const midPanelPrice = midPanelLabor * CUSTOMER_PRICE_FACTOR
    this.price = doorPrice + hingePrice + paintPrice + midPanelPrice
  }
}
