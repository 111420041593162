<ng-template #saveButton>
  @if (changed && !saving) {
    <span>
      <span kdlI18n label="saveIn"></span>: {{ autoSaveTimeout - timer }}s
    </span>
  } @else if (!changed && !saving) {
    <span kdlI18n label="save"></span>
  } @else if (saving) {
    <span kdlI18n label="saving"></span>
  }
</ng-template>

<div *ngIf="showFloater && currentProject?.id" [class.is-saving]="autoSaveService.saving$ | async" class="float opacity-transition">
  <div class="float-content">
    <div [routerLink]="['edit-project', 'project', currentProject.id]"
         class="floater-title">
      {{ currentProject.customer?.name }}
    </div>

    <kdl-lock [id]="currentProject.id" class="lock"></kdl-lock>

    <div class="name-and-lock"></div>

    <button
      (click)="saveProject()"
      [disabled]="!changed || saving"
      color="primary"
      mat-raised-button>
      <mat-icon class="material-icons-outlined" fontIcon="save"></mat-icon>
      <ng-container *ngTemplateOutlet="saveButton"></ng-container>
    </button>

    <kdl-print [class.is-saving]="changed && !saving"></kdl-print>
  </div>

  <div class="links">
    @if (prodboardUrl) {
      <a [href]="prodboardUrl" kdlI18n label="openInProdboard" target="_blank">
        Text overridden by i18n label
      </a>
    } @else {
      <div *ngIf="!prodboardUrl">No URL</div>
    }

    <a [routerLink]="['prodboard', 'update', currentProject.id]"
       kdlI18n label="selectNewProdboard">
      Text overridden by i18n label
    </a>
  </div>

  @if (currentProject.pricing) {
    <!-- Pricing will only be shown if country ("lc") is selected -->
    @if (currentProject.form.lc) {
      <a (click)="openPriceDialog()" class="floater-title">
        Price: {{ currentProject.pricing.totalCustomerPrice | number:'1.0-0':'fr' }}
        kr
      </a>
    } @else {
      <a class="floater-title"
         [matTooltip]="'selectCountryTooltip' | i18n | async"
         kdlI18n label="selectCountry"
      >Text overridden by i18n label</a>
    }
  }
</div>

