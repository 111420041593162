import {CustomerStateMap} from '../customer-state-map'
import {CustomerProjectData, CustomerStateLabel, ICustomer} from '../customer-types'

export const CustomerInvoiceFields = ['invoiceAddress1', 'invoiceAddress2', 'invoicePostalCode', 'invoicePostalTown', 'invoiceCountry']
export const CustomerDeliveryFields = ['address1', 'address2', 'postalCode', 'postalTown', 'country']

export class Customer implements ICustomer {
  public id: string
  public type: 'CST'
  public version: number
  public timeStamp: number

  public name: string
  public email: string
  public phone: string

  // Delivery address fields
  public address1: string
  public address2: string
  public postalCode: string
  public postalTown: string
  public country: string

  // Invoice address fields
  public invoiceAddress1: string
  public invoiceAddress2: string
  public invoicePostalCode: string
  public invoicePostalTown: string
  public invoiceCountry: string

  /**
   * These should be saved on server. A list of customer project ids
   */
  public projects: CustomerProjectData[] = []

  public currentStateLabel: CustomerStateLabel = CustomerStateMap.FIRST_STATE_LABEL

  constructor(customer: ICustomer = {} as any) {
    Object.assign(this, customer)
  }

  /**
   * Return the delivery address as
   * Daniel Bergdahl
   * Trädgårdsgatan 2
   * 26868 Röstånga
   * Sverige
   */
  public getDeliveryAdress(): string {
    return [this.name, this.address1, this.address2, `${this.postalCode} ${this.postalTown}`, this.country]
      .filter(s => s)
      .join('\n').replace(/undefined|null| {2}/g, '')
  }

  /**
   * Return the data to be copied in the customer list as
   * Daniel Bergdahl
   * Trädgårdsgatan 2
   * 26868 Röstånga
   * Sverige
   * 0706-6666666
   * daniel@dabe.se
   */
  public getCustomerCopyData(): string {
    return [this.getDeliveryAdress(), this.phone, this.email]
      .filter(s => s)
      .join('\n').replace(/undefined|null| {2}/g, '')
  }

  /**
   * Return notification info like:
   * Avisering till:
   * 0730274188
   * rexsuecia@gmail.com
   *
   */
  public getNotification(): string {
    return ['Avisering till:', this.email, this.phone]
      .filter(s => s)
      .join('\n')
  }

  public validAddress(): boolean {
    return [this.name, this.address1, this.postalTown, this.country, this.email, this.phone]
      .map((v: string | undefined) => v?.length > 0)
      .reduce((acc: boolean, v: boolean) => acc && v, true)
  }
}
