import {
  PriceTableRow,
  TQuantityUnit
} from '../../../../services/project-price-table-types'
import {
  AssemblyPrice,
  CommentPrice, ProjectPricing, AssemblyServices,
  TAssemblyService
} from '../../../../services/project-pricing-types'

export class PriceTableCommon {
  public static getCommentsRows(comments: CommentPrice[]): PriceTableRow {
    return new PriceTableRow({
      rowType: 'subsection',
      name: 'priceTable_subsection_comments',
      subRows: comments
        .map(c => new PriceTableRow({
          rowType: 'value',
          name: c.name,
          ...c.priceTypes
        }))
    })
  }

  public static getInstallationSectionRows(pricing: ProjectPricing): PriceTableRow[] {
    const installationData: PriceTableRow[] = []

    // For installation section we will gather all services across every item,
    // every item-option and project, and we will group them by type.
    // Every group is a row
    AssemblyServices.forEach(serviceType => {
      const servicesInItems = pricing.items
        .flatMap(item => [
          ...item.services,
          ...item.options.flatMap(o => o.services)
        ])

      const services: AssemblyPrice[] = [...pricing.services, ...servicesInItems]
        .filter(s => s.type === serviceType)

      installationData.push(new PriceTableRow({
        rowType: 'value',
        name: `priceTable_row_assemblyServiceType_${serviceType}_group`,
        amount: services.reduce((acc, s) =>
          acc + s.quantity, 0),
        unit: PriceTableCommon.getUnitTypeByServiceType(serviceType),
        customer: services.reduce((acc, s) =>
          acc + s.getPriceType('customer'), 0),
        factory: services.reduce((acc, s) =>
          acc + s.getPriceType('factory'), 0),
        costs: services.reduce((acc, s) =>
          acc + s.getPriceType('costs'), 0)
      }))
    })

    return installationData
  }

  public static getUnitTypeByServiceType(serviceType: TAssemblyService): TQuantityUnit {
    let unitType: TQuantityUnit = 'unit'
    if (serviceType === 'counterTopArea') {
      unitType = 'area'
    }
    if (serviceType === 'volume' ||
      serviceType === 'carry' ||
      serviceType === 'waste') {
      unitType = 'volume'
    }
    return unitType
  }
}