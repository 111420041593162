import {InjectionToken} from '@angular/core'
import {Observable, of} from 'rxjs'
import {distinctUntilChanged, switchMap} from 'rxjs/operators'
import {I18n} from './lang/i18n'

const LanguageCodes = ['sv', 'en'] as const
export type TLanguageCode = typeof LanguageCodes[number]
export const DefaultLanguage: TLanguageCode = 'en'

export type TTranslationItem = {[key in TLanguageCode]: string}

/**
 * Another "type" to save some time.
 */
export type Ti18nFactory<T> = (module: I18nModule) => Observable<T>

/**
 * Typings for the win to avoid spelling mistakes.
 */
export type I18nModule =
  'core'
  | 'settings'
  | 'dashboard'
  | 'appliances'
  | 'comments'
  | 'project-viewer'
  | 'images'
  | 'dialogs'
  | 'edit-project'
  | 'counter-top'
  | 'cabinet'
  | 'user'
  | 'products'
  | 'login'
  | 'customer'
  | 'prodboard'
  | 'factory-extra'
  | 'project-pricing'

/**
 * It is just a tolen, nothing more nothing less. But when using
 * this you expect an observable of I18n:s back.
 */
export const I18N =
  new InjectionToken<Observable<I18n>>('I18N')

/**
 * Note how we statically return this. But point being that
 * this in the future can emit different languages
 */
export const getLocaleLanguageCode = (): Observable<TLanguageCode> => of('en')

/**
 * Load a language "module", look ./lang/en.core.lang.ts is the English (en)
 * labels for "core" module
 */
export const i18nCoreFactory: Ti18nFactory<I18n> = (module: I18nModule): Observable<I18n> =>
  getLocaleLanguageCode().pipe(
    distinctUntilChanged(),
    switchMap(async (code: TLanguageCode) =>
      (await import(`./lang/${code}.${module}.lang.ts`)).lang
    )
  )

