import {Component, OnDestroy, OnInit} from '@angular/core'
import {Problem, ProblemService} from '../../services/problem.service'
import {debounceTime, filter} from 'rxjs/operators'
import {Subscription} from 'rxjs'
import {SETTINGS_HOME_PATH} from '../interface/routing'
import {MatSnackBar} from '@angular/material/snack-bar'

@Component({
  selector: 'kdl-problem-list',
  templateUrl: './problem-list.component.html',
  styleUrls: ['./problem-list.component.scss']
})
export class ProblemListComponent implements OnInit, OnDestroy {

  /**
   * Show number of errors as a counter in the button.
   */
  public errorCount = 0

  /**
   * So that we can route to the problem component
   * in settings.
   */
  public settingsHome = '/' + SETTINGS_HOME_PATH

  /**
   * So that we can unsubscribe if we are used outside the "app"
   */
  private problem$ = new Subscription()

  private counter$ = new Subscription()

  constructor(
    private problemService: ProblemService,
    private snackBar: MatSnackBar
  ) {
  }

  public ngOnInit(): void {
    this.problem$ = this.problemService.notify$.pipe(
      filter<Problem | null>(Boolean),
      debounceTime(400)
    ).subscribe({
      next: (problem: Problem) => {
        this.showToast(problem.description)
      }
    })

    this.counter$ = this.problemService.unhandledProblems$.subscribe({
      next: (count: number) => this.errorCount = count
    })
  }

  public ngOnDestroy(): void {
    this.problem$.unsubscribe()
    this.counter$.unsubscribe()
  }

  private showToast(message: string) {
    this.snackBar.open(message, 'Dismiss',
      {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 2500,
        panelClass: 'problem-panel'
      })
  }
}
