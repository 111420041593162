import {I3DCoordinates} from '../../services/project-file-types'
import {ProdboardCabinet} from './prodboard-cabinet'
import {CombinedUnit} from "../combined-unit"

export const CabinetLayoutRotations = ['0', '90', '180', '270'] as const
export type TCabinetLayoutRotation = typeof CabinetLayoutRotations[number]

export interface ICabinetLayoutPosition {
  xStart: number
  xEnd: number
  yStart: number
  yEnd: number
  zStart: number
  zEnd: number
}

/**
 * Class containing cabinet's information about its layout. Qualities that
 * are linked to its position in the room and walls.
 *
 * Important feature from Prodboard to note:
 *  If rotation {z: 1, x: 0} The doors are facing south => No rotation
 *  If rotation {z: 0, x: 1} The doors are facing east => 90º rotation
 *  If rotation {z: -1, x: 0} The doors are facing north => 180º rotation
 *  If rotation {z: 0, x: -1} The doors are facing west => 270º rotation
 */
export class CabinetLayout {

  ////////////////////////////////////////////////////////////////
  // Needed Cabinet's data
  ////////////////////////////////////////////////////////////////
  /**
   * Cabinet's UUID
   */
  public uid: string
  public name: string

  ////////////////////////////////////////////////////////////////
  // Coordinates information
  ////////////////////////////////////////////////////////////////
  /**
   * Original position of cabinet inside the room. And depending on where the
   * cabinet is facing, the coordinates will have different meanings:
   *  - Facing south (0º rotation):
   *  X = width; Y = height; Z = depth
   *  - Facing east (90º rotation):
   *  X = depth; Y = height; Z = width
   *  - Facing north (180º rotation):
   *  X = width; Y = height; Z = depth (reversed)
   *  - Facing west (270º rotation):
   *  X = depth (reversed); Y = height; Z = width
   *
   * What do we mean with reversed? For example, a cabinet facing north,
   * 180º rotation, zStart will be the front of the cabinet and zEnd the back.
   * In a "real magnificent world in which we don't think about coordinates",
   * zStart is usually the back and zEnd is the front.
   */
  original: ICabinetLayoutPosition
  /**
   * Rotated position of cabinet, as if it was facing south from north wall,
   * 0º rotation. Only valid to work with cabinets on same rotation group. Why?
   * If we consider all cabinets to be facing the same direction, in the real
   * world it will mean that many of them could be overlapping with each other,
   * a thing that's only possible in accidents... So no, impossible. Unusable.
   *
   * However, whenever we are working with wall cabinets, we can consider that
   * they do not interfere with other cabinets, so we can work with cabinets on
   * the same wall for example.
   */
  rotated: ICabinetLayoutPosition


  ////////////////////////////////////////////////////////////////
  // Other layout information
  ////////////////////////////////////////////////////////////////
  /**
   * Cabinet's width
   */
  width: number
  /**
   * Cabinet's height
   */
  height: number
  /**
   * Cabinet's depth
   */
  depth: number
  /**
   * Rotation applied to cabinet in order to get its starting-ending
   * coordinates according to its dimensions.
   */
  public rotation: TCabinetLayoutRotation
  /**
   * Flag to know if cabinet is a wall cabinet. What does this mean?
   * Cabinet has Y-start bigger than 0, not on the floor; and one of its sides
   * is touching a wall.
   */
  public isWallCabinet: boolean = false
  /**
   * Other cabinet layouts that are "neighbour" with this one.
   * Meaning that touching each other either side-by-side, top-to-bottom or
   * front-to-back.
   */
  public neighbours: CabinetLayout[] = []

  constructor(cabinet: ProdboardCabinet) {
    this.uid = cabinet.uid
    this.name = `${cabinet.index}. ${cabinet.cat}`

    this.width = cabinet.dimensions.x
    this.height = cabinet.dimensions.y
    this.depth = cabinet.dimensions.z

    // 1. Calculate rotation. Needed to calculate positions
    this.rotation = CabinetLayout
      .getRotationFromCoordinates(cabinet.position.direction)
    // 2. Get original position
    this.original = CabinetLayout.getOriginalPosition(
      this.rotation, cabinet.position.center, cabinet.dimensions)
    // 3. Get rotated position
    this.rotated = CabinetLayout.getRotatedPosition(
      cabinet.room.dimensions, this.rotation, cabinet.position.center, cabinet.dimensions)
    // 4. Check if element is in the wall
    this.isWallCabinet = CabinetLayout.checkIfWallCabinet(cabinet.room.dimensions, this)
  }

  /**
   * Method to set all neighbours of layout based on other layouts passed as
   * parameters.
   */
  public setNeighbours(checkLayouts: CabinetLayout[]) {
    // Get those layouts that are neighbours
    this.neighbours = checkLayouts
      // We don't check themselves
      .filter(l => this.uid !== l.uid)
      .filter(l => CabinetLayout.checkIfNeighbour(this, l))
  }

  /**
   * Other cabinet layouts that are "neighbour" with this one on the same wall.
   * Meaning that they are, first, on the same wall, and second, touching each
   * other either side-by-side or top-to-bottom.
   */
  public get wallNeighbours(): CabinetLayout[] {
    return this.neighbours.filter(l => l.isWallCabinet)
  }

  /**
   * Get all neighbours of this layout that are bottom-aligned with it,
   * building a row of elements by getting the neighbours of neighbours and so
   * on.
   * Example: If A has neighbours B and C; B has neighbours: A and D; C has
   * neighbours: A; and D has neighbours: B.
   * Then A, B, C and D are forming a line like: D-B-A-C
   */

  public static getNeighboursRow(
    layout: CabinetLayout,
    checks: (a: CabinetLayout, b: CabinetLayout) => boolean
  ): CabinetLayout[] {
    return this.getNeighboursRowBase(layout, checks)
  }

  /**
   * Same as getNeighboursRow but with additional check for combination
   */

  public static getNeighboursCombinedRow(
    layout: CabinetLayout,
    checks: (a: CabinetLayout, b: CabinetLayout) => boolean,
    cabinets: ProdboardCabinet[] = []
  ): CabinetLayout[] {
    return this.getNeighboursRowBase(
      layout,
      checks,
      (a, b) => this.checkIfNeighbourCombineLight(
        this.getCabinetFromLayout(a, cabinets),
        this.getCabinetFromLayout(b, cabinets)
      )
    )
  }

  public static getCabinetFromLayout(layout: CabinetLayout, cabinets: ProdboardCabinet[]): ProdboardCabinet {
    return cabinets.find(c => c.uid === layout.uid)!
  }

  /**
   * Checks if two elements are aligned to same bottom. Y-start is the same.
   */
  public static checkIfBottomAligned(a: CabinetLayout, b: CabinetLayout): boolean {
    return a.original.yStart === b.original.yStart
  }

  /**
   * A cabinet will be a wall cabinet if:
   *  - Cabinet's Y-start is more than 0 (not touching the floor)
   *  - At least one cabinet side (X or Z axis) is touching a wall. A wall
   *  can have coordinate-value 0 or rooms-dimension. For example, in a room
   *  of X:4000 & Z:2000, a cabinet will be touching a wall if:
   *   Z-start = 0 || Z-end = 2000 || X-start = 0 || X-end = 4000
   *
   * We make calculations with a little margin instead of 0 for safety against
   * little decimal values.
   */
  private static checkIfWallCabinet(room: I3DCoordinates, layout: CabinetLayout): boolean {
    return layout.original.yStart > 0 &&
      // We add a little margin to Z and X checks to avoid exact decimal numbers
      (layout.original.xStart <= 5 || (room.x - layout.original.xEnd) <= 5 ||
        layout.original.zStart <= 5 || (room.z - layout.original.zEnd) <= 5)
  }

  private static getNeighboursRowBase(
    layout: CabinetLayout,
    checks: (a: CabinetLayout, b: CabinetLayout) => boolean,
    additionalFilter?: (a: CabinetLayout, b: CabinetLayout) => boolean
  ): CabinetLayout[] {
    if (!checks(layout, layout)) {
      return []
    }

    const totalNeighbours: CabinetLayout[] = [layout]
    const getNeighbours = (currentLayout: CabinetLayout): void => {
      const newNeighbours = currentLayout.neighbours
        .filter(l => !totalNeighbours.includes(l) && checks(currentLayout, l)
          && (additionalFilter ? additionalFilter(currentLayout, l) : true))

      totalNeighbours.push(...newNeighbours)
      newNeighbours.forEach(l => getNeighbours(l))
    }

    getNeighbours(layout)
    return totalNeighbours
  }

  /**
   * A cabinet can be touching other cabinet because they are next to each
   * other, or because they are on top of each other.
   * Pragmatically speaking, what is touching? Imagine CabinetA and CabinetB.
   * They will be touching front to back when:
   * - "A is back and B is front" OR "B is back and A is front"
   *   - (A-front == B-back || A-back == B-front) &&
   *   - (A-top > B-bot && A-bot < B-top) &&
   *   - (A-right > B-left && A-left < B-right)
   *
   * They will be touching side by side when:
   * - "A is left and B is right" OR "B is left and A is right"
   *   - (A-right == B-left || A-left == B-right) &&
   *   - (A-top > B-bot && A-bot < B-top) &&
   *   - (A-front > B-back && A-back < B-front)
   *
   * They will be touching top to bottom when:
   * - "A is top and B is bottom" OR "B is top and A is bottom"
   *    - (A-bot == B-top || A-top == B-bot) &&
   *    - (A-front > B-back && A-back < B-front) &&
   *    - (A-right > B-left && A-left < B-right)
   *
   * We make calculations with a little margin instead of 0 for safety against
   * little decimal values.
   */
  private static checkIfNeighbour(a: CabinetLayout, b: CabinetLayout): boolean {
    // "A is back and B is front" OR "B is back and A is front"
    return ((a.original.zEnd === b.original.zStart ||
          a.original.zStart === b.original.zEnd) &&
        (a.original.yEnd > b.original.yStart &&
          a.original.yStart < b.original.yEnd) &&
        (a.original.xEnd > b.original.xStart &&
          a.original.xStart < b.original.xEnd))
      ||
      // "A is left and B is right" OR "B is left and A is right"
      ((a.original.xEnd === b.original.xStart ||
          a.original.xStart === b.original.xEnd) &&
        (a.original.yEnd > b.original.yStart &&
          a.original.yStart < b.original.yEnd) &&
        (a.original.zEnd > b.original.zStart &&
          a.original.zStart < b.original.zEnd))
      ||
      // "A is bottom and B is top" OR "B is bottom and A is top"
      ((a.original.yEnd === b.original.yStart ||
          a.original.yStart === b.original.yEnd) &&
        (a.original.zEnd > b.original.zStart &&
          a.original.zStart < b.original.zEnd) &&
        (a.original.xEnd > b.original.xStart &&
          a.original.xStart < b.original.xEnd))
  }

  /**
   * Checks whether two neighboring cabinets can be combined based on the `CombinedUnit` option.
   *
   * The method compares the layout and combination options of two cabinets (`aCab` and `bCab`):
   * - **Vertical check**: Ensures the top of one cabinet aligns with the bottom of the other.
   * - **Horizontal check**: Ensures the left or right sides align, considering the rotation of each cabinet.
   *
   * @param aCab - The first cabinet to check.
   * @param bCab - The second cabinet to check.
   * @returns `true` if the cabinets can be combined according to their `CombinedUnit` options, `false` otherwise.
   */

  private static checkIfNeighbourCombineLight(aCab: ProdboardCabinet, bCab: ProdboardCabinet): boolean {
    if (!aCab || !bCab) return false

    const a = aCab.layout
    const b = bCab.layout

    const aCabComb = new Map(
      aCab.getActiveOption<CombinedUnit>('CombinedUnit')?.viewOptions.map(option =>
        [option.name, option.selection === 'Ja']
      )
    )

    const bCabComb = new Map(
      bCab.getActiveOption<CombinedUnit>('CombinedUnit')?.viewOptions.map(option =>
        [option.name, option.selection === 'Ja']
      )
    )

    // Top-bottom check
    if (!this.checkIfBottomAligned(a, b)) {
      return false
    }

    // Side checks based on rotation
    const sideChecks = {
      [CabinetLayoutRotations[0]]: () =>
        a.original.xEnd === b.original.xStart ?
          aCabComb.get('right') && bCabComb.get('left') :
          aCabComb.get('left') && bCabComb.get('right'),
      [CabinetLayoutRotations[1]]: () =>
        a.original.zStart === b.original.zEnd ?
          aCabComb.get('right') && bCabComb.get('left') :
          aCabComb.get('left') && bCabComb.get('right'),
      [CabinetLayoutRotations[2]]: () =>
        a.original.xStart === b.original.xEnd ?
          aCabComb.get('right') && bCabComb.get('left') :
          aCabComb.get('left') && bCabComb.get('right'),
      [CabinetLayoutRotations[3]]: () =>
        a.original.zEnd === b.original.zStart ?
          aCabComb.get('right') && bCabComb.get('left') :
          aCabComb.get('left') && bCabComb.get('right')
    }

    return a.rotation === b.rotation ? sideChecks[a.rotation]() : false
  }


  /**
   * Important feature from Prodboard to note:
   *  If rotation {z: 1, x: 0} The doors are facing south => No rotation
   *  If rotation {z: 0, x: 1} The doors are facing east => 90º rotation
   *  If rotation {z: -1, x: 0} The doors are facing north => 180º rotation
   *  If rotation {z: 0, x: -1} The doors are facing west => 270º rotation
   */
  static getRotationFromCoordinates(coordinate: I3DCoordinates): TCabinetLayoutRotation {
    if (coordinate.z === 1) {
      return '0'
    }
    if (coordinate.x === 1) {
      return '90'
    }
    if (coordinate.z === -1) {
      return '180'
    }
    // Only option left should be x === -1
    return '270'
  }

  /**
   * Gets positions for X, Y and Z axis of an element (cabinet usually) inside
   * a room. Without any rotation or modification.
   */
  static getOriginalPosition(
    rotation: TCabinetLayoutRotation,
    center: I3DCoordinates,
    dimensions: I3DCoordinates
  ): ICabinetLayoutPosition {
    const halfWidth = ((rotation === '90' || rotation === '270') ?
      dimensions.z : dimensions.x) / 2
    const halfHeight = dimensions.y / 2
    const halfDepth = ((rotation === '90' || rotation === '270') ?
      dimensions.x : dimensions.z) / 2
    return {
      xStart: center.x - halfWidth,
      xEnd: center.x + halfWidth,
      yStart: center.y - halfHeight,
      yEnd: center.y + halfHeight,
      zStart: center.z - halfDepth,
      zEnd: center.z + halfDepth
    }
  }

  /**
   * Gets positions for X, Y and Z axis of an element (cabinet usually) inside
   * a room, but applying rotation to make the element appears as if it was in
   * "north wall" of the room.
   * North wall is the one in which:
   *    - X axis grows from left to right, align with cabinet's width.
   *    - Y axis grows from floor to ceiling, align with cabinet's height.
   *    - Z axis grows from back to front, align with cabinet's depth.
   */
  static getRotatedPosition(
    room: I3DCoordinates,
    rotation: TCabinetLayoutRotation,
    center: I3DCoordinates,
    dimensions: I3DCoordinates
  ): ICabinetLayoutPosition {
    const map: Record<TCabinetLayoutRotation,
      (room: I3DCoordinates, c: I3DCoordinates, d: I3DCoordinates) => ICabinetLayoutPosition> = {
      '0': this.getNoRotation.bind(this),
      '90': this.getNinetyRotation.bind(this),
      '180': this.getOneEightyRotation.bind(this),
      '270': this.getTwoSeventyRotation.bind(this)
    }
    return map[rotation](room, center, dimensions)
  }

  /**
   * Calculates rotated position for an element situated in "north wall",
   * facing south.
   */
  private static getNoRotation(_: I3DCoordinates, center: I3DCoordinates, dimensions: I3DCoordinates): ICabinetLayoutPosition {
    const halfWidth = (dimensions.x / 2)
    const halfHeight = (dimensions.y / 2)
    const halfDepth = (dimensions.z / 2)
    return {
      xStart: center.x - halfWidth,
      xEnd: center.x + halfWidth,
      zStart: center.z - halfDepth,
      zEnd: center.z + halfDepth,
      yStart: center.y - halfHeight,
      yEnd: center.y + halfHeight
    }
  }

  /**
   * Calculates rotated position for an element situated in "west wall",
   * facing east.
   */
  private static getNinetyRotation(room: I3DCoordinates, center: I3DCoordinates, dimensions: I3DCoordinates): ICabinetLayoutPosition {
    const halfWidth = (dimensions.x / 2)
    const halfHeight = (dimensions.y / 2)
    const halfDepth = (dimensions.z / 2)
    return {
      xStart: room.z - center.z - halfWidth,
      xEnd: room.z - center.z + halfWidth,
      zStart: center.x - halfDepth,
      zEnd: center.x + halfDepth,
      yStart: center.y - halfHeight,
      yEnd: center.y + halfHeight
    }
  }

  /**
   * Calculates rotated position for an element situated in "south wall",
   * facing north.
   */
  private static getOneEightyRotation(room: I3DCoordinates, center: I3DCoordinates, dimensions: I3DCoordinates): ICabinetLayoutPosition {
    const halfWidth = (dimensions.x / 2)
    const halfHeight = (dimensions.y / 2)
    const halfDepth = (dimensions.z / 2)
    return {
      xStart: room.x - center.x - halfWidth,
      xEnd: room.x - center.x + halfWidth,
      zStart: room.z - center.z - halfDepth,
      zEnd: room.z - center.z + halfDepth,
      yStart: center.y - halfHeight,
      yEnd: center.y + halfHeight
    }
  }

  /**
   * Calculates rotated position for an element situated in "east wall",
   * facing west.
   */
  private static getTwoSeventyRotation(room: I3DCoordinates, center: I3DCoordinates, dimensions: I3DCoordinates): ICabinetLayoutPosition {
    const halfWidth = (dimensions.x / 2)
    const halfHeight = (dimensions.y / 2)
    const halfDepth = (dimensions.z / 2)
    return {
      xStart: center.z - halfWidth,
      xEnd: center.z + halfWidth,
      zStart: room.x - center.x - halfDepth,
      zEnd: room.x - center.x + halfDepth,
      yStart: center.y - halfHeight,
      yEnd: center.y + halfHeight
    }
  }
}
