import {BaseTag} from './base-tag'

export class WaitingForCustomerTag extends BaseTag {
  constructor() {
    super()
    this.id = '2'
    /**
     * Waiting for customer
     */
    this.type = 'w'

    /**
     * WfC is short for Waiting for Customer, we did not
     * want the WC abbreviation.
     */
    this.label = 'WfC'

    /**
     * Should be regular golden something.
     */
    this.color = '#c49238'

    /**
     * We _should_ put this in translations
     */
    this.name = 'Waiting for customer'

    /**
     * Set by the system and not something you should do
     * yourself.
     */
    this.selectable = false

    /**
     * This sort
     */
    this.sort = 4
  }
}
