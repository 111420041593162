import {TLanguageCode} from '../application/i18n.provider'
import {IProduct} from '../common/interface/product-types'
import {TSettingOptionUseCase} from '../services/settings-item.service'
import {
  CabinetOption,
  TOptionSelectName,
  ViewOptionType
} from './cabinet-option'
import {DoorAttachmentMill} from './mill-file/mill-file-types'
import {DOOR_ON_DOOR, SLIDES, TBD} from './model-types'

export class DoorAttachment extends CabinetOption {
  constructor(option: DoorAttachmentMill, product: IProduct, cabinetId: number) {
    super(product, cabinetId)

    this.priority = 10
    this.viewOptions = [
      {
        type: ViewOptionType.select,
        title: 'Luckinfästning',
        name: 'doorAttachment',
        values: [DOOR_ON_DOOR, SLIDES, TBD],
        selection: '',
        disabled: false
      }
    ]

    this.active = true

    this.description = 'MISSING'
    this.title = 'optDoorAttachment'

    this.setValuesFromProdboardData(option)
  }

  get optionSelectName(): TOptionSelectName {
    return 'DoorAttachment'
  }

  public update(data: any) {
    super.update(data)
    this.setFromProperties(data)
  }

  public getCustomCustomerListing(
    useCase: TSettingOptionUseCase,
    lc: TLanguageCode
  ): string[] {
    return [this.settingOption.getI18n(this.viewOptions[0].selection, useCase, lc)]
  }

  private setValuesFromProdboardData(option: DoorAttachmentMill): void {
    this.viewOptions[0].selection = option.value
  }
}
