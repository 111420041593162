import {Injectable} from '@angular/core'
import {
  AuthenticationDetails,
  CognitoIdToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js'
import {Observable} from 'rxjs'
import {environment} from '../../environments/environment'
import {EAuthState} from '../common/interface/auth'
import {fromPromise} from 'rxjs/internal/observable/innerFrom'


/**
 * This is a dummy wrapper so that we can replace the CognitoUser in testing.
 */
@Injectable({
  providedIn: 'root'
})
export class CognitoUserFactory {
  public getUser(data: any): CognitoUser | undefined {
    return new CognitoUser(data)
  }
}

@Injectable({
  providedIn: 'root'
})
export class CognitoService {
  /**
   * To make this testable we need to have the user modifiable?
   *
   * @private
   */
  public user: CognitoUser | null = null

  /**
   * This has to be configured if we use different accounts.
   * Currently only used by Daniel though.
   */
  public readonly clientId = environment.clientId

  /**
   * See clientId above
   */
  private readonly userPoolId = environment.userPoolId

  /**
   * The user pool is a pretty static thing we instantiate this in the
   * constructor. We will not use it actively in testing anyways.
   */
  private readonly pool: CognitoUserPool

  constructor(
    private userFactory: CognitoUserFactory
  ) {
    // We do the JSON parse thing to avoid linter warnings, thats is.
    const poolData = JSON.parse(`{ "UserPoolId": "${this.userPoolId}", "ClientId": "${this.clientId}" }`)
    this.pool = new CognitoUserPool(poolData)
  }

  public static getLoginData(username: string, password: string): AuthenticationDetails {
    const data = JSON.parse(`{ "Username": "${username}", "Password": "${password}" }`)
    return new AuthenticationDetails(data)
  }

  /**
   * Get user is always sync. Checkout the get user session if in doubt.
   */
  public getExistingUser(username: string): CognitoUser | null {
    this.user = this.userFactory.getUser(this.getUserData(username))
    return this.user
  }


  /**
   * This involves nw traffic so no we have to go Observable.
   *
   */
  public getExistingSession(user: CognitoUser): Observable<CognitoIdToken | boolean> {
    return fromPromise(new Promise((resolve) => {
      user.getSession(() => {
        // Get a session returns null or a session. It is sync.
        const cognitoUserSession = user.getSignInUserSession()
        // If both session and valid returns true then we should get a valid IdToken back
        if (cognitoUserSession && cognitoUserSession.isValid()) {
          // We actually use the cognito ID token towards API GW
          resolve(cognitoUserSession.getIdToken())
        }
        resolve(false)
      })
    }))
  }

  /**
   * We separate the token fetch b/c we might want other tokens. And
   * we may keep a session alive in memory, we can always check "isValid"
   * on it on the outside. Remember that this is a pure Cognito wrapper
   * only.
   *
   * @param idToken - The IdToken,
   */
  public getUserJwt(idToken: CognitoIdToken): string {
    return idToken.getJwtToken()
  }

  public login(username: string, password: string): Observable<EAuthState> {
    // Create a new empty user.
    this.user = this.userFactory.getUser(this.getUserData(username))
    // Simple wrapper to avoid lint warnings
    const loginData = CognitoService.getLoginData(username, password)
    return fromPromise(new Promise((resolve) => {
      this.user.authenticateUser(loginData, {
        onSuccess: () => resolve(EAuthState.pendingUser),
        newPasswordRequired: () => resolve(EAuthState.newPassword),
        onFailure: (err) => {
          if (err.message === 'Password reset required for the user') {
            return resolve(EAuthState.resetPassword)
          }
          resolve(EAuthState.error)
        }
      })
    }))
  }

  public logout(): Observable<EAuthState> {
    return fromPromise(new Promise(resolve => this.user.globalSignOut({
      onSuccess: () => resolve(EAuthState.start),
      onFailure: () => resolve(EAuthState.start)
    })))
  }

  public setPassword(newPassword: string): Observable<EAuthState> {
    return fromPromise(
      new Promise((resolve) =>
        this.user.completeNewPasswordChallenge(newPassword, undefined, {
            onSuccess: (session: CognitoUserSession) => {
              this.user.setSignInUserSession(session)
              return resolve(EAuthState.authenticated)
            },
            onFailure: () => resolve(EAuthState.error)
          }
        )))
  }

  public confirmPassword(newPassword: string, code: string): Observable<EAuthState> {
    return fromPromise(
      new Promise((resolve) =>
        this.user.confirmPassword(code, newPassword, {
            onSuccess: () => resolve(EAuthState.passwordUpdated),
            onFailure: () => resolve(EAuthState.error)
          }
        )))
  }

  public forgotPassword(username: string): Observable<EAuthState> {
    this.user = this.getExistingUser(username)
    return fromPromise(
      new Promise(resolve =>
        this.user.forgotPassword({
          onSuccess: () => {
            resolve(EAuthState.passwordUpdated)
          },
          onFailure: () => resolve(EAuthState.error)
        })))
  }

  /**
   * This is just a wrapper so that we do not have to do the JSON parse
   * thing that we do to avoid linter warnings.
   */
  private getUserData(username: string): any {
    const data = JSON.parse(`{ "Username": "${username}", "Pool": "{}" }`)
    data.Pool = this.pool
    return data
  }
}

