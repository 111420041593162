@if ((openProjectService.project$ | async) === null) {
  <div class="shortcut-holder"
       (keydown.enter)="openProject()"
       (click)="openProject()">
    <mat-icon class="material-icons-outlined" fontIcon="add"></mat-icon>
    <div kdlI18n label="newProjectShortCut"></div>
  </div>
} @else if (!(openProjectService.project$ | async).id) {
  <div class="shortcut-holder">
    <div kdlI18n label="creatingProjectShortCut"></div>
  </div>
}
