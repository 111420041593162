import {Injectable} from '@angular/core'
import {BehaviorSubject, ReplaySubject} from 'rxjs'
import {hasch} from '../common/interface/helpers'
import {ActivatedRoute, ParamMap} from '@angular/router'

export interface Problem {
  description: string
  handled: boolean
  timeStamp?: string
  error?: Error
}

@Injectable({
  providedIn: 'root'
})
export class ProblemService {
  /**
   * Subscribe to or publish problems, anyone can publish here
   */
  public problems$: ReplaySubject<Problem> = new ReplaySubject<Problem>(1)
  public notify$: ReplaySubject<Problem> = new ReplaySubject<Problem>(1)

  public problemHistory$: BehaviorSubject<Problem[]> = new BehaviorSubject<Problem[]>([])

  public unhandledProblems$: BehaviorSubject<number> = new BehaviorSubject<number>(0)

  private problemHistory: Problem[] = []

  /**
   * Let us keep track of errors so that we do not repeat the
   * same error over and over, but always count 'em
   */
  private errorMap: { [key: string]: number } = {}

  /**
   * We want to set messages based on project, so
   * if we load another project the exact same message
   * may occur.
   */
  private project: string = null


  constructor(private route: ActivatedRoute) {
    route.paramMap.subscribe({
      next: (params: ParamMap) => {
        this.project = params.get('id')
      }
    })
    this.problems$.subscribe({
      next: (problem: Problem) => {
        if (this.errorMap[hasch(problem.description + this.project)]) {
          this.errorMap[hasch(problem.description + this.project)]++
        } else {
          this.errorMap[hasch(problem.description + this.project)] = 1
          this.problemHistory.push(problem)
          this.problemHistory$.next(this.problemHistory.filter(this.filter))
          this.unhandledProblems$.next(this.problemHistory.length)
          this.notify$.next(problem)
        }
      }
    })
  }

  public handleProblem(problem: Problem): void {
    problem.handled = true
    this.problemHistory = this.problemHistory.filter(this.filter)
    this.problemHistory$.next(this.problemHistory)
    this.unhandledProblems$.next(this.problemHistory.length)
  }

  public clearAll(): void {
    this.problemHistory.length = 0
    this.problemHistory$.next(this.problemHistory)
    this.unhandledProblems$.next(this.problemHistory.length)
  }

  private filter = (problem: Problem) => !problem.handled
}
